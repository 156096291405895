import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Long: number;
};

export type AasAuthenticationAttributes = {
  __typename?: 'AasAuthenticationAttributes';
  authenticationMode: Scalars['String'];
  authorizationMode: Scalars['String'];
  company: Scalars['String'];
  customTags?: Maybe<Array<KeyValueTag>>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  grantedApplications: Array<Scalars['String']>;
  grantedAuthorities: Array<Scalars['String']>;
  grantedCompanies: Array<Scalars['String']>;
  grantedInstances: Array<Scalars['String']>;
  grantedReports: Array<Scalars['String']>;
  grantedRoles: Array<Scalars['String']>;
  grantedSites: Array<Scalars['String']>;
  grantedSitesAndInstances: Array<Scalars['String']>;
  grantedSitesCompaniesAndInstances: Array<Scalars['String']>;
  groups: Array<Scalars['String']>;
  hardTimeout: Scalars['String'];
  idleTimeout: Scalars['String'];
  lastName: Scalars['String'];
  managedCompanies: Array<Scalars['String']>;
  managedGroups: Array<Scalars['String']>;
  managedInstances: Array<Scalars['String']>;
  managedSites: Array<Scalars['String']>;
  newUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  technicalAccount: Scalars['Boolean'];
  twoFactorAuthentication: Scalars['Boolean'];
  user: Scalars['String'];
  userEnabled: Scalars['Boolean'];
  userType: Scalars['String'];
  uuid: Scalars['String'];
  version: Scalars['String'];
};

export enum AccessType {
  Access = 'ACCESS',
  Any = 'ANY',
  Management = 'MANAGEMENT'
}

export enum ActiveAuthenticationType {
  Central = 'CENTRAL',
  Local = 'LOCAL'
}

export type Actor = {
  __typename?: 'Actor';
  admin?: Maybe<AdminAccount>;
  technicalAccount?: Maybe<TechnicalAccount>;
};

export type AddGroupMemberResponse = {
  __typename?: 'AddGroupMemberResponse';
  added: Scalars['Boolean'];
  group?: Maybe<AdminGroup>;
  member?: Maybe<GroupMember>;
  message?: Maybe<Scalars['String']>;
};

export type AdminAccount = {
  __typename?: 'AdminAccount';
  commonAuthData: CommonAuthData;
  company: Company;
  created: Scalars['Date'];
  customTags?: Maybe<Array<KeyValueTag>>;
  domain: LoginDomain;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  hasUserImage?: Maybe<Scalars['Boolean']>;
  id: Scalars['Long'];
  lastName: Scalars['String'];
  mailingListOptIn: Scalars['Boolean'];
  modified: Scalars['Date'];
  name: Scalars['String'];
  optInEmails: OptInPreference;
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AdminGroup = {
  __typename?: 'AdminGroup';
  amsBindings: Array<AmsGroup>;
  audits: Array<AdminGroupAudit>;
  childGroupCount: Scalars['Int'];
  childGroups: Array<AdminGroup>;
  comments: Array<GroupComment>;
  company: Company;
  companyCount: Scalars['Int'];
  created: Scalars['Date'];
  createdBy?: Maybe<AdminAccount>;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  grantAllCompanies: Scalars['Boolean'];
  grantAllInstances: Scalars['Boolean'];
  grantAllReports: Scalars['Boolean'];
  grantAllSites: Scalars['Boolean'];
  grantedApplications: Array<Application>;
  grantedCompanies: Array<Company>;
  grantedInstances: Array<Instance>;
  grantedReports: Array<ReportIdentifier>;
  grantedSites: Array<Site>;
  id: Scalars['Long'];
  instanceCount: Scalars['Int'];
  key: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  manageAllCompanies: Scalars['Boolean'];
  manageAllInstances: Scalars['Boolean'];
  manageAllReports: Scalars['Boolean'];
  manageAllSites: Scalars['Boolean'];
  managedApplications: Array<Application>;
  managedCompanies: Array<Company>;
  managedInstances: Array<Instance>;
  managedReports: Array<ReportIdentifier>;
  managedSites: Array<Site>;
  managerGroup?: Maybe<AdminGroupPreview>;
  managersGroup: Scalars['Boolean'];
  memberCount: Scalars['Int'];
  members: Array<GroupMember>;
  modified: Scalars['Date'];
  name: Scalars['String'];
  personal: Scalars['Boolean'];
  siteCount: Scalars['Int'];
  systemManaged: Scalars['Boolean'];
};

export type AdminGroupAudit = {
  __typename?: 'AdminGroupAudit';
  admin?: Maybe<AdminAccount>;
  created: Scalars['Date'];
};

export type AdminGroupIdentifier = {
  __typename?: 'AdminGroupIdentifier';
  company: CompanyIdentifier;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['Long'];
  key: Scalars['String'];
  manager: Scalars['Boolean'];
  name: Scalars['String'];
  parentId: Scalars['Long'];
  technicalAccountGroup: Scalars['Boolean'];
};

export type AdminGroupPage = {
  __typename?: 'AdminGroupPage';
  after: Scalars['Int'];
  depth: Scalars['Int'];
  first: Scalars['Int'];
  page: Array<AdminGroup>;
  size: Scalars['Int'];
};

export type AdminGroupPreview = {
  __typename?: 'AdminGroupPreview';
  company: CompanyPreview;
  grantAllReports: Scalars['Boolean'];
  id: Scalars['Long'];
  key: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  manageAllCompanies: Scalars['Boolean'];
  manageAllInstances: Scalars['Boolean'];
  manageAllReports: Scalars['Boolean'];
  manageAllSites: Scalars['Boolean'];
  members: Array<GroupMember>;
  name: Scalars['String'];
};

export type AdminGroupRef = {
  __typename?: 'AdminGroupRef';
  access: Scalars['Boolean'];
  company: Scalars['String'];
  key: Scalars['String'];
  management: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AdminGroupSearch = {
  closed?: InputMaybe<Scalars['Boolean']>;
  companyName?: InputMaybe<Scalars['String']>;
  groupKeys?: InputMaybe<Array<Scalars['String']>>;
  instanceName?: InputMaybe<Scalars['String']>;
  isSystem?: InputMaybe<Scalars['Boolean']>;
  level?: Scalars['Int'];
  managerGroup?: InputMaybe<Scalars['Int']>;
  ownerCompanyKey?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
};

export type AdminResponse = {
  __typename?: 'AdminResponse';
  admin: AdminAccount;
  message?: Maybe<Scalars['String']>;
};

export type AdminScriptPermission = {
  __typename?: 'AdminScriptPermission';
  applicationKey?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  grantedByGroups: Array<Scalars['String']>;
  key: Scalars['String'];
  personal: Scalars['Boolean'];
};

export type AdminSearch = {
  company?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scalars['String']>;
};

export type AmsGroup = {
  __typename?: 'AmsGroup';
  childGroups?: Maybe<Array<AmsGroup>>;
  dn: Scalars['String'];
  members?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type AmsGroupInput = {
  dn: Scalars['String'];
  name: Scalars['String'];
};

export type ApiPermissionService = {
  __typename?: 'ApiPermissionService';
  operations: Array<ApiPermissionServiceOperation>;
  serviceName: Scalars['String'];
  servicePath: Scalars['String'];
};

export type ApiPermissionServiceOperation = {
  __typename?: 'ApiPermissionServiceOperation';
  operation: Scalars['String'];
  operations: Array<ApiPermissionServiceOperationParameter>;
};

export type ApiPermissionServiceOperationParameter = {
  __typename?: 'ApiPermissionServiceOperationParameter';
  keys: Array<Scalars['String']>;
  parameter: Scalars['String'];
};

export type ApiPermissionTemplate = {
  __typename?: 'ApiPermissionTemplate';
  id: Scalars['String'];
  services: Array<ApiPermissionService>;
};

export type Application = {
  __typename?: 'Application';
  authenticationProtocol: AuthenticationProtocol;
  authorizationApi: AuthorizationApi;
  contextPath?: Maybe<Scalars['String']>;
  deprecated: Scalars['Boolean'];
  group?: Maybe<ApplicationGroup>;
  id: Scalars['Long'];
  key: Scalars['String'];
  loginUrl?: Maybe<Scalars['String']>;
  managedPermissions: Array<Permission>;
  managedRoles: Array<Role>;
  multiSiteSupport: MultiSiteSupport;
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  permissionKeys: Array<Scalars['String']>;
  permissions: Array<Permission>;
  referredPermissions?: Maybe<Array<Permission>>;
  roleKeys: Array<Scalars['String']>;
  roles: Array<Role>;
  serveAssignedInstances: Scalars['Boolean'];
  statusUrl?: Maybe<Scalars['String']>;
  technicalAccountConf?: Maybe<TechnicalAccountConf>;
  workspaces?: Maybe<Array<Workspace>>;
};

export enum ApplicationGroup {
  Ims = 'IMS',
  ImsExternal = 'IMS_EXTERNAL',
  None = 'NONE',
  System = 'SYSTEM'
}

export type ApplicationIdentifier = {
  __typename?: 'ApplicationIdentifier';
  id?: Maybe<Scalars['Long']>;
  key: Scalars['String'];
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
};

export type ApplicationItemInput = {
  appKey?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  type: ApplicationItemType;
};

export enum ApplicationItemType {
  Application = 'APPLICATION',
  Permission = 'PERMISSION',
  Role = 'ROLE'
}

export type ApplicationPreview = {
  __typename?: 'ApplicationPreview';
  deprecated: Scalars['Boolean'];
  id: Scalars['Long'];
  key: Scalars['String'];
  modified: Scalars['Date'];
  name: Scalars['String'];
  permissions: Array<PermissionPreview>;
  roles: Array<PermissionPreview>;
};

export type Audit = {
  __typename?: 'Audit';
  description: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  parameters: Array<AuditParameter>;
  tags: Array<Scalars['String']>;
};

export type AuditConfiguration = {
  __typename?: 'AuditConfiguration';
  audit: Audit;
  created: Scalars['Date'];
  creator: Actor;
  cronExpression: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['Long'];
  includeCsv: Scalars['Boolean'];
  notificationType: EnumScheduledAuditCallout;
  parameters: Array<KeyValueTag>;
  timezone: Scalars['String'];
};

export type AuditParameter = {
  __typename?: 'AuditParameter';
  description: Scalars['String'];
  key: Scalars['String'];
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  options?: Maybe<Array<KeyValueTag>>;
  required: Scalars['Boolean'];
  type: EnumParameterType;
};

export type AuditResult = {
  __typename?: 'AuditResult';
  headers: Array<Scalars['String']>;
  rows: Array<Array<Scalars['String']>>;
};

export type AuditResultPage = {
  __typename?: 'AuditResultPage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page: AuditResult;
  size: Scalars['Int'];
};

export enum AuthenticationProtocol {
  Cas = 'CAS',
  Jwt = 'JWT',
  None = 'NONE',
  Oauth_2 = 'OAUTH_2',
  Oidc = 'OIDC',
  Saml_2 = 'SAML_2',
  SamlIdp = 'SAML_IDP'
}

export enum AuthenticationProvider {
  Ad = 'AD',
  Azure = 'AZURE',
  Imsldap = 'IMSLDAP',
  Infraldap = 'INFRALDAP',
  Local = 'LOCAL',
  Oidc = 'OIDC',
  Okta = 'OKTA',
  Oldb = 'OLDB',
  Saml = 'SAML'
}

export type AuthenticationProviderAttributes = {
  __typename?: 'AuthenticationProviderAttributes';
  enu: AuthenticationProvider;
  externallyGoverned: Scalars['Boolean'];
  label: Scalars['String'];
  name: Scalars['String'];
  supportedHashMethods: Array<HashMethod>;
};

export enum AuthorizationApi {
  Actorapipermissions = 'ACTORAPIPERMISSIONS',
  None = 'NONE',
  Permissions = 'PERMISSIONS',
  Roles = 'ROLES'
}

export type Certificate = {
  __typename?: 'Certificate';
  certificate: Scalars['String'];
  chain: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Date'];
  dc: Scalars['String'];
  id: Scalars['Int'];
  lastDownloaded?: Maybe<Scalars['Date']>;
  lastDownloadedBy?: Maybe<AdminAccount>;
  name: Scalars['String'];
  notifications: Array<CertificateNotification>;
  notify: Scalars['Boolean'];
  profile: CertificateProfile;
  sites?: Maybe<Array<Scalars['String']>>;
  snowTicket?: Maybe<Scalars['String']>;
  status: CertificateStatus;
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};

export type CertificateChoices = {
  __typename?: 'CertificateChoices';
  massExpiration?: Maybe<Scalars['Date']>;
};

export enum CertificateFormat {
  Jks = 'jks',
  P12 = 'p12',
  Pem = 'pem',
  Zip = 'zip'
}

export type CertificateInput = {
  companyKey: Scalars['String'];
  key: Scalars['String'];
  profileName: Scalars['String'];
};

export type CertificateNotification = {
  __typename?: 'CertificateNotification';
  date: Scalars['Date'];
  id: Scalars['Int'];
  sent: Scalars['Boolean'];
};

export enum CertificateProfile {
  Gameslink = 'Gameslink',
  ImsConfigApiClient = 'IMS_ConfigAPI_Client',
  ImsConfigApiServer = 'IMS_ConfigAPI_Server',
  ImsOneLoginClient = 'IMS_OneLogin_Client',
  Undefined = 'Undefined',
  WebApi = 'WebAPI'
}

export type CertificateProfileChoices = {
  __typename?: 'CertificateProfileChoices';
  brands: Array<SemicBrand>;
};

export type CertificateReplacementInput = {
  disableNotifications: Scalars['String'];
  previousCertId: Scalars['Int'];
};

export enum CertificateStatus {
  Expired = 'expired',
  Revoked = 'revoked',
  Unchecked = 'unchecked',
  Unknown = 'unknown',
  Valid = 'valid'
}

export type CertificateTemplate = {
  __typename?: 'CertificateTemplate';
  brandBindings: Array<Scalars['String']>;
  comments: Scalars['String'];
  company: Company;
  dc: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  notificationGroups: Array<AdminGroupIdentifier>;
  profile: CertificateProfile;
  sites: Array<Site>;
  snowTicket: Scalars['String'];
};

export type CertificateTemplateSearch = {
  certId?: InputMaybe<Scalars['Int']>;
  companyKey?: InputMaybe<Scalars['String']>;
  dc?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  gamingInstance?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<CertificateProfile>;
  site?: InputMaybe<Scalars['String']>;
};

export type ChangesetGroupPairs = {
  __typename?: 'ChangesetGroupPairs';
  key: AdminGroupPreview;
  value: GroupItemsChangeSet;
};

export type CommonAuthData = {
  __typename?: 'CommonAuthData';
  authenticationMode: AuthenticationProvider;
  authenticationModeAttributes: AuthenticationProviderAttributes;
  infraLdapUsername?: Maybe<Scalars['String']>;
  lastlogindate?: Maybe<Scalars['Date']>;
  multiplesessionaction: MultipleSessionAction;
  twoFactorActivated: Scalars['Boolean'];
  twoFactorEnabled: Scalars['Boolean'];
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<CompanyAddress>;
  contactInfo?: Maybe<CompanyContactInfo>;
  created: Scalars['Date'];
  crmId?: Maybe<Scalars['String']>;
  crmName?: Maybe<Scalars['String']>;
  domains: Array<LoginDomain>;
  externalId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  instances: Array<InstanceIdentifier>;
  key: Scalars['String'];
  mailingList?: Maybe<MailingList>;
  modified: Scalars['Date'];
  name: Scalars['String'];
  optInEmails: Scalars['Boolean'];
  roles: Array<CompanyRole>;
  sites: Array<SiteIdentifier>;
  syncedFromCrm?: Maybe<Scalars['Boolean']>;
  system: Scalars['Boolean'];
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyAddressInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  zip: Scalars['String'];
};

export type CompanyContactInfo = {
  __typename?: 'CompanyContactInfo';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CompanyContactInfoInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type CompanyIdentifier = {
  __typename?: 'CompanyIdentifier';
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
  system: Scalars['Boolean'];
};

export type CompanyInput = {
  address?: InputMaybe<CompanyAddressInput>;
  contactInfo?: InputMaybe<CompanyContactInfoInput>;
  crmId?: InputMaybe<Scalars['String']>;
  crmName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  optInEmails?: InputMaybe<Scalars['Boolean']>;
  roles: Array<Scalars['String']>;
};

export type CompanyPreview = {
  __typename?: 'CompanyPreview';
  key: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Maybe<CompanyRole>>;
  system: Scalars['Boolean'];
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  description: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type CompanySearch = {
  authProviders?: InputMaybe<Array<AuthenticationProvider>>;
  domain?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  instanceName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  siteName?: InputMaybe<Scalars['String']>;
};

export enum EnumParameterType {
  Boolean = 'BOOLEAN',
  Company = 'COMPANY',
  Date = 'DATE',
  Number = 'NUMBER',
  Permission = 'PERMISSION',
  Select = 'SELECT',
  Site = 'SITE',
  String = 'STRING'
}

export enum EnumScheduledAuditCallout {
  Always = 'ALWAYS',
  Empty = 'EMPTY',
  Nonempty = 'NONEMPTY',
  Onchange = 'ONCHANGE'
}

export type Event = {
  __typename?: 'Event';
  actorDomain?: Maybe<Scalars['String']>;
  actorUsername?: Maybe<Scalars['String']>;
  code: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  created: Scalars['Date'];
  domain?: Maybe<Scalars['String']>;
  eventip?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  flowId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  targetDomain?: Maybe<Scalars['String']>;
  targetUsername?: Maybe<Scalars['String']>;
  versionName?: Maybe<Scalars['String']>;
};

export type EventSearch = {
  actor?: InputMaybe<Array<Scalars['Long']>>;
  code?: InputMaybe<Scalars['Int']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  eventip?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Array<Scalars['String']>>;
  flowId?: InputMaybe<Scalars['String']>;
  groupCompanyKey?: InputMaybe<Scalars['String']>;
  groupKey?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Array<Scalars['String']>>;
  message?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Array<Scalars['Long']>>;
  targetCompanies?: InputMaybe<Array<Scalars['Long']>>;
  versionName?: InputMaybe<Scalars['String']>;
};

export type EventStats = {
  __typename?: 'EventStats';
  eventCount: Scalars['Int'];
  eventLevel: LogLevel;
  eventName: Scalars['String'];
  eventType: Scalars['String'];
};

export type EventType = {
  __typename?: 'EventType';
  description: Scalars['String'];
  level: LogLevel;
  name: Scalars['String'];
};

export type FindAdmin = {
  company: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
};

export type Folder = {
  __typename?: 'Folder';
  folders: Array<Folder>;
  key: Scalars['String'];
  name: Scalars['String'];
  workspaces: Array<Workspace>;
};

export type GrantedCompany = {
  __typename?: 'GrantedCompany';
  instances: Array<GrantedInstance>;
  key: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Maybe<CompanyRole>>;
  system: Scalars['Boolean'];
};

export type GrantedInstance = {
  __typename?: 'GrantedInstance';
  company: GrantedCompany;
  name: Scalars['String'];
  regulation?: Maybe<Regulation>;
  timezone?: Maybe<Scalars['String']>;
};

export type GrantedService = {
  __typename?: 'GrantedService';
  application?: Maybe<Application>;
  id: Scalars['Long'];
  name: Scalars['String'];
  service: Scalars['String'];
};

export type GrantedSite = {
  __typename?: 'GrantedSite';
  instances: Array<GrantedInstance>;
  key: Scalars['String'];
  loginNotificationsEnabled: Scalars['Boolean'];
  manualNomConfigured?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  occupationalLicenseRequired: Scalars['Boolean'];
  services: Array<GrantedService>;
  timezone?: Maybe<Scalars['String']>;
};

export type GroupCloneParams = {
  cloneCompanies?: InputMaybe<Scalars['Boolean']>;
  cloneInstances?: InputMaybe<Scalars['Boolean']>;
  cloneManagement?: InputMaybe<Scalars['Boolean']>;
  cloneMembers?: InputMaybe<Scalars['Boolean']>;
  clonePermissions?: InputMaybe<Scalars['Boolean']>;
  cloneReports?: InputMaybe<Scalars['Boolean']>;
  cloneSites?: InputMaybe<Scalars['Boolean']>;
};

export type GroupCloneValidation = {
  __typename?: 'GroupCloneValidation';
  message: Scalars['String'];
  permissionDiff?: Maybe<Array<Application>>;
  siteDiff?: Maybe<Array<Site>>;
  valid: Scalars['Boolean'];
};

export type GroupComment = {
  __typename?: 'GroupComment';
  admin?: Maybe<AdminAccount>;
  comment: Scalars['String'];
  created: Scalars['Date'];
};

export type GroupCompany = {
  __typename?: 'GroupCompany';
  access: Scalars['Boolean'];
  accessAllInstances: Scalars['Boolean'];
  accessAllInstancesChanged: Scalars['Boolean'];
  accessChanged: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementAllInstances: Scalars['Boolean'];
  delegateManagementAllInstancesChanged: Scalars['Boolean'];
  delegateManagementChanged: Scalars['Boolean'];
  grantedBy: Array<Scalars['String']>;
  group: AdminGroupIdentifier;
  identifier: CompanyIdentifier;
  managedBy: Array<Scalars['String']>;
  roles: Array<Maybe<CompanyRole>>;
};

export type GroupCompanyPage = {
  __typename?: 'GroupCompanyPage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page?: Maybe<Array<GroupCompanyWithGroups>>;
  size: Scalars['Int'];
};

export type GroupCompanyWithGroups = {
  __typename?: 'GroupCompanyWithGroups';
  canActorManageItem?: Maybe<Scalars['Boolean']>;
  groups: Array<GroupCompany>;
  item: GroupCompany;
};

export type GroupInput = {
  company: Scalars['String'];
  description: Scalars['String'];
  grantAllCompanies?: InputMaybe<Scalars['Boolean']>;
  grantAllInstances?: InputMaybe<Scalars['Boolean']>;
  grantAllReports?: InputMaybe<Scalars['Boolean']>;
  grantAllSites?: InputMaybe<Scalars['Boolean']>;
  licenseeSuperGroup?: InputMaybe<Scalars['Boolean']>;
  manageAllCompanies?: InputMaybe<Scalars['Boolean']>;
  manageAllInstances?: InputMaybe<Scalars['Boolean']>;
  manageAllReports?: InputMaybe<Scalars['Boolean']>;
  manageAllSites?: InputMaybe<Scalars['Boolean']>;
  managerCompany: Scalars['String'];
  managerGroup: Scalars['String'];
  managersGroup?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GroupInstance = {
  __typename?: 'GroupInstance';
  access: Scalars['Boolean'];
  accessChanged: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementChanged: Scalars['Boolean'];
  grantedBy: Array<Scalars['String']>;
  group: AdminGroupIdentifier;
  identifier: InstanceIdentifier;
  managedBy: Array<Scalars['String']>;
};

export type GroupInstancePage = {
  __typename?: 'GroupInstancePage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page: Array<GroupInstanceWithGroups>;
  size: Scalars['Int'];
};

export type GroupInstanceWithGroups = {
  __typename?: 'GroupInstanceWithGroups';
  canActorManageItem?: Maybe<Scalars['Boolean']>;
  groups: Array<GroupInstance>;
  item: GroupInstance;
};

export type GroupItemInput = {
  access: Scalars['Boolean'];
  accessAllInstances?: InputMaybe<Scalars['Boolean']>;
  accessAllInstancesChanged?: InputMaybe<Scalars['Boolean']>;
  accessChanged: Scalars['Boolean'];
  applicationKey?: InputMaybe<Scalars['String']>;
  companyKey?: InputMaybe<Scalars['String']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementAllInstances?: InputMaybe<Scalars['Boolean']>;
  delegateManagementAllInstancesChanged?: InputMaybe<Scalars['Boolean']>;
  delegateManagementChanged: Scalars['Boolean'];
  deprecated?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  siteKey?: InputMaybe<Scalars['String']>;
  type: GroupItemType;
};

export type GroupItemsChangeSet = {
  __typename?: 'GroupItemsChangeSet';
  actor?: Maybe<Actor>;
  changedCompanies: Array<GroupCompany>;
  changedInstances: Array<GroupInstance>;
  changedMembers: Array<GroupMember>;
  changedPermissions: Array<GroupPermission>;
  changedReports: Array<GroupReport>;
  changedSites: Array<GroupSite>;
  comment: Scalars['String'];
  createdAt: Scalars['Date'];
  headGroup?: Maybe<AdminGroupPreview>;
  id: Scalars['String'];
  rollbackEnabled?: Maybe<Scalars['Boolean']>;
};

export type GroupItemSearch = {
  accessType?: InputMaybe<Scalars['String']>;
  applicationKey?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  companyName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupKey?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum GroupItemType {
  Company = 'COMPANY',
  Instance = 'INSTANCE',
  Permission = 'PERMISSION',
  Report = 'REPORT',
  Role = 'ROLE',
  Site = 'SITE'
}

export type GroupMember = {
  __typename?: 'GroupMember';
  account: AdminAccount;
  addedBy?: Maybe<AdminAccount>;
  created?: Maybe<Scalars['Date']>;
  domainUsername: Scalars['String'];
  id: Scalars['Int'];
  newValue?: Maybe<Scalars['Boolean']>;
  source?: Maybe<AmsGroup>;
};

export type GroupMemberInput = {
  company: Scalars['String'];
  domain: Scalars['String'];
  fromAd?: InputMaybe<Scalars['Boolean']>;
  group: Scalars['String'];
  username: Scalars['String'];
};

export type GroupPermission = {
  __typename?: 'GroupPermission';
  access: Scalars['Boolean'];
  accessChanged?: Maybe<Scalars['Boolean']>;
  accessSystem: Scalars['Boolean'];
  application: ApplicationIdentifier;
  dedicated?: Maybe<Scalars['Boolean']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementChanged?: Maybe<Scalars['Boolean']>;
  delegateManagementSystem: Scalars['Boolean'];
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  grantedByRoleNames: Array<Scalars['String']>;
  group: AdminGroupIdentifier;
  key: Scalars['String'];
  managementByRoleNames: Array<Scalars['String']>;
  name: Scalars['String'];
  regulations: Array<Scalars['String']>;
  reportPermission?: Maybe<Scalars['Boolean']>;
  roleDerived: Scalars['Boolean'];
  system?: Maybe<Scalars['Boolean']>;
  taEnabled?: Maybe<Scalars['Boolean']>;
  tags: Array<Scalars['String']>;
};

export type GroupPermissionPage = {
  __typename?: 'GroupPermissionPage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page: Array<GroupPermissionWithGroups>;
  size: Scalars['Int'];
};

export type GroupPermissionTreeItem = {
  __typename?: 'GroupPermissionTreeItem';
  application: ApplicationIdentifier;
  children: Array<Scalars['String']>;
  dedicated: Scalars['Boolean'];
  fullKey: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['Boolean'];
  system: Scalars['Boolean'];
  taEnabled: Scalars['Boolean'];
};

export type GroupPermissionWithGroups = {
  __typename?: 'GroupPermissionWithGroups';
  groups: Array<GroupPermission>;
  item: GroupPermission;
};

export type GroupReport = {
  __typename?: 'GroupReport';
  access: Scalars['Boolean'];
  accessChanged: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementChanged: Scalars['Boolean'];
  grantedBy: Array<Scalars['String']>;
  group: AdminGroupIdentifier;
  identifier: ReportIdentifier;
  managedBy: Array<Scalars['String']>;
};

export type GroupReportPage = {
  __typename?: 'GroupReportPage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page: Array<GroupReportWithGroups>;
  size: Scalars['Int'];
};

export type GroupReportWithGroups = {
  __typename?: 'GroupReportWithGroups';
  canActorManageItem?: Maybe<Scalars['Boolean']>;
  groups: Array<GroupReport>;
  item: GroupReport;
};

export type GroupSite = {
  __typename?: 'GroupSite';
  access: Scalars['Boolean'];
  accessAllInstances: Scalars['Boolean'];
  accessAllInstancesChanged: Scalars['Boolean'];
  accessChanged: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  delegateManagement: Scalars['Boolean'];
  delegateManagementAllInstances: Scalars['Boolean'];
  delegateManagementAllInstancesChanged: Scalars['Boolean'];
  delegateManagementChanged: Scalars['Boolean'];
  grantedBy: Array<Scalars['String']>;
  group: AdminGroupIdentifier;
  identifier: SiteIdentifier;
  managedBy: Array<Scalars['String']>;
};

export type GroupSitePage = {
  __typename?: 'GroupSitePage';
  after: Scalars['Int'];
  first: Scalars['Int'];
  page: Array<GroupSiteWithGroups>;
  size: Scalars['Int'];
};

export type GroupSiteWithGroups = {
  __typename?: 'GroupSiteWithGroups';
  canActorManageItem?: Maybe<Scalars['Boolean']>;
  groups: Array<GroupSite>;
  item: GroupSite;
};

export enum HashMethod {
  Bcrypt = 'BCRYPT',
  Crypt = 'CRYPT',
  Md5 = 'MD5',
  Md5Salt = 'MD5SALT',
  Sha = 'SHA',
  Sha256 = 'SHA256',
  Sha256Salt = 'SHA256SALT',
  Sha384 = 'SHA384',
  Sha384Salt = 'SHA384SALT',
  Sha512 = 'SHA512',
  Sha512Salt = 'SHA512SALT',
  Shasalt = 'SHASALT',
  Ssha = 'SSHA',
  Ssha256 = 'SSHA256',
  Ssha384 = 'SSHA384',
  Ssha512 = 'SSHA512'
}

export type Instance = {
  __typename?: 'Instance';
  company: CompanyIdentifier;
  created: Scalars['Date'];
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
  modified: Scalars['Date'];
  name: Scalars['String'];
  regulation?: Maybe<Regulation>;
  site: SiteIdentifier;
  siteKey: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  walletCode?: Maybe<Scalars['Int']>;
};

export type InstanceIdentifier = {
  __typename?: 'InstanceIdentifier';
  company: CompanyIdentifier;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  site: SiteIdentifier;
};

export type IntRange = {
  __typename?: 'IntRange';
  lowerBoundIncluded?: Maybe<Scalars['Int']>;
  upperBoundExcluded?: Maybe<Scalars['Int']>;
};

export type JwtValidatingResult = {
  __typename?: 'JwtValidatingResult';
  created?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  loginSessionInfo?: Maybe<LoginSessionInfo>;
  message: Scalars['String'];
  tokenSizeKiB: Scalars['Float'];
};

export type KeyValuePair = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type KeyValueTag = {
  __typename?: 'KeyValueTag';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValueTagInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LoginDomain = {
  __typename?: 'LoginDomain';
  authenticationProvider: AuthenticationProvider;
  authenticationProviderAttributes: AuthenticationProviderAttributes;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  company: CompanyIdentifier;
  enabled: Scalars['Boolean'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  id: Scalars['Int'];
  keys?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  metadataUrl?: Maybe<Scalars['String']>;
  passwordPolicy?: Maybe<PasswordPolicy>;
  primary: Scalars['Boolean'];
  transitionDate?: Maybe<Scalars['Date']>;
  transitionQuiet?: Maybe<Scalars['Boolean']>;
  transitionTo?: Maybe<AuthenticationProvider>;
  transitionToAttributes?: Maybe<AuthenticationProviderAttributes>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type LoginDomainInput = {
  authenticationProvider?: InputMaybe<AuthenticationProvider>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  keys?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  metadataUrl?: InputMaybe<Scalars['String']>;
  transitionDate?: InputMaybe<Scalars['Date']>;
  transitionQuiet?: InputMaybe<Scalars['Date']>;
  transitionTo?: InputMaybe<AuthenticationProvider>;
};

export type LoginSession = {
  __typename?: 'LoginSession';
  companyKey?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastActivityDate: Scalars['Date'];
  loginDate: Scalars['Date'];
  loginIp: Scalars['String'];
  open: Scalars['Boolean'];
  service?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  tgt: Scalars['String'];
  type: Scalars['String'];
  username: Scalars['String'];
};

export type LoginSessionInfo = {
  __typename?: 'LoginSessionInfo';
  lastActivityDate: Scalars['String'];
  loginDate: Scalars['String'];
  open: Scalars['Boolean'];
};

export enum LogLevel {
  Critical = 'critical',
  Error = 'error',
  Message = 'message',
  Warning = 'warning'
}

export type MailingList = {
  __typename?: 'MailingList';
  externalId: Scalars['String'];
  name: Scalars['String'];
  system: Scalars['Boolean'];
};

export type Menu = {
  __typename?: 'Menu';
  folders: Array<Folder>;
  key: Scalars['String'];
  name: Scalars['String'];
  workspaces: Array<Workspace>;
};

export type ModifiedNotificationTemplate = {
  attachment?: InputMaybe<Scalars['String']>;
  attachmentName?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Long'];
  name?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Array<InputMaybe<NewNotificationRecipient>>>;
  site?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  templateTitle?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<NotificationTrigger>;
};

export type MultipleLoginSessionConstraint = {
  __typename?: 'MultipleLoginSessionConstraint';
  name?: Maybe<MultipleSessionAction>;
  ordinal?: Maybe<Scalars['Int']>;
};

export enum MultipleSessionAction {
  Allow = 'ALLOW',
  Deny = 'DENY',
  Warn = 'WARN'
}

export enum MultiSiteSupport {
  All = 'ALL',
  None = 'NONE',
  SelectedSites = 'SELECTED_SITES'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAdAdmin: AdminAccount;
  addAdminAccount: AdminAccount;
  addAdminAccounts: Array<AdminResponse>;
  addClientCertNotification: CertificateNotification;
  addClientCertNotificationGroups?: Maybe<Scalars['Boolean']>;
  addClientCertTemplate: CertificateTemplate;
  addCommonCompanyGroups?: Maybe<AdminGroup>;
  addCompany: Company;
  addGroup?: Maybe<AdminGroup>;
  addGroupAuditEntry: Array<AdminGroupAudit>;
  addGroupComment: Array<GroupComment>;
  addGroupMembers: Array<AddGroupMemberResponse>;
  addLoginDomain: LoginDomain;
  addManagementPermission?: Maybe<AdminGroupIdentifier>;
  addMembersFromDomainAndUsername: Array<AdminAccount>;
  addNotificationTemplate: NotificationTemplate;
  addOauthConfiguration: OauthConfiguration;
  addPolicyConsent: Policy;
  addScheduledAuditConfiguration: AuditConfiguration;
  addSite: Site;
  addSiteCompany: Company;
  addSiteInstance?: Maybe<Instance>;
  addSitePermit: SitePermit;
  addSitePermitProfile: SitePermitProfile;
  addSiteService: Service;
  approveRegistration?: Maybe<Scalars['Boolean']>;
  assignManager?: Maybe<AdminGroup>;
  assignServiceInstance: Service;
  bindSitePermitProfile?: Maybe<Scalars['Boolean']>;
  checkSitesVersionForCrmUpdate: Scalars['Boolean'];
  cloneGroup: AdminGroup;
  createSiteCertificate: SiteCertificateDeployment;
  createTechnicalAccount?: Maybe<TechnicalAccount>;
  deleteApplicationItems: Scalars['Boolean'];
  deleteClientCertNotification: CertificateNotification;
  deleteClientCertTemplate?: Maybe<Scalars['Boolean']>;
  deleteNotificationTemplate: NotificationTemplate;
  enableGroup: AdminGroupIdentifier;
  endLoginSession: LoginSession;
  endLoginSessions: Scalars['Int'];
  endServiceTickets: Scalars['Boolean'];
  fetchSiteConfig: SiteDifferences;
  fetchSiteSecrets: Scalars['Boolean'];
  generateOauthSecret: OauthConfiguration;
  grantInstanceMissingCompaniesAndSites: Scalars['Boolean'];
  grantInstances?: Maybe<AdminGroupIdentifier>;
  grantPermission: AdminGroupIdentifier;
  grantSites?: Maybe<AdminGroupIdentifier>;
  importClientCerts?: Maybe<Scalars['Boolean']>;
  importGroup: AdminGroup;
  installSiteCertificates: Array<Scalars['Int']>;
  issueApikey: Scalars['Boolean'];
  issueClientCert: Certificate;
  issueOauthCredentials?: Maybe<OauthCredentials>;
  migratePermission: Array<PermissionMigrationGroup>;
  migrateSiteReportsToSpec: Scalars['Boolean'];
  modifyNotificationTemplate: NotificationTemplate;
  normalizeGroupSuperRelations: Scalars['Boolean'];
  rebuildGroupHierarchy: Scalars['Boolean'];
  refreshLoginDomainMetadata: LoginDomain;
  refreshSiteTokens: Scalars['Boolean'];
  regenerateOauthSecret?: Maybe<OauthCredentials>;
  rejectRegistration?: Maybe<Scalars['Boolean']>;
  reloadApplications: Scalars['Boolean'];
  reloadSites: Scalars['Boolean'];
  removeAdminAccount: AdminAccount;
  removeAdminConfiguration?: Maybe<Scalars['Boolean']>;
  removeClientCertNotificationGroups?: Maybe<Scalars['Boolean']>;
  removeCompany: Company;
  removeGroup?: Maybe<AdminGroupIdentifier>;
  removeGroupMembers: Array<GroupMember>;
  removeLoginDomain: LoginDomain;
  removeManagementPermission?: Maybe<AdminGroupIdentifier>;
  removeOauthConfiguration: Scalars['Boolean'];
  removeScheduledAuditConfiguration: AuditConfiguration;
  removeServiceInstance: Service;
  removeSite: Site;
  removeSiteCompany: Company;
  removeSiteInstance?: Maybe<Instance>;
  removeSiteService: Service;
  removeTechnicalAccount?: Maybe<TechnicalAccount>;
  renameGroup: AdminGroupIdentifier;
  replaceSiteCertificate: SiteCertificateDeployment;
  revertChangesets: GroupItemsChangeSet;
  revokeApikey?: Maybe<Scalars['Boolean']>;
  revokeOauthCredentials?: Maybe<Scalars['Boolean']>;
  revokeOauthSecret: OauthConfiguration;
  revokeSiteCertificates: Array<Scalars['Int']>;
  runChecks: Scalars['Boolean'];
  saveAdminConfiguration?: Maybe<Scalars['Boolean']>;
  sendExpirationNotifications?: Maybe<Scalars['Boolean']>;
  sendTaggedNotifications?: Maybe<Scalars['Boolean']>;
  sendTestNotification?: Maybe<Scalars['Boolean']>;
  setActorTimezone?: Maybe<Scalars['Boolean']>;
  setMailingList?: Maybe<MailingList>;
  siteAccessReason?: Maybe<Scalars['Boolean']>;
  syncAdminAccount: Scalars['Boolean'];
  syncClientCerts?: Maybe<Scalars['Boolean']>;
  syncCompanyCrmData: Array<CompanyIdentifier>;
  syncCompanyInfoToSite: SiteCompany;
  syncPowerBiAcl: Scalars['Long'];
  syncReports: Array<Report>;
  toggleAdminAccount: AdminAccount;
  toggleClientCertNotifications?: Maybe<Scalars['Boolean']>;
  toggleClientCertRevocation?: Maybe<Scalars['Boolean']>;
  toggleClientCertTemplate?: Maybe<Scalars['Boolean']>;
  toggleSitePermitRevocation?: Maybe<Scalars['Boolean']>;
  triggerCompanySemicSync: SemicJobStatus;
  triggerInstanceSemicSync: SemicJobStatus;
  unbindSitePermitProfile?: Maybe<Scalars['Boolean']>;
  uninstallSiteCertificates: Array<Scalars['Int']>;
  updateAdminAccount: AdminAccount;
  updateAmsGroupBindings?: Maybe<AdminGroup>;
  updateCompany: Company;
  updateGroupDescription: AdminGroupIdentifier;
  updateGroupItems: GroupItemsChangeSet;
  updateLoginDomain: LoginDomain;
  updatePwdPolicy: PasswordPolicy;
  updateService: Service;
  updateSite: Site;
  updateSiteInstance?: Maybe<Instance>;
  updateTechnicalAccount?: Maybe<TechnicalAccount>;
};


export type MutationAddAdAdminArgs = {
  username: Scalars['String'];
};


export type MutationAddAdminAccountArgs = {
  admin: NewAdmin;
};


export type MutationAddAdminAccountsArgs = {
  admins: Array<NewAdmin>;
};


export type MutationAddClientCertNotificationArgs = {
  certId: Scalars['Int'];
  notifyDaysBefore: Scalars['Int'];
};


export type MutationAddClientCertNotificationGroupsArgs = {
  groups: Array<Scalars['String']>;
  templateId: Scalars['Int'];
};


export type MutationAddClientCertTemplateArgs = {
  template: NewCertificateTemplate;
};


export type MutationAddCommonCompanyGroupsArgs = {
  company: Scalars['String'];
  headGroup: Scalars['String'];
  headGroupCompany: Scalars['String'];
};


export type MutationAddCompanyArgs = {
  company: CompanyInput;
};


export type MutationAddGroupArgs = {
  group: GroupInput;
};


export type MutationAddGroupAuditEntryArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationAddGroupCommentArgs = {
  comment: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationAddGroupMembersArgs = {
  addedMembers: Array<GroupMemberInput>;
  comment: Scalars['String'];
};


export type MutationAddLoginDomainArgs = {
  company: Scalars['String'];
  domain: LoginDomainInput;
};


export type MutationAddManagementPermissionArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationAddMembersFromDomainAndUsernameArgs = {
  comment: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
  usernames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationAddNotificationTemplateArgs = {
  template: NewNotificationTemplate;
};


export type MutationAddOauthConfigurationArgs = {
  application: Scalars['String'];
  clientId: Scalars['String'];
  secret?: InputMaybe<Scalars['String']>;
  service: Scalars['String'];
};


export type MutationAddPolicyConsentArgs = {
  policyId: Scalars['Long'];
};


export type MutationAddScheduledAuditConfigurationArgs = {
  configuration?: InputMaybe<ScheduledAuditInput>;
};


export type MutationAddSiteArgs = {
  name: Scalars['String'];
  site: SiteInput;
};


export type MutationAddSiteCompanyArgs = {
  company: Scalars['String'];
  site: Scalars['String'];
};


export type MutationAddSiteInstanceArgs = {
  company: Scalars['String'];
  enabled: Scalars['Boolean'];
  instance: Scalars['String'];
  regulation?: InputMaybe<Regulation>;
  site: Scalars['String'];
};


export type MutationAddSitePermitArgs = {
  notifyAdminsAboutExpiration: Scalars['Boolean'];
  permitNumber: Scalars['String'];
  profileId: Scalars['Long'];
  username: Scalars['String'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};


export type MutationAddSitePermitProfileArgs = {
  description?: InputMaybe<Scalars['String']>;
  managerGroup: Scalars['String'];
  name: Scalars['String'];
  notifyDaysBefore: Array<Scalars['Int']>;
  type: SitePermitType;
};


export type MutationAddSiteServiceArgs = {
  application: Scalars['String'];
  name: Scalars['String'];
  service: Scalars['String'];
  site: Scalars['String'];
};


export type MutationApproveRegistrationArgs = {
  id: Scalars['Int'];
};


export type MutationAssignManagerArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  managerGroup: Scalars['String'];
  managerGroupCompany: Scalars['String'];
};


export type MutationAssignServiceInstanceArgs = {
  instance: Scalars['String'];
  service: Scalars['String'];
  site: Scalars['String'];
};


export type MutationBindSitePermitProfileArgs = {
  profileId: Scalars['Long'];
  site: Scalars['String'];
};


export type MutationCheckSitesVersionForCrmUpdateArgs = {
  async?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCloneGroupArgs = {
  group: GroupInput;
  params: GroupCloneParams;
  sourceCompany: Scalars['String'];
  sourceGroup: Scalars['String'];
};


export type MutationCreateSiteCertificateArgs = {
  application: Scalars['String'];
  profile: Scalars['String'];
  site: Scalars['String'];
};


export type MutationCreateTechnicalAccountArgs = {
  companyKey: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  username: Scalars['String'];
};


export type MutationDeleteApplicationItemsArgs = {
  comment: Scalars['String'];
  items: Array<ApplicationItemInput>;
};


export type MutationDeleteClientCertNotificationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteClientCertTemplateArgs = {
  templateId: Scalars['Int'];
};


export type MutationDeleteNotificationTemplateArgs = {
  templateId: Scalars['Long'];
};


export type MutationEnableGroupArgs = {
  company: Scalars['String'];
  enable: Scalars['Boolean'];
  group: Scalars['String'];
};


export type MutationEndLoginSessionArgs = {
  site?: InputMaybe<Scalars['String']>;
  tgt: Scalars['String'];
};


export type MutationEndLoginSessionsArgs = {
  loginDomain: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationEndServiceTicketsArgs = {
  applications: Array<InputMaybe<Scalars['String']>>;
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationFetchSiteConfigArgs = {
  name: Scalars['String'];
};


export type MutationFetchSiteSecretsArgs = {
  site: Scalars['String'];
};


export type MutationGenerateOauthSecretArgs = {
  application: Scalars['String'];
  clientId: Scalars['String'];
};


export type MutationGrantInstancesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  instances?: InputMaybe<Array<Scalars['String']>>;
  management?: InputMaybe<Scalars['Boolean']>;
  site: Scalars['String'];
};


export type MutationGrantPermissionArgs = {
  application: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
  permission: Scalars['String'];
};


export type MutationGrantSitesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  management?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationImportClientCertsArgs = {
  templateId: Scalars['Int'];
};


export type MutationImportGroupArgs = {
  group: GroupInput;
  groupName: Scalars['String'];
  siteName: Scalars['String'];
};


export type MutationInstallSiteCertificatesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationIssueApikeyArgs = {
  allowedOrigin?: InputMaybe<Scalars['String']>;
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationIssueClientCertArgs = {
  cert: NewCertificate;
  muteReplacedNotifications?: InputMaybe<Scalars['Boolean']>;
  notifyDaysBefore: Array<Scalars['Int']>;
  replaces?: InputMaybe<Scalars['Int']>;
};


export type MutationIssueOauthCredentialsArgs = {
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationMigratePermissionArgs = {
  comment: Scalars['String'];
  fromApplication: Scalars['String'];
  fromIsRole: Scalars['Boolean'];
  fromPermission: Scalars['String'];
  removeSourcePermission: Scalars['Boolean'];
  toApplication: Scalars['String'];
  toIsRole: Scalars['Boolean'];
  toPermission: Scalars['String'];
};


export type MutationModifyNotificationTemplateArgs = {
  template: ModifiedNotificationTemplate;
};


export type MutationRebuildGroupHierarchyArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationRefreshLoginDomainMetadataArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationRefreshSiteTokensArgs = {
  site: Scalars['String'];
};


export type MutationRegenerateOauthSecretArgs = {
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRejectRegistrationArgs = {
  comments: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationReloadApplicationsArgs = {
  reloadOnlyApps?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRemoveAdminAccountArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRemoveAdminConfigurationArgs = {
  key: Scalars['String'];
};


export type MutationRemoveClientCertNotificationGroupsArgs = {
  groups: Array<Scalars['String']>;
  templateId: Scalars['Int'];
};


export type MutationRemoveCompanyArgs = {
  company: Scalars['String'];
};


export type MutationRemoveGroupArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationRemoveGroupMembersArgs = {
  comment: Scalars['String'];
  removedMembers: Array<GroupMemberInput>;
};


export type MutationRemoveLoginDomainArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationRemoveManagementPermissionArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationRemoveOauthConfigurationArgs = {
  application: Scalars['String'];
  clientId: Scalars['String'];
  service: Scalars['String'];
};


export type MutationRemoveScheduledAuditConfigurationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveServiceInstanceArgs = {
  instance: Scalars['String'];
  service: Scalars['String'];
  site: Scalars['String'];
};


export type MutationRemoveSiteArgs = {
  name: Scalars['String'];
};


export type MutationRemoveSiteCompanyArgs = {
  company: Scalars['String'];
  site: Scalars['String'];
};


export type MutationRemoveSiteInstanceArgs = {
  instance: Scalars['String'];
  site: Scalars['String'];
};


export type MutationRemoveSiteServiceArgs = {
  service: Scalars['String'];
  site: Scalars['String'];
};


export type MutationRemoveTechnicalAccountArgs = {
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRenameGroupArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  newName?: InputMaybe<Scalars['String']>;
};


export type MutationReplaceSiteCertificateArgs = {
  id: Scalars['Int'];
};


export type MutationRevertChangesetsArgs = {
  changesetId: Scalars['String'];
  comment: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
};


export type MutationRevokeApikeyArgs = {
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRevokeOauthCredentialsArgs = {
  companyKey: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRevokeOauthSecretArgs = {
  application: Scalars['String'];
  clientId: Scalars['String'];
};


export type MutationRevokeSiteCertificatesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationSaveAdminConfigurationArgs = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};


export type MutationSendTaggedNotificationsArgs = {
  site: Scalars['String'];
  tag: Scalars['String'];
  templateVariables?: InputMaybe<Array<InputMaybe<KeyValuePair>>>;
};


export type MutationSendTestNotificationArgs = {
  templateId: Scalars['Long'];
};


export type MutationSetActorTimezoneArgs = {
  timezone: Scalars['String'];
};


export type MutationSetMailingListArgs = {
  companyKey: Scalars['String'];
  listId: Scalars['String'];
};


export type MutationSiteAccessReasonArgs = {
  override?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  service: Scalars['String'];
};


export type MutationSyncAdminAccountArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type MutationSyncCompanyCrmDataArgs = {
  syncAll: Scalars['Boolean'];
};


export type MutationSyncCompanyInfoToSiteArgs = {
  companyKey: Scalars['String'];
  siteKey: Scalars['String'];
};


export type MutationSyncReportsArgs = {
  siteName: Scalars['String'];
  timestamp?: InputMaybe<Scalars['Long']>;
};


export type MutationToggleAdminAccountArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
  enable: Scalars['Boolean'];
  username: Scalars['String'];
};


export type MutationToggleClientCertNotificationsArgs = {
  certId: Scalars['Int'];
  notify: Scalars['Boolean'];
};


export type MutationToggleClientCertRevocationArgs = {
  certId: Scalars['Int'];
  revoke: Scalars['Boolean'];
};


export type MutationToggleClientCertTemplateArgs = {
  enable: Scalars['Boolean'];
  templateId: Scalars['Int'];
};


export type MutationToggleSitePermitRevocationArgs = {
  permitId: Scalars['Long'];
  revoke: Scalars['Boolean'];
};


export type MutationTriggerCompanySemicSyncArgs = {
  companyName: Scalars['String'];
};


export type MutationTriggerInstanceSemicSyncArgs = {
  instanceName: Scalars['String'];
  siteName: Scalars['String'];
};


export type MutationUnbindSitePermitProfileArgs = {
  profileId: Scalars['Long'];
  site: Scalars['String'];
};


export type MutationUninstallSiteCertificatesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationUpdateAdminAccountArgs = {
  admin: NewAdmin;
  currentCompany: Scalars['String'];
  currentDomain: Scalars['String'];
  currentUsername: Scalars['String'];
  passwordConfirmation?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAmsGroupBindingsArgs = {
  addedAmsBindings: Array<AmsGroupInput>;
  comment: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
  removedAmsBindings: Array<AmsGroupInput>;
};


export type MutationUpdateCompanyArgs = {
  company: CompanyInput;
  key: Scalars['String'];
};


export type MutationUpdateGroupDescriptionArgs = {
  company: Scalars['String'];
  description: Scalars['String'];
  group: Scalars['String'];
};


export type MutationUpdateGroupItemsArgs = {
  comment: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
  items: Array<GroupItemInput>;
};


export type MutationUpdateLoginDomainArgs = {
  company: Scalars['String'];
  domain: LoginDomainInput;
};


export type MutationUpdatePwdPolicyArgs = {
  company: Scalars['String'];
  domain: Scalars['String'];
  policy: PasswordPolicyInput;
};


export type MutationUpdateServiceArgs = {
  data: ServiceInput;
  service: Scalars['String'];
};


export type MutationUpdateSiteArgs = {
  name: Scalars['String'];
  site: SiteInput;
};


export type MutationUpdateSiteInstanceArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  instance: Scalars['String'];
  regulation?: InputMaybe<Regulation>;
  site: Scalars['String'];
};


export type MutationUpdateTechnicalAccountArgs = {
  companyKey: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type NavigationConfiguration = {
  __typename?: 'NavigationConfiguration';
  openInNewTab?: Maybe<Scalars['Boolean']>;
};

export type Network = {
  __typename?: 'Network';
  code: Scalars['Long'];
  externalid: Scalars['Long'];
  name: Scalars['String'];
  type: NetworkType;
};

export type NetworkType = {
  __typename?: 'NetworkType';
  codename: Scalars['String'];
  name: Scalars['String'];
};

export type NewAdmin = {
  commonAuthData?: InputMaybe<NewCommonAuthData>;
  company: Scalars['String'];
  customTags?: InputMaybe<Array<KeyValueTagInput>>;
  domain: Scalars['String'];
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mailingListOptIn?: InputMaybe<Scalars['Boolean']>;
  optInEmails?: InputMaybe<OptInPreference>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  userImage?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type NewCertificate = {
  comment?: InputMaybe<Scalars['String']>;
  templateId: Scalars['Int'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};

export type NewCertificateTemplate = {
  brandBindings: Array<Scalars['String']>;
  comments: Scalars['String'];
  companyId?: InputMaybe<Scalars['Long']>;
  companyKey: Scalars['String'];
  dc: Scalars['String'];
  enabled: Scalars['Boolean'];
  importExisting: Scalars['Boolean'];
  name: Scalars['String'];
  notificationGroups: Array<Scalars['String']>;
  profile: CertificateProfile;
  sites: Array<Scalars['String']>;
  snowTicket: Scalars['String'];
};

export type NewCommonAuthData = {
  multiplesessionaction: MultipleSessionAction;
  twoFactorEnabled: Scalars['Boolean'];
};

export type NewNotificationRecipient = {
  channel: NotificationChannel;
  id?: InputMaybe<Scalars['Long']>;
  recipient: Scalars['String'];
};

export type NewNotificationTemplate = {
  attachment?: InputMaybe<Scalars['String']>;
  attachmentName?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  recipients: Array<NewNotificationRecipient>;
  site?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  template: Scalars['String'];
  templateTitle: Scalars['String'];
  trigger: NotificationTrigger;
};

export enum NotificationChannel {
  Email = 'EMAIL'
}

export type NotificationRecipient = {
  __typename?: 'NotificationRecipient';
  channel: NotificationChannel;
  id: Scalars['Long'];
  recipient: Scalars['String'];
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  attachment?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['Long'];
  name: Scalars['String'];
  recipients: Array<NotificationRecipient>;
  site?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  template: Scalars['String'];
  templateTitle: Scalars['String'];
  trigger: NotificationTrigger;
};

export enum NotificationTrigger {
  OnDemand = 'ON_DEMAND',
  SiteAccess = 'SITE_ACCESS'
}

export type OauthConfiguration = {
  __typename?: 'OauthConfiguration';
  application: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  services: Array<Scalars['String']>;
};

export type OauthCredentials = {
  __typename?: 'OauthCredentials';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export enum OnAuthError {
  ReturnEmptyList = 'RETURN_EMPTY_LIST',
  ReturnNull = 'RETURN_NULL',
  ThrowError = 'THROW_ERROR'
}

export enum Operation {
  All = 'ALL',
  Delete = 'DELETE',
  Read = 'READ',
  Write = 'WRITE'
}

export enum OptInPreference {
  CompanyDefault = 'COMPANY_DEFAULT',
  OptIn = 'OPT_IN',
  OptOut = 'OPT_OUT'
}

export type Pagination = {
  after?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PasswordPolicy = {
  __typename?: 'PasswordPolicy';
  passwordCheckSyntax: Scalars['Boolean'];
  passwordExp: Scalars['Boolean'];
  passwordInHistory: Scalars['Int'];
  passwordMaxAge: Scalars['Int'];
  passwordMaxRepeats: Scalars['Int'];
  passwordMinAge: Scalars['Int'];
  passwordMinAlphas: Scalars['Int'];
  passwordMinCategories: Scalars['Int'];
  passwordMinDigits: Scalars['Int'];
  passwordMinLength: Scalars['Int'];
  passwordMinLowers: Scalars['Int'];
  passwordMinSpecials: Scalars['Int'];
  passwordMinUppers: Scalars['Int'];
  passwordStorageScheme: HashMethod;
  passwordWarning: Scalars['Int'];
};

export type PasswordPolicyContext = {
  __typename?: 'PasswordPolicyContext';
  passwordInHistoryReq: IntRange;
  passwordMaxAgeReq: IntRange;
  passwordMaxRepeatsReq: IntRange;
  passwordMinAgeReq: IntRange;
  passwordMinAlphasReq: IntRange;
  passwordMinCategoriesReq: IntRange;
  passwordMinDigitsReq: IntRange;
  passwordMinLengthReq: IntRange;
  passwordMinLowersReq: IntRange;
  passwordMinSpecialsReq: IntRange;
  passwordMinUppersReq: IntRange;
  passwordStorageSchemeOptions: Array<HashMethod>;
  passwordWarningReq: IntRange;
};

export type PasswordPolicyInput = {
  passwordCheckSyntax: Scalars['Boolean'];
  passwordExp: Scalars['Boolean'];
  passwordInHistory: Scalars['Int'];
  passwordMaxAge: Scalars['Int'];
  passwordMaxRepeats: Scalars['Int'];
  passwordMinAge: Scalars['Int'];
  passwordMinAlphas: Scalars['Int'];
  passwordMinCategories: Scalars['Int'];
  passwordMinDigits: Scalars['Int'];
  passwordMinLength: Scalars['Int'];
  passwordMinLowers: Scalars['Int'];
  passwordMinSpecials: Scalars['Int'];
  passwordMinUppers: Scalars['Int'];
  passwordStorageScheme: HashMethod;
  passwordWarning: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  apiPermissionTemplates: Array<Scalars['String']>;
  application: ApplicationIdentifier;
  dedicated: Scalars['Boolean'];
  deprecated: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  key: Scalars['String'];
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  owner?: Maybe<Scalars['String']>;
  regulations: Array<Scalars['String']>;
  reportPermission: Scalars['Boolean'];
  resources: Array<Scalars['String']>;
  system: Scalars['Boolean'];
  taEnabled: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
  tagsString: Scalars['String'];
};

export type PermissionInput = {
  applicationKey: Scalars['String'];
  isRole: Scalars['Boolean'];
  key: Scalars['String'];
};

export type PermissionMetaData = {
  __typename?: 'PermissionMetaData';
  apiPermissionTemplates: Array<ApiPermissionTemplate>;
  key: Scalars['String'];
  operation?: Maybe<Operation>;
  resources: Array<Scalars['String']>;
};

export type PermissionMigrationGroup = {
  __typename?: 'PermissionMigrationGroup';
  group: AdminGroupIdentifier;
  permCanBeAdded: Scalars['Boolean'];
  permToBeAddedAccess: Scalars['Boolean'];
  permToBeAddedManagement: Scalars['Boolean'];
  sourcePermAccess: Scalars['Boolean'];
  sourcePermManagement: Scalars['Boolean'];
};

export type PermissionPreview = {
  __typename?: 'PermissionPreview';
  deprecated: Scalars['Boolean'];
  id: Scalars['Long'];
  key: Scalars['String'];
  modified: Scalars['Date'];
  name: Scalars['String'];
};

export enum PermissionType {
  Access = 'ACCESS',
  Management = 'MANAGEMENT'
}

export type Policy = {
  __typename?: 'Policy';
  content: Scalars['String'];
  created: Scalars['Date'];
  id: Scalars['Long'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type PowerBiAclSyncStatus = {
  __typename?: 'PowerBiAclSyncStatus';
  chunks?: Maybe<Scalars['Int']>;
  completedChunks?: Maybe<Scalars['Int']>;
  created: Scalars['Date'];
  finished?: Maybe<Scalars['Date']>;
  id: Scalars['Long'];
  server: Scalars['Long'];
  status: PowerBiAclSyncStatusType;
  tasks?: Maybe<Scalars['Long']>;
};

export enum PowerBiAclSyncStatusType {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Successful = 'SUCCESSFUL'
}

export type QrCode = {
  __typename?: 'QrCode';
  secret: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeSession: SessionInfo;
  adminGroups: Array<AdminGroup>;
  applications: Array<Application>;
  authenticationProviders: Array<AuthenticationProviderAttributes>;
  childGroups: Array<AdminGroup>;
  countAdminAccounts: Scalars['Int'];
  countClientCertTemplates: Scalars['Int'];
  countCompanies: Scalars['Int'];
  countLoginSessions: Scalars['Int'];
  countManagedSites: Scalars['Int'];
  countRegistrations: Scalars['Int'];
  disableTwoFactorAuthentication?: Maybe<Scalars['String']>;
  eventCount: Scalars['Int'];
  events: Array<Event>;
  eventTypes: Array<EventType>;
  executeAudit: AuditResultPage;
  fetchTwoFactorQrCode?: Maybe<QrCode>;
  findAdAdmins: Array<AdminResponse>;
  /** Find admin account by company domain and username */
  findAdmin?: Maybe<AdminAccount>;
  /** Find enabled admin account by its username or email */
  findAdminAccount?: Maybe<AdminAccount>;
  findAdminAccounts: Array<AdminAccount>;
  findAdminApplications: Array<Maybe<Application>>;
  findAdminCompanies: GroupCompanyPage;
  findAdminInstances: GroupInstancePage;
  findAdminPermissions: GroupPermissionPage;
  findAdminPermissionSitesAccess: Array<GroupSite>;
  findAdminReports: GroupReportPage;
  findAdminSites: GroupSitePage;
  findAmsGroups: Array<AmsGroup>;
  findApplication?: Maybe<Application>;
  findAuditResultsCount: Scalars['Int'];
  findAudits: Array<Audit>;
  findChangesetChanges: Array<ChangesetGroupPairs>;
  findClientCerts: Array<Certificate>;
  findClientCertTemplates: Array<CertificateTemplate>;
  findCompanies: Array<Company>;
  /** Find company with key. Key is unique identifier */
  findCompany: Company;
  /** Find company with name. Name is unique identifier */
  findCompanyByName: Company;
  /** Find company roles */
  findCompanyRoles: Array<Maybe<CompanyRole>>;
  findDefaultPasswordPolicy: PasswordPolicy;
  findDeprecatedAppItems: Array<ApplicationPreview>;
  findGroup?: Maybe<AdminGroup>;
  findGroupCompanies: Array<GroupCompany>;
  findGroupInstances: Array<GroupInstance>;
  findGroupItemChanges: GroupItemsChangeSet;
  findGroupItemsChangeSets: Array<GroupItemsChangeSet>;
  findGroupPermissions: Array<Maybe<Permission>>;
  findGroupReports: Array<GroupReport>;
  findGroupRolesAndPermissions: Array<GroupPermission>;
  findGroupSites: Array<GroupSite>;
  findGroupsWithPermission: Array<AdminGroupIdentifier>;
  findGroupsWithRole: Array<AdminGroupPreview>;
  findManagedGroups: AdminGroupPage;
  findMemberCandidates: Array<AdminAccount>;
  findOauthConfiguration?: Maybe<OauthConfiguration>;
  findOauthConfigurations: Array<OauthConfiguration>;
  findOneGroupItemChangeSets: Array<GroupItemsChangeSet>;
  findPasswordPolicyContext: PasswordPolicyContext;
  findPermissionGroups: Array<AdminGroupRef>;
  findPermissionMigrationGroups: Array<PermissionMigrationGroup>;
  findPermitProfiles: Array<SitePermitProfile>;
  findRegistrations: Array<Maybe<Registration>>;
  findRoleGroups: Array<AdminGroupRef>;
  findRoleSubRolesAndPermissions: Array<GroupPermissionTreeItem>;
  findScheduledAuditConfigurations: Array<AuditConfiguration>;
  findService?: Maybe<Service>;
  findSite: Site;
  findSiteCertificateDeployments: Array<SiteCertificateDeployment>;
  findSiteGroup?: Maybe<SiteAdminGroup>;
  findSiteGroupNames: Array<Scalars['String']>;
  findSiteManagedCompanies: Array<Company>;
  findSiteManagedInstances: Array<Instance>;
  findSiteNotificationTemplates: Array<NotificationTemplate>;
  findSitePermits: Array<SitePermit>;
  getAdminConfiguration?: Maybe<Scalars['String']>;
  /** Get current user workspaces */
  getAdminWorkspaceContext?: Maybe<Workspace>;
  getClientCert: Certificate;
  getClientCertPrivateKey: Scalars['String'];
  getMailingLists: Array<MailingList>;
  getMenuById?: Maybe<Menu>;
  grantedCompanies: Array<GrantedCompany>;
  grantedGroups: Array<AdminGroup>;
  grantedInstances: Array<GrantedInstance>;
  grantedSites: Array<GrantedSite>;
  importableCerts: Array<Certificate>;
  loginDomains: Array<LoginDomain>;
  loginSessions?: Maybe<Array<LoginSession>>;
  managedCompanies: Array<Company>;
  managedGroup: Array<AdminGroup>;
  managedGroups: Array<AdminGroup>;
  managedInstances: Array<Instance>;
  managedSites: Array<Site>;
  managerGroups: Array<AdminGroup>;
  menu: Menu;
  navigatorServices: Array<GrantedSite>;
  notificationTemplates: Array<NotificationTemplate>;
  permissions: Array<Scalars['String']>;
  policiesWithoutConsent: Array<Policy>;
  powerBiAclSyncs: Array<PowerBiAclSyncStatus>;
  powerBiAclSyncStatus?: Maybe<PowerBiAclSyncStatus>;
  querySiteGroup?: Maybe<SiteAdminGroup>;
  resetTwoFactorAuthentication?: Maybe<Scalars['String']>;
  sendPasswordResetLink?: Maybe<Scalars['String']>;
  sendTestEmail: Scalars['String'];
  sendTwoFactorActivationRequest?: Maybe<Scalars['Boolean']>;
  services: Array<Service>;
  serviceStatus: Scalars['String'];
  serviceTicketsByGroupAndApplications: Array<LoginSession>;
  serviceTicketsBySession?: Maybe<Array<Maybe<LoginSession>>>;
  setting?: Maybe<Setting>;
  settings: Array<Setting>;
  siteConfiguration: Array<Site>;
  siteReports: Array<Report>;
  /** Return AasAuthenticationAttributes for given technical account and target */
  technicalAccountAttributes?: Maybe<AasAuthenticationAttributes>;
  technicalAccounts: Array<TechnicalAccount>;
  validateCloneGroup?: Maybe<GroupCloneValidation>;
  validateImportGroup?: Maybe<GroupCloneValidation>;
  validateToken: JwtValidatingResult;
  whoAmI: AdminAccount;
  workspaces: Array<Workspace>;
};


export type QueryAdminGroupsArgs = {
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  sitename?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type QueryApplicationsArgs = {
  group?: InputMaybe<ApplicationGroup>;
  loadFromDB?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChildGroupsArgs = {
  company: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  flat?: Scalars['Int'];
  group: Scalars['String'];
  instanceName?: InputMaybe<Scalars['String']>;
  level?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
};


export type QueryCountAdminAccountsArgs = {
  search?: InputMaybe<AdminSearch>;
};


export type QueryCountClientCertTemplatesArgs = {
  search: CertificateTemplateSearch;
};


export type QueryCountCompaniesArgs = {
  search?: InputMaybe<CompanySearch>;
};


export type QueryCountLoginSessionsArgs = {
  search?: InputMaybe<SessionSearch>;
  site?: InputMaybe<Scalars['String']>;
};


export type QueryCountManagedSitesArgs = {
  filter?: InputMaybe<SitesFilter>;
};


export type QueryCountRegistrationsArgs = {
  approvedFrom?: InputMaybe<Scalars['Date']>;
  approvedStatus?: InputMaybe<Scalars['Int']>;
  approvedTo?: InputMaybe<Scalars['Date']>;
  company?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
};


export type QueryDisableTwoFactorAuthenticationArgs = {
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type QueryEventCountArgs = {
  eventSearch?: InputMaybe<EventSearch>;
};


export type QueryEventsArgs = {
  eventSearch?: InputMaybe<EventSearch>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryExecuteAuditArgs = {
  key: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  parameters: Array<KeyValuePair>;
  sort?: InputMaybe<Sort>;
};


export type QueryFetchTwoFactorQrCodeArgs = {
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type QueryFindAdAdminsArgs = {
  usernameSearch: Scalars['String'];
};


export type QueryFindAdminArgs = {
  companyId: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type QueryFindAdminAccountArgs = {
  username?: InputMaybe<Scalars['String']>;
};


export type QueryFindAdminAccountsArgs = {
  export?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<AdminSearch>;
  sort?: InputMaybe<Sort>;
};


export type QueryFindAdminApplicationsArgs = {
  findAdmin: FindAdmin;
  siteName?: InputMaybe<Scalars['String']>;
};


export type QueryFindAdminCompaniesArgs = {
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
};


export type QueryFindAdminInstancesArgs = {
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
};


export type QueryFindAdminPermissionsArgs = {
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
};


export type QueryFindAdminPermissionSitesAccessArgs = {
  admin: FindAdmin;
  permission: PermissionInput;
};


export type QueryFindAdminReportsArgs = {
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
};


export type QueryFindAdminSitesArgs = {
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
};


export type QueryFindAmsGroupsArgs = {
  search: Scalars['String'];
};


export type QueryFindApplicationArgs = {
  application: Scalars['String'];
};


export type QueryFindAuditResultsCountArgs = {
  key: Scalars['String'];
  parameters: Array<KeyValuePair>;
};


export type QueryFindAuditsArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type QueryFindChangesetChangesArgs = {
  changesetId: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryFindClientCertsArgs = {
  templateId: Scalars['Int'];
};


export type QueryFindClientCertTemplatesArgs = {
  pagination?: InputMaybe<Pagination>;
  search: CertificateTemplateSearch;
  sort?: InputMaybe<Array<Sort>>;
};


export type QueryFindCompaniesArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<CompanySearch>;
  sort?: InputMaybe<Array<Sort>>;
};


export type QueryFindCompanyArgs = {
  companyKey: Scalars['String'];
};


export type QueryFindCompanyByNameArgs = {
  name: Scalars['String'];
};


export type QueryFindGroupArgs = {
  company?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  groupid?: InputMaybe<Scalars['Int']>;
};


export type QueryFindGroupCompaniesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryFindGroupInstancesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryFindGroupItemChangesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  groupItems: Array<GroupItemInput>;
};


export type QueryFindGroupItemsChangeSetsArgs = {
  company: Scalars['String'];
  endDate: Scalars['String'];
  group: Scalars['String'];
  items: Array<GroupItemType>;
  searchMembers: Scalars['Boolean'];
  startDate: Scalars['String'];
};


export type QueryFindGroupPermissionsArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryFindGroupReportsArgs = {
  groupKey: Scalars['String'];
};


export type QueryFindGroupRolesAndPermissionsArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
  loadFromDatabase?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFindGroupSitesArgs = {
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryFindGroupsWithPermissionArgs = {
  accessType: AccessType;
  application: Scalars['String'];
  permissionKey: Scalars['String'];
  searchRoles: Scalars['Boolean'];
};


export type QueryFindGroupsWithRoleArgs = {
  accessType: AccessType;
  application: Scalars['String'];
  roleKey: Scalars['String'];
  searchRoles: Scalars['Boolean'];
};


export type QueryFindManagedGroupsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<AdminGroupSearch>;
};


export type QueryFindMemberCandidatesArgs = {
  company: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  includeAlreadyAdded?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFindOauthConfigurationArgs = {
  application: Scalars['String'];
  clientId: Scalars['String'];
};


export type QueryFindOauthConfigurationsArgs = {
  serviceId: Scalars['String'];
};


export type QueryFindOneGroupItemChangeSetsArgs = {
  company: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  groupItem?: InputMaybe<GroupItemInput>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryFindPasswordPolicyContextArgs = {
  domain: Scalars['String'];
};


export type QueryFindPermissionGroupsArgs = {
  application: Scalars['String'];
  permission: Scalars['String'];
};


export type QueryFindPermissionMigrationGroupsArgs = {
  fromApplication: Scalars['String'];
  fromIsRole: Scalars['Boolean'];
  fromPermission: Scalars['String'];
  toApplication: Scalars['String'];
  toIsRole: Scalars['Boolean'];
  toPermission: Scalars['String'];
};


export type QueryFindPermitProfilesArgs = {
  site?: InputMaybe<Scalars['String']>;
};


export type QueryFindRegistrationsArgs = {
  approvedFrom?: InputMaybe<Scalars['Date']>;
  approvedStatus?: InputMaybe<Scalars['Int']>;
  approvedTo?: InputMaybe<Scalars['Date']>;
  company?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  firstname?: InputMaybe<Scalars['String']>;
  getFrom?: InputMaybe<Scalars['Int']>;
  getLimit?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
};


export type QueryFindRoleGroupsArgs = {
  application: Scalars['String'];
  role: Scalars['String'];
};


export type QueryFindRoleSubRolesAndPermissionsArgs = {
  application: Scalars['String'];
  company: Scalars['String'];
  group: Scalars['String'];
  role: Scalars['String'];
};


export type QueryFindServiceArgs = {
  service: Scalars['String'];
};


export type QueryFindSiteArgs = {
  name: Scalars['String'];
};


export type QueryFindSiteCertificateDeploymentsArgs = {
  site: Scalars['String'];
};


export type QueryFindSiteGroupArgs = {
  groupName: Scalars['String'];
  siteName: Scalars['String'];
};


export type QueryFindSiteGroupNamesArgs = {
  siteName: Scalars['String'];
};


export type QueryFindSiteManagedCompaniesArgs = {
  sitename: Scalars['String'];
};


export type QueryFindSiteManagedInstancesArgs = {
  sitename: Scalars['String'];
};


export type QueryFindSiteNotificationTemplatesArgs = {
  siteKey: Scalars['String'];
};


export type QueryFindSitePermitsArgs = {
  profileId?: InputMaybe<Scalars['Long']>;
  site?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type QueryGetAdminConfigurationArgs = {
  key: Scalars['String'];
};


export type QueryGetAdminWorkspaceContextArgs = {
  application: Scalars['String'];
  service?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  workspace: Scalars['String'];
};


export type QueryGetClientCertArgs = {
  id: Scalars['Int'];
};


export type QueryGetClientCertPrivateKeyArgs = {
  certId: Scalars['Int'];
  format: CertificateFormat;
  password?: InputMaybe<Scalars['String']>;
};


export type QueryGetMenuByIdArgs = {
  id: Scalars['String'];
  service?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryGrantedInstancesArgs = {
  sitename?: InputMaybe<Scalars['String']>;
};


export type QueryLoginSessionsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<SessionSearch>;
  site?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};


export type QueryManagedCompaniesArgs = {
  authProviders?: InputMaybe<Array<AuthenticationProvider>>;
};


export type QueryManagedGroupsArgs = {
  companyName?: InputMaybe<Scalars['String']>;
  flat?: Scalars['Int'];
  instanceName?: InputMaybe<Scalars['String']>;
  level?: Scalars['Int'];
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
};


export type QueryManagedInstancesArgs = {
  company?: InputMaybe<Scalars['String']>;
  sites?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryManagedSitesArgs = {
  filter?: InputMaybe<SitesFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<Sort>>;
};


export type QueryManagerGroupsArgs = {
  companyName?: InputMaybe<Scalars['String']>;
  instanceName?: InputMaybe<Scalars['String']>;
  level?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
};


export type QueryQuerySiteGroupArgs = {
  groupName: Scalars['String'];
  siteName: Scalars['String'];
};


export type QueryResetTwoFactorAuthenticationArgs = {
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type QuerySendPasswordResetLinkArgs = {
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type QuerySendTestEmailArgs = {
  companyKey: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type QuerySendTwoFactorActivationRequestArgs = {
  code: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
};


export type QueryServicesArgs = {
  application?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  siteName?: InputMaybe<Scalars['String']>;
};


export type QueryServiceTicketsByGroupAndApplicationsArgs = {
  applications: Array<InputMaybe<Scalars['String']>>;
  company: Scalars['String'];
  group: Scalars['String'];
};


export type QueryServiceTicketsBySessionArgs = {
  site?: InputMaybe<Scalars['String']>;
  tgt: Scalars['String'];
};


export type QuerySettingArgs = {
  key: Scalars['String'];
};


export type QuerySiteReportsArgs = {
  siteName: Scalars['String'];
};


export type QueryTechnicalAccountAttributesArgs = {
  application?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  targetApplication?: InputMaybe<Scalars['String']>;
};


export type QueryValidateCloneGroupArgs = {
  group: GroupInput;
  params: GroupCloneParams;
  sourceCompany: Scalars['String'];
  sourceGroup: Scalars['String'];
};


export type QueryValidateImportGroupArgs = {
  group: GroupInput;
  groupName: Scalars['String'];
  siteName: Scalars['String'];
};


export type QueryValidateTokenArgs = {
  token: Scalars['String'];
};


export type QueryWorkspacesArgs = {
  application?: InputMaybe<Scalars['String']>;
  filterByKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterByTags?: InputMaybe<Scalars['String']>;
};

export type Registration = {
  __typename?: 'Registration';
  approved: Scalars['Int'];
  approved_by?: Maybe<Scalars['String']>;
  approved_date?: Maybe<Scalars['Date']>;
  code?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  created: Scalars['Date'];
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export enum Regulation {
  Alderney = 'ALDERNEY',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Armenia = 'ARMENIA',
  Austria = 'AUSTRIA',
  Belgium = 'BELGIUM',
  Bulgaria = 'BULGARIA',
  CaOntario = 'CA_ONTARIO',
  CityOfBuenosAires = 'CITY_OF_BUENOS_AIRES',
  Colombia = 'COLOMBIA',
  Colorado = 'COLORADO',
  Costarica = 'COSTARICA',
  Croatia = 'CROATIA',
  Czech = 'CZECH',
  Curacao = 'CURACAO',
  Denmark = 'DENMARK',
  EspMadrid = 'ESP_MADRID',
  Estonia = 'ESTONIA',
  Finland = 'FINLAND',
  France = 'FRANCE',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  Ghana = 'GHANA',
  Gibraltar = 'GIBRALTAR',
  Greece = 'GREECE',
  Isleofman = 'ISLEOFMAN',
  ItalyAams = 'ITALY_AAMS',
  ItalyPokerstars = 'ITALY_POKERSTARS',
  Jersey = 'JERSEY',
  Kahnawake = 'KAHNAWAKE',
  Kenya = 'KENYA',
  Latvia = 'LATVIA',
  Malta = 'MALTA',
  Mexico = 'MEXICO',
  Michigan = 'MICHIGAN',
  Moorgate = 'MOORGATE',
  Netherlands = 'NETHERLANDS',
  NewJersey = 'NEW_JERSEY',
  Nigeria = 'NIGERIA',
  Norway = 'NORWAY',
  Oklahoma = 'OKLAHOMA',
  Panama = 'PANAMA',
  PhilippinesPagcor = 'PHILIPPINES_PAGCOR',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  ProvinceOfBuenosAires = 'PROVINCE_OF_BUENOS_AIRES',
  Romania = 'ROMANIA',
  Slovakia = 'SLOVAKIA',
  Southafrica = 'SOUTHAFRICA',
  Spain = 'SPAIN',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Zambia = 'ZAMBIA',
  Ukraine = 'UKRAINE',
  UnitedKingdom = 'UNITED_KINGDOM',
  UsArizona = 'US_ARIZONA',
  UsIndiana = 'US_INDIANA',
  UsOhio = 'US_OHIO',
  UsPennsylvania = 'US_PENNSYLVANIA'
}

export type Report = {
  __typename?: 'Report';
  application?: Maybe<Scalars['String']>;
  frozen: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  reportGroup: Array<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  site: SiteIdentifier;
};

export type ReportIdentifier = {
  __typename?: 'ReportIdentifier';
  frozen: Scalars['Boolean'];
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
  reportGroup: Array<Scalars['String']>;
  site: SiteIdentifier;
};

export type ReportInput = {
  application?: InputMaybe<Scalars['String']>;
  frozen?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  reportGroup: Array<Scalars['String']>;
  reportType?: InputMaybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  dedicated: Scalars['Boolean'];
  deprecated: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  key: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  roles: Array<Role>;
  shared: Scalars['Boolean'];
  system: Scalars['Boolean'];
  technicalAccountEnabled: Scalars['Boolean'];
  virtualRole: Scalars['Boolean'];
};

export type ScheduledAuditInput = {
  auditKey: Scalars['String'];
  cronExpression: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  includeCsv: Scalars['Boolean'];
  notificationType: EnumScheduledAuditCallout;
  parameters: Array<KeyValuePair>;
  timezone: Scalars['String'];
};

export type SectionConfiguration = {
  __typename?: 'SectionConfiguration';
  keepClosed: Scalars['Boolean'];
  orderConstraints: Scalars['String'];
  state: SectionState;
  type: SectionType;
};

export enum SectionState {
  Collapsed = 'COLLAPSED',
  Hidden = 'HIDDEN',
  Open = 'OPEN'
}

export enum SectionType {
  Java = 'JAVA',
  Php = 'PHP',
  Remote = 'REMOTE'
}

export type SemicBrand = {
  __typename?: 'SemicBrand';
  brand: Scalars['String'];
  name: Scalars['String'];
  siteName: Scalars['String'];
};

export type SemicJobStatus = {
  __typename?: 'SemicJobStatus';
  ciId?: Maybe<Scalars['String']>;
  cmdbCode?: Maybe<Scalars['String']>;
  errorMessages: Array<Scalars['String']>;
  status: Scalars['String'];
  syncId: Scalars['Int'];
  warningMessages: Array<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  application: Application;
  created: Scalars['Date'];
  enabled: Scalars['Boolean'];
  instances: Array<InstanceIdentifier>;
  modified: Scalars['Date'];
  name: Scalars['String'];
  promoted: Scalars['Boolean'];
  service: Scalars['String'];
  sites: Array<SiteIdentifier>;
  statusMessage?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export type ServiceInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  promoted?: InputMaybe<Scalars['Boolean']>;
  welcomeMessage?: InputMaybe<Scalars['String']>;
};

export type SessionInfo = {
  __typename?: 'SessionInfo';
  hardTimeoutInSeconds: Scalars['Int'];
  idleTimeoutInSeconds: Scalars['Int'];
  sessionCreation: Scalars['Date'];
};

export type SessionSearch = {
  company?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  domain?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  ownSessionsOnly?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider: Scalars['String'];
  value: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  accountKey?: Maybe<Scalars['String']>;
  accountName: Scalars['String'];
  category: SiteGroup;
  certsMassExpiration?: Maybe<Scalars['Date']>;
  companies: Array<Company>;
  created: Scalars['Date'];
  group: SiteGroup;
  id: Scalars['Int'];
  infraAccountKey: Scalars['String'];
  infraAccountName: Scalars['String'];
  instances: Array<Instance>;
  key: Scalars['String'];
  loginNotificationsEnabled: Scalars['Boolean'];
  mode: SiteMode;
  modified: Scalars['Date'];
  name: Scalars['String'];
  networks: Array<Network>;
  occupationalLicenseRequired: Scalars['Boolean'];
  restrictions: Array<Scalars['String']>;
  services: Array<Service>;
  status: SiteStatus;
  supportGroup?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type: SiteType;
  version?: Maybe<Scalars['String']>;
};

export type SiteAdmin = {
  __typename?: 'SiteAdmin';
  activeAuthenticationType?: Maybe<ActiveAuthenticationType>;
  centralAccount: Scalars['Boolean'];
  chatAdmin: Scalars['Boolean'];
  code: Scalars['Long'];
  comments?: Maybe<Scalars['String']>;
  createdDate: Scalars['Date'];
  email: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  failedLoginCount: Scalars['Int'];
  firstName: Scalars['String'];
  frozen: Scalars['Boolean'];
  groups: Array<Scalars['String']>;
  instances: Array<Scalars['String']>;
  lastActivityDate?: Maybe<Scalars['Date']>;
  lastActivityIp?: Maybe<Scalars['String']>;
  lastLoginDate?: Maybe<Scalars['Date']>;
  lastName: Scalars['String'];
  localUsername?: Maybe<Scalars['String']>;
  multipleSessionAction?: Maybe<MultipleSessionAction>;
  outDatedLock: Scalars['Boolean'];
  permissions: Array<AdminScriptPermission>;
  phone?: Maybe<Scalars['String']>;
  twoFactorActivated: Scalars['Boolean'];
  userName: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  wrongPwdLock: Scalars['Boolean'];
};

export type SiteAdminGroup = {
  __typename?: 'SiteAdminGroup';
  code: Scalars['Long'];
  description?: Maybe<Scalars['String']>;
  frozen: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  members: Array<SiteAdmin>;
  name: Scalars['String'];
  permissions: Array<AdminScriptPermission>;
  system: Scalars['Boolean'];
};

export type SiteCertificateDeployment = {
  __typename?: 'SiteCertificateDeployment';
  application: Scalars['String'];
  certificate?: Maybe<Certificate>;
  profile: Scalars['String'];
};

export type SiteCompany = {
  __typename?: 'SiteCompany';
  crmId?: Maybe<Scalars['String']>;
  crmName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SiteDifferences = {
  __typename?: 'SiteDifferences';
  companies: Array<Company>;
  instances: Array<Instance>;
  networks: Array<Network>;
  operators?: Maybe<Array<Company>>;
  services: Array<Service>;
};

export enum SiteGroup {
  Banner = 'BANNER',
  Bingo = 'BINGO',
  CdnOrigin = 'CDN_ORIGIN',
  Ims = 'IMS',
  Live = 'LIVE',
  None = 'NONE',
  Onelogin = 'ONELOGIN',
  Other = 'OTHER',
  Poker = 'POKER',
  Poms = 'POMS',
  PopGpas = 'POP_GPAS',
  Portal = 'PORTAL',
  Progressive = 'PROGRESSIVE',
  Sportsbook = 'SPORTSBOOK',
  System = 'SYSTEM',
  Tv = 'TV'
}

export type SiteIdentifier = {
  __typename?: 'SiteIdentifier';
  category?: Maybe<SiteGroup>;
  group?: Maybe<SiteGroup>;
  id: Scalars['Int'];
  infraAccountKey: Scalars['String'];
  infraAccountName: Scalars['String'];
  key: Scalars['String'];
  loginNotificationsEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  occupationalLicenseRequired: Scalars['Boolean'];
  status?: Maybe<SiteStatus>;
};

export type SiteInput = {
  group: SiteGroup;
  loginNotificationsEnabled: Scalars['Boolean'];
  mode?: InputMaybe<SiteMode>;
  occupationalLicenseRequired: Scalars['Boolean'];
  status: SiteStatus;
  timezone?: InputMaybe<Scalars['String']>;
  type: SiteType;
};

export enum SiteMode {
  Dedicated = 'DEDICATED',
  Shared = 'SHARED'
}

export type SitePermit = {
  __typename?: 'SitePermit';
  admin: AdminAccount;
  id: Scalars['Long'];
  permitNumber: Scalars['String'];
  profile: SitePermitProfile;
  revoked: Scalars['Boolean'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};

export type SitePermitProfile = {
  __typename?: 'SitePermitProfile';
  description: Scalars['String'];
  id: Scalars['Long'];
  managerGroup: AdminGroup;
  name: Scalars['String'];
  notifyDaysBefore: Array<Scalars['Int']>;
  sites: Array<Site>;
  type: SitePermitType;
};

export enum SitePermitType {
  OccupationalLicense = 'OCCUPATIONAL_LICENSE',
  TemporalAccessPermit = 'TEMPORAL_ACCESS_PERMIT'
}

export type SitesFilter = {
  category?: InputMaybe<SiteGroup>;
  closed?: InputMaybe<Scalars['Boolean']>;
  instance?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SiteType>;
};

export enum SiteStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  InHandover = 'IN_HANDOVER',
  SiteUserAccess = 'SITE_USER_ACCESS',
  SystemAccess = 'SYSTEM_ACCESS',
  WaitingGoAlive = 'WAITING_GO_ALIVE'
}

export enum SiteType {
  Container = 'CONTAINER',
  Dev = 'DEV',
  InternalProd = 'INTERNAL_PROD',
  InternalSite = 'INTERNAL_SITE',
  Prod = 'PROD',
  Staging = 'STAGING'
}

export type Sort = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type TechnicalAccount = {
  __typename?: 'TechnicalAccount';
  application?: Maybe<Application>;
  company: Company;
  created?: Maybe<Scalars['Date']>;
  domain?: Maybe<LoginDomain>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  modified?: Maybe<Scalars['Date']>;
  oauthClientId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type TechnicalAccountConf = {
  __typename?: 'TechnicalAccountConf';
  centralVaultPath?: Maybe<Scalars['String']>;
  generateApplicationAccount: Scalars['Boolean'];
  generateConfigApiCredentials: Scalars['Boolean'];
  installSemicClientCertificates?: Maybe<Array<Scalars['String']>>;
  semicClientCertificateProfile?: Maybe<Scalars['String']>;
  semicKeystoreProfile?: Maybe<Scalars['String']>;
  useClientCertificateAuthentication: Scalars['Boolean'];
  vaultPath?: Maybe<Scalars['String']>;
};

export enum TwoFactorAuthenticationMethod {
  Any = 'ANY',
  Ga = 'GA',
  None = 'NONE',
  Radius = 'RADIUS'
}

export type Workspace = {
  __typename?: 'Workspace';
  accessGroup?: Maybe<Scalars['String']>;
  accessPolicy?: Maybe<WorkspaceAccessPolicy>;
  api?: Maybe<Scalars['Boolean']>;
  application?: Maybe<Application>;
  applicationKey: Scalars['String'];
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayCondition?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  importance?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  navigationConfiguration?: Maybe<NavigationConfiguration>;
  permissions: Array<Scalars['String']>;
  permissionsMetaData: Array<PermissionMetaData>;
  regulations: Array<Regulation>;
  sectionConfiguration?: Maybe<SectionConfiguration>;
  sections: Array<Workspace>;
  tags?: Maybe<Scalars['String']>;
};

export enum WorkspaceAccessPolicy {
  Authenticated = 'AUTHENTICATED',
  Authorized = 'AUTHORIZED',
  None = 'NONE',
  Public = 'PUBLIC'
}

export type AdminQuickSearchQueryVariables = Exact<{
  quickSearch: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
}>;


export type AdminQuickSearchQuery = (
  { __typename?: 'Query' }
  & { findAdminAccounts: Array<(
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key' | 'system'>
    ), domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ) }
  )> }
);

export type AdminInfoFragment = (
  { __typename?: 'AdminAccount' }
  & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key' | 'system'>
  ), domain: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'code'>
  ) }
);

export type GetAdminConfigurationQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetAdminConfigurationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAdminConfiguration'>
);

export type SaveAdminConfigurationMutationVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['String'];
}>;


export type SaveAdminConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveAdminConfiguration'>
);

export type AddAdminAccountMutationVariables = Exact<{
  admin: NewAdmin;
}>;


export type AddAdminAccountMutation = (
  { __typename?: 'Mutation' }
  & { addAdminAccount: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'username'>
  ) }
);

export type RemoveAdminConfigurationMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type RemoveAdminConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAdminConfiguration'>
);

export type SetActorTimezoneMutationVariables = Exact<{
  timezone: Scalars['String'];
}>;


export type SetActorTimezoneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setActorTimezone'>
);

export type FullAdminFragment = (
  { __typename?: 'AdminAccount' }
  & Pick<AdminAccount, 'id' | 'username' | 'optInEmails' | 'firstName' | 'position' | 'phoneNumber' | 'hasUserImage' | 'lastName' | 'enabled' | 'email' | 'timezone' | 'mailingListOptIn'>
  & { commonAuthData: (
    { __typename?: 'CommonAuthData' }
    & Pick<CommonAuthData, 'twoFactorActivated' | 'multiplesessionaction' | 'twoFactorEnabled' | 'infraLdapUsername' | 'authenticationMode'>
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key' | 'optInEmails' | 'system'>
    & { mailingList?: Maybe<(
      { __typename?: 'MailingList' }
      & Pick<MailingList, 'name'>
    )> }
  ), domain: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'code' | 'authenticationProvider' | 'enableTwoFactorAuthentication'>
  ), customTags?: Maybe<Array<(
    { __typename?: 'KeyValueTag' }
    & Pick<KeyValueTag, 'key' | 'value'>
  )>> }
);

export type FindAdminReportsFromGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminReportsFromGroupsQuery = (
  { __typename?: 'Query' }
  & { adminGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key'>
    ), grantedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )>, grantedReports: Array<(
      { __typename?: 'ReportIdentifier' }
      & Pick<ReportIdentifier, 'key' | 'name' | 'reportGroup'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'name'>
      ) }
    )>, managedReports: Array<(
      { __typename?: 'ReportIdentifier' }
      & Pick<ReportIdentifier, 'key' | 'name' | 'reportGroup'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'name'>
      ) }
    )> }
  )> }
);

export type FindAdminGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminGroupsQuery = (
  { __typename?: 'Query' }
  & { adminGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name' | 'companyCount' | 'siteCount' | 'instanceCount'>
    & { managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'key' | 'name'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'name'>
      ) }
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    ) }
  )> }
);

export type FindAdminPermissionsFromGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminPermissionsFromGroupsQuery = (
  { __typename?: 'Query' }
  & { adminGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name'>
    & { grantedApplications: Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'key' | 'name'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'dedicated'>
      )>, roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'key' | 'name'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'dedicated'>
        )>, roles: Array<(
          { __typename?: 'Role' }
          & Pick<Role, 'key' | 'name'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'dedicated'>
          )> }
        )> }
      )> }
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    ) }
  )> }
);

export type FindAdminSitesAndInstancesFromGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminSitesAndInstancesFromGroupsQuery = (
  { __typename?: 'Query' }
  & { adminGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name'>
    & { grantedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name' | 'key' | 'accountName'>
    )>, grantedInstances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key'>
      ), company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key'>
      ) }
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    ) }
  )> }
);

export type FindAdminManagerGroupsFromGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminManagerGroupsFromGroupsQuery = (
  { __typename?: 'Query' }
  & { adminGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name'>
    & { managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'name'>
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    ) }
  )> }
);

export type UpdateAdminAccountMutationVariables = Exact<{
  admin: NewAdmin;
  currentCompany: Scalars['String'];
  currentDomain: Scalars['String'];
  currentUsername: Scalars['String'];
  passwordConfirmation?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAdminAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminAccount: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'username'>
  ) }
);

export type FindAdminAccountQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domainCode: Scalars['String'];
  username: Scalars['String'];
}>;


export type FindAdminAccountQuery = (
  { __typename?: 'Query' }
  & { findAdmin?: Maybe<(
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'id' | 'username' | 'optInEmails' | 'firstName' | 'position' | 'phoneNumber' | 'hasUserImage' | 'lastName' | 'enabled' | 'email' | 'timezone' | 'mailingListOptIn'>
    & { commonAuthData: (
      { __typename?: 'CommonAuthData' }
      & Pick<CommonAuthData, 'twoFactorActivated' | 'multiplesessionaction' | 'twoFactorEnabled' | 'infraLdapUsername' | 'authenticationMode'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key' | 'optInEmails' | 'system'>
      & { mailingList?: Maybe<(
        { __typename?: 'MailingList' }
        & Pick<MailingList, 'name'>
      )> }
    ), domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code' | 'authenticationProvider' | 'enableTwoFactorAuthentication'>
    ), customTags?: Maybe<Array<(
      { __typename?: 'KeyValueTag' }
      & Pick<KeyValueTag, 'key' | 'value'>
    )>> }
  )> }
);

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = (
  { __typename?: 'Query' }
  & { whoAmI: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'id' | 'username' | 'optInEmails' | 'firstName' | 'position' | 'phoneNumber' | 'hasUserImage' | 'lastName' | 'enabled' | 'email' | 'timezone' | 'mailingListOptIn'>
    & { customTags?: Maybe<Array<(
      { __typename?: 'KeyValueTag' }
      & Pick<KeyValueTag, 'key' | 'value'>
    )>>, commonAuthData: (
      { __typename?: 'CommonAuthData' }
      & Pick<CommonAuthData, 'twoFactorActivated' | 'multiplesessionaction' | 'twoFactorEnabled' | 'infraLdapUsername' | 'authenticationMode'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key' | 'optInEmails' | 'system'>
      & { mailingList?: Maybe<(
        { __typename?: 'MailingList' }
        & Pick<MailingList, 'name'>
      )> }
    ), domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code' | 'authenticationProvider' | 'enableTwoFactorAuthentication'>
    ) }
  ) }
);

export type SendTestEmailQueryVariables = Exact<{
  companyKey: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
}>;


export type SendTestEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendTestEmail'>
);

export type FindAdAdminsQueryVariables = Exact<{
  usernameSearch: Scalars['String'];
}>;


export type FindAdAdminsQuery = (
  { __typename?: 'Query' }
  & { findAdAdmins: Array<(
    { __typename?: 'AdminResponse' }
    & Pick<AdminResponse, 'message'>
    & { admin: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'username' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    ) }
  )> }
);

export type FindServiceTicketsByGroupAndApplicationsQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  applications: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type FindServiceTicketsByGroupAndApplicationsQuery = (
  { __typename?: 'Query' }
  & { serviceTicketsByGroupAndApplications: Array<(
    { __typename?: 'LoginSession' }
    & Pick<LoginSession, 'id' | 'tgt' | 'type' | 'loginIp' | 'loginDate' | 'lastActivityDate' | 'companyKey' | 'username' | 'domain' | 'open' | 'service' | 'serviceName' | 'siteName'>
  )> }
);

export type EndServiceTicketsMutationVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  applications: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type EndServiceTicketsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'endServiceTickets'>
);

export type SyncAdminAccountMutationVariables = Exact<{
  username: Scalars['String'];
  domain: Scalars['String'];
  company: Scalars['String'];
}>;


export type SyncAdminAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncAdminAccount'>
);

export type FindAdminGroupSitesQueryVariables = Exact<{
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
}>;


export type FindAdminGroupSitesQuery = (
  { __typename?: 'Query' }
  & { findAdminSites: (
    { __typename?: 'GroupSitePage' }
    & Pick<GroupSitePage, 'size' | 'first' | 'after'>
    & { page: Array<(
      { __typename?: 'GroupSiteWithGroups' }
      & Pick<GroupSiteWithGroups, 'canActorManageItem'>
      & { item: (
        { __typename?: 'GroupSite' }
        & Pick<GroupSite, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances'>
        & { identifier: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'name' | 'key' | 'group' | 'infraAccountName' | 'infraAccountKey' | 'status'>
        ) }
      ), groups: Array<(
        { __typename?: 'GroupSite' }
        & Pick<GroupSite, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances'>
        & { group: (
          { __typename?: 'AdminGroupIdentifier' }
          & Pick<AdminGroupIdentifier, 'key' | 'name'>
          & { company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type FindAdminGroupCompaniesQueryVariables = Exact<{
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
}>;


export type FindAdminGroupCompaniesQuery = (
  { __typename?: 'Query' }
  & { findAdminCompanies: (
    { __typename?: 'GroupCompanyPage' }
    & Pick<GroupCompanyPage, 'size' | 'first' | 'after'>
    & { page?: Maybe<Array<(
      { __typename?: 'GroupCompanyWithGroups' }
      & Pick<GroupCompanyWithGroups, 'canActorManageItem'>
      & { item: (
        { __typename?: 'GroupCompany' }
        & Pick<GroupCompany, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances'>
        & { identifier: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'name' | 'key'>
        ) }
      ), groups: Array<(
        { __typename?: 'GroupCompany' }
        & Pick<GroupCompany, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances'>
        & { group: (
          { __typename?: 'AdminGroupIdentifier' }
          & Pick<AdminGroupIdentifier, 'key' | 'name'>
          & { company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key'>
          ) }
        ) }
      )> }
    )>> }
  ) }
);

export type FindAdminGroupInstancesQueryVariables = Exact<{
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
}>;


export type FindAdminGroupInstancesQuery = (
  { __typename?: 'Query' }
  & { findAdminInstances: (
    { __typename?: 'GroupInstancePage' }
    & Pick<GroupInstancePage, 'size' | 'first' | 'after'>
    & { page: Array<(
      { __typename?: 'GroupInstanceWithGroups' }
      & Pick<GroupInstanceWithGroups, 'canActorManageItem'>
      & { item: (
        { __typename?: 'GroupInstance' }
        & Pick<GroupInstance, 'access' | 'delegateManagement'>
        & { identifier: (
          { __typename?: 'InstanceIdentifier' }
          & Pick<InstanceIdentifier, 'name' | 'enabled'>
          & { site: (
            { __typename?: 'SiteIdentifier' }
            & Pick<SiteIdentifier, 'key' | 'name'>
          ), company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'name' | 'key'>
          ) }
        ) }
      ), groups: Array<(
        { __typename?: 'GroupInstance' }
        & Pick<GroupInstance, 'access' | 'delegateManagement'>
        & { group: (
          { __typename?: 'AdminGroupIdentifier' }
          & Pick<AdminGroupIdentifier, 'key' | 'name'>
          & { company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type FindAdminGroupReportsQueryVariables = Exact<{
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
}>;


export type FindAdminGroupReportsQuery = (
  { __typename?: 'Query' }
  & { findAdminReports: (
    { __typename?: 'GroupReportPage' }
    & Pick<GroupReportPage, 'size' | 'first' | 'after'>
    & { page: Array<(
      { __typename?: 'GroupReportWithGroups' }
      & Pick<GroupReportWithGroups, 'canActorManageItem'>
      & { item: (
        { __typename?: 'GroupReport' }
        & Pick<GroupReport, 'access' | 'delegateManagement'>
        & { identifier: (
          { __typename?: 'ReportIdentifier' }
          & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
          & { site: (
            { __typename?: 'SiteIdentifier' }
            & Pick<SiteIdentifier, 'key' | 'name'>
          ) }
        ) }
      ), groups: Array<(
        { __typename?: 'GroupReport' }
        & Pick<GroupReport, 'access' | 'delegateManagement'>
        & { group: (
          { __typename?: 'AdminGroupIdentifier' }
          & Pick<AdminGroupIdentifier, 'key' | 'name'>
          & { company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type FindAdminGroupPermissionsQueryVariables = Exact<{
  findAdmin: FindAdmin;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<GroupItemSearch>;
}>;


export type FindAdminGroupPermissionsQuery = (
  { __typename?: 'Query' }
  & { findAdminPermissions: (
    { __typename?: 'GroupPermissionPage' }
    & Pick<GroupPermissionPage, 'size' | 'first' | 'after'>
    & { page: Array<(
      { __typename?: 'GroupPermissionWithGroups' }
      & { item: (
        { __typename?: 'GroupPermission' }
        & Pick<GroupPermission, 'access' | 'delegateManagement' | 'key' | 'name' | 'tags' | 'regulations' | 'roleDerived' | 'reportPermission' | 'description'>
        & { application: (
          { __typename?: 'ApplicationIdentifier' }
          & Pick<ApplicationIdentifier, 'key' | 'name' | 'owner'>
        ) }
      ), groups: Array<(
        { __typename?: 'GroupPermission' }
        & Pick<GroupPermission, 'access' | 'delegateManagement' | 'grantedByRoleNames' | 'managementByRoleNames'>
        & { group: (
          { __typename?: 'AdminGroupIdentifier' }
          & Pick<AdminGroupIdentifier, 'key' | 'name'>
          & { company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type FindAdminApplicationsQueryVariables = Exact<{
  findAdmin: FindAdmin;
}>;


export type FindAdminApplicationsQuery = (
  { __typename?: 'Query' }
  & { findAdminApplications: Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'key' | 'name'>
  )>> }
);

export type FindApplicationsQueryVariables = Exact<{
  loadFromDB?: InputMaybe<Scalars['Boolean']>;
}>;


export type FindApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications: Array<(
    { __typename?: 'Application' }
    & Pick<Application, 'key' | 'name' | 'owner' | 'group' | 'authenticationProtocol' | 'authorizationApi' | 'deprecated'>
    & { workspaces?: Maybe<Array<(
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'key' | 'name' | 'accessGroup' | 'permissions' | 'tags' | 'api' | 'link' | 'regulations'>
    )>>, permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
    )>, referredPermissions?: Maybe<Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
    )>>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'key' | 'name' | 'description' | 'virtualRole' | 'shared' | 'deprecated'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
        & { roles: Array<(
          { __typename?: 'Role' }
          & Pick<Role, 'key'>
        )> }
      )>, permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'key'>
      )> }
    )> }
  )> }
);

export type FindApplicationQueryVariables = Exact<{
  application: Scalars['String'];
}>;


export type FindApplicationQuery = (
  { __typename?: 'Query' }
  & { findApplication?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'key' | 'name' | 'owner' | 'group' | 'authenticationProtocol' | 'authorizationApi' | 'deprecated'>
    & { workspaces?: Maybe<Array<(
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'key' | 'name' | 'accessGroup' | 'permissions' | 'tags' | 'api' | 'link' | 'regulations'>
    )>>, permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
    )>, referredPermissions?: Maybe<Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
    )>>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'key' | 'name' | 'description' | 'virtualRole' | 'shared' | 'deprecated'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
        & { roles: Array<(
          { __typename?: 'Role' }
          & Pick<Role, 'key'>
        )> }
      )>, permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'key'>
      )> }
    )> }
  )> }
);

export type FindRoleGroupsQueryVariables = Exact<{
  application: Scalars['String'];
  role: Scalars['String'];
}>;


export type FindRoleGroupsQuery = (
  { __typename?: 'Query' }
  & { findRoleGroups: Array<(
    { __typename?: 'AdminGroupRef' }
    & Pick<AdminGroupRef, 'name' | 'key' | 'company' | 'access' | 'management'>
  )> }
);

export type FindPermissionGroupsQueryVariables = Exact<{
  application: Scalars['String'];
  permission: Scalars['String'];
}>;


export type FindPermissionGroupsQuery = (
  { __typename?: 'Query' }
  & { findPermissionGroups: Array<(
    { __typename?: 'AdminGroupRef' }
    & Pick<AdminGroupRef, 'name' | 'key' | 'company' | 'access' | 'management'>
  )> }
);

export type FindApplicationsWithPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindApplicationsWithPermissionsQuery = (
  { __typename?: 'Query' }
  & { applications: Array<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'key'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'name' | 'key'>
    )> }
  )> }
);

export type DeleteApplicationItemsMutationVariables = Exact<{
  items: Array<ApplicationItemInput> | ApplicationItemInput;
  comment: Scalars['String'];
}>;


export type DeleteApplicationItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApplicationItems'>
);

export type FindDeprecatedAppItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindDeprecatedAppItemsQuery = (
  { __typename?: 'Query' }
  & { findDeprecatedAppItems: Array<(
    { __typename?: 'ApplicationPreview' }
    & Pick<ApplicationPreview, 'id' | 'key' | 'name' | 'deprecated' | 'modified'>
    & { roles: Array<(
      { __typename?: 'PermissionPreview' }
      & Pick<PermissionPreview, 'id' | 'key' | 'name' | 'deprecated' | 'modified'>
    )>, permissions: Array<(
      { __typename?: 'PermissionPreview' }
      & Pick<PermissionPreview, 'id' | 'key' | 'name' | 'deprecated' | 'modified'>
    )> }
  )> }
);

export type ReloadApplicationsMutationVariables = Exact<{
  reloadOnlyApps?: InputMaybe<Scalars['Boolean']>;
}>;


export type ReloadApplicationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reloadApplications'>
);

export type MigratePermissionMutationVariables = Exact<{
  fromPermission: Scalars['String'];
  fromApplication: Scalars['String'];
  fromIsRole: Scalars['Boolean'];
  toPermission: Scalars['String'];
  toApplication: Scalars['String'];
  toIsRole: Scalars['Boolean'];
  removeSourcePermission: Scalars['Boolean'];
  comment: Scalars['String'];
}>;


export type MigratePermissionMutation = (
  { __typename?: 'Mutation' }
  & { migratePermission: Array<(
    { __typename?: 'PermissionMigrationGroup' }
    & Pick<PermissionMigrationGroup, 'sourcePermManagement' | 'sourcePermAccess' | 'permToBeAddedAccess' | 'permToBeAddedManagement'>
    & { group: (
      { __typename?: 'AdminGroupIdentifier' }
      & Pick<AdminGroupIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type GroupReferenceFragment = (
  { __typename?: 'AdminGroupRef' }
  & Pick<AdminGroupRef, 'name' | 'key' | 'company' | 'access' | 'management'>
);

export type FullApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'key' | 'name' | 'owner' | 'group' | 'authenticationProtocol' | 'authorizationApi' | 'deprecated'>
  & { workspaces?: Maybe<Array<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'key' | 'name' | 'accessGroup' | 'permissions' | 'tags' | 'api' | 'link' | 'regulations'>
  )>>, permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
  )>, referredPermissions?: Maybe<Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
  )>>, roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'key' | 'name' | 'description' | 'virtualRole' | 'shared' | 'deprecated'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'key'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
      )> }
    )>, permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'key'>
    )> }
  )> }
);

export type FullRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'key' | 'name' | 'description' | 'virtualRole' | 'shared' | 'deprecated'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'key'>
  )> }
);

export type FullPermissionFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'key' | 'name' | 'tags' | 'system' | 'reportPermission' | 'taEnabled' | 'dedicated' | 'regulations' | 'owner' | 'description' | 'deprecated'>
);

export type FullWorkspaceFragment = (
  { __typename?: 'Workspace' }
  & Pick<Workspace, 'key' | 'name' | 'accessGroup' | 'permissions' | 'tags' | 'api' | 'link' | 'regulations'>
);

export type LightApplicationPermissionFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'name' | 'key'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'name' | 'key'>
  )> }
);

export type ExecuteAuditQueryVariables = Exact<{
  name: Scalars['String'];
  parameters: Array<KeyValuePair> | KeyValuePair;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type ExecuteAuditQuery = (
  { __typename?: 'Query' }
  & { executeAudit: (
    { __typename?: 'AuditResultPage' }
    & Pick<AuditResultPage, 'size' | 'first' | 'after'>
    & { page: (
      { __typename?: 'AuditResult' }
      & Pick<AuditResult, 'headers' | 'rows'>
    ) }
  ) }
);

export type FindAuditCountQueryVariables = Exact<{
  name: Scalars['String'];
  parameters: Array<KeyValuePair> | KeyValuePair;
}>;


export type FindAuditCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findAuditResultsCount'>
);

export type AddScheduledAuditMutationVariables = Exact<{
  configuration: ScheduledAuditInput;
}>;


export type AddScheduledAuditMutation = (
  { __typename?: 'Mutation' }
  & { addScheduledAuditConfiguration: (
    { __typename?: 'AuditConfiguration' }
    & Pick<AuditConfiguration, 'id' | 'created' | 'notificationType' | 'includeCsv' | 'description'>
    & { parameters: Array<(
      { __typename?: 'KeyValueTag' }
      & Pick<KeyValueTag, 'key' | 'value'>
    )>, creator: (
      { __typename?: 'Actor' }
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'username'>
      )> }
    ) }
  ) }
);

export type RemoveScheduledAuditMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveScheduledAuditMutation = (
  { __typename?: 'Mutation' }
  & { removeScheduledAuditConfiguration: (
    { __typename?: 'AuditConfiguration' }
    & Pick<AuditConfiguration, 'id'>
  ) }
);

export type FindAuditQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type FindAuditQuery = (
  { __typename?: 'Query' }
  & { findAudits: Array<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'name' | 'key' | 'description' | 'tags'>
    & { parameters: Array<(
      { __typename?: 'AuditParameter' }
      & Pick<AuditParameter, 'name' | 'key' | 'type' | 'required' | 'description' | 'multiple'>
      & { options?: Maybe<Array<(
        { __typename?: 'KeyValueTag' }
        & Pick<KeyValueTag, 'key' | 'value'>
      )>> }
    )> }
  )> }
);

export type FindAuditsListQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAuditsListQuery = (
  { __typename?: 'Query' }
  & { findAudits: Array<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'name' | 'key' | 'description' | 'tags'>
  )> }
);

export type FindScheduledAuditConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindScheduledAuditConfigurationsQuery = (
  { __typename?: 'Query' }
  & { findScheduledAuditConfigurations: Array<(
    { __typename?: 'AuditConfiguration' }
    & Pick<AuditConfiguration, 'id' | 'description' | 'cronExpression' | 'timezone' | 'notificationType' | 'includeCsv' | 'created' | 'enabled'>
    & { audit: (
      { __typename?: 'Audit' }
      & Pick<Audit, 'key' | 'name' | 'description' | 'tags'>
      & { parameters: Array<(
        { __typename?: 'AuditParameter' }
        & Pick<AuditParameter, 'name' | 'key' | 'type' | 'required' | 'description' | 'multiple'>
        & { options?: Maybe<Array<(
          { __typename?: 'KeyValueTag' }
          & Pick<KeyValueTag, 'key' | 'value'>
        )>> }
      )> }
    ), parameters: Array<(
      { __typename?: 'KeyValueTag' }
      & Pick<KeyValueTag, 'key' | 'value'>
    )>, creator: (
      { __typename?: 'Actor' }
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'username'>
        & { domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )> }
    ) }
  )> }
);

export type AuditReferenceFragment = (
  { __typename?: 'Audit' }
  & Pick<Audit, 'name' | 'key' | 'description' | 'tags'>
);

export type FullAuditConfigurationFragment = (
  { __typename?: 'AuditConfiguration' }
  & Pick<AuditConfiguration, 'id' | 'description' | 'cronExpression' | 'timezone' | 'notificationType' | 'includeCsv' | 'created' | 'enabled'>
  & { audit: (
    { __typename?: 'Audit' }
    & Pick<Audit, 'key' | 'name' | 'description' | 'tags'>
    & { parameters: Array<(
      { __typename?: 'AuditParameter' }
      & Pick<AuditParameter, 'name' | 'key' | 'type' | 'required' | 'description' | 'multiple'>
      & { options?: Maybe<Array<(
        { __typename?: 'KeyValueTag' }
        & Pick<KeyValueTag, 'key' | 'value'>
      )>> }
    )> }
  ), parameters: Array<(
    { __typename?: 'KeyValueTag' }
    & Pick<KeyValueTag, 'key' | 'value'>
  )>, creator: (
    { __typename?: 'Actor' }
    & { admin?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'username'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )> }
  ) }
);

export type SyncClientCertsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncClientCertsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncClientCerts'>
);

export type AddClientCertTemplateMutationVariables = Exact<{
  template: NewCertificateTemplate;
}>;


export type AddClientCertTemplateMutation = (
  { __typename?: 'Mutation' }
  & { value: (
    { __typename?: 'CertificateTemplate' }
    & Pick<CertificateTemplate, 'id' | 'name' | 'enabled' | 'dc' | 'profile' | 'comments' | 'brandBindings'>
    & { sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    ), notificationGroups: Array<(
      { __typename?: 'AdminGroupIdentifier' }
      & Pick<AdminGroupIdentifier, 'name' | 'key'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name'>
      ) }
    )> }
  ) }
);

export type DeleteClientCertTemplateMutationVariables = Exact<{
  templateId: Scalars['Int'];
}>;


export type DeleteClientCertTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClientCertTemplate'>
);

export type ToggleClientCertTemplateMutationVariables = Exact<{
  enable: Scalars['Boolean'];
  templateId: Scalars['Int'];
}>;


export type ToggleClientCertTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleClientCertTemplate'>
);

export type CountClientCertTemplatesQueryVariables = Exact<{
  search: CertificateTemplateSearch;
}>;


export type CountClientCertTemplatesQuery = (
  { __typename?: 'Query' }
  & { value: Query['countClientCertTemplates'] }
);

export type FindClientCertTemplatesQueryVariables = Exact<{
  search: CertificateTemplateSearch;
  pagination?: InputMaybe<Pagination>;
  sort: Array<Sort> | Sort;
}>;


export type FindClientCertTemplatesQuery = (
  { __typename?: 'Query' }
  & { value: Array<(
    { __typename?: 'CertificateTemplate' }
    & Pick<CertificateTemplate, 'id' | 'name' | 'enabled' | 'dc' | 'profile' | 'comments' | 'brandBindings'>
    & { sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    ), notificationGroups: Array<(
      { __typename?: 'AdminGroupIdentifier' }
      & Pick<AdminGroupIdentifier, 'name' | 'key'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name'>
      ) }
    )> }
  )> }
);

export type FindClientCertsQueryVariables = Exact<{
  templateId: Scalars['Int'];
}>;


export type FindClientCertsQuery = (
  { __typename?: 'Query' }
  & { value: Array<(
    { __typename?: 'Certificate' }
    & Pick<Certificate, 'id' | 'name' | 'status' | 'validFrom' | 'validTo' | 'createdAt' | 'notify' | 'chain' | 'certificate' | 'comment' | 'lastDownloaded'>
    & { lastDownloadedBy?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'firstName' | 'lastName' | 'username'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )> }
  )> }
);

export type ToggleClientCertRevocationMutationVariables = Exact<{
  certId: Scalars['Int'];
  revoke: Scalars['Boolean'];
}>;


export type ToggleClientCertRevocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleClientCertRevocation'>
);

export type ToggleClientCertNotificationsMutationVariables = Exact<{
  certId: Scalars['Int'];
  notify: Scalars['Boolean'];
}>;


export type ToggleClientCertNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleClientCertNotifications'>
);

export type GetClientCertPrivateKeyQueryVariables = Exact<{
  certId: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
  format: CertificateFormat;
}>;


export type GetClientCertPrivateKeyQuery = (
  { __typename?: 'Query' }
  & { value: Query['getClientCertPrivateKey'] }
);

export type ImportClientCertsMutationVariables = Exact<{
  templateId: Scalars['Int'];
}>;


export type ImportClientCertsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importClientCerts'>
);

export type IssueClientCertMutationVariables = Exact<{
  cert: NewCertificate;
  muteReplacedNotifications?: InputMaybe<Scalars['Boolean']>;
  replaces?: InputMaybe<Scalars['Int']>;
  notifyDaysBefore: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type IssueClientCertMutation = (
  { __typename?: 'Mutation' }
  & { value: (
    { __typename?: 'Certificate' }
    & Pick<Certificate, 'id' | 'name' | 'status' | 'validFrom' | 'validTo' | 'createdAt' | 'notify' | 'chain' | 'certificate' | 'comment' | 'lastDownloaded'>
    & { lastDownloadedBy?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'firstName' | 'lastName' | 'username'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )> }
  ) }
);

export type GetImportableCertificatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetImportableCertificatesQuery = (
  { __typename?: 'Query' }
  & { value: Array<(
    { __typename?: 'Certificate' }
    & Pick<Certificate, 'id' | 'name' | 'dc' | 'profile' | 'sites' | 'status' | 'createdAt' | 'validFrom' | 'validTo' | 'snowTicket' | 'comment'>
  )> }
);

export type GetClientCertQueryVariables = Exact<{
  certId: Scalars['Int'];
}>;


export type GetClientCertQuery = (
  { __typename?: 'Query' }
  & { getClientCert: (
    { __typename?: 'Certificate' }
    & Pick<Certificate, 'id'>
    & { notifications: Array<(
      { __typename?: 'CertificateNotification' }
      & Pick<CertificateNotification, 'id' | 'date' | 'sent'>
    )> }
  ) }
);

export type AddClientCertNotificationGroupMutationVariables = Exact<{
  templateId: Scalars['Int'];
  group: Scalars['String'];
}>;


export type AddClientCertNotificationGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addClientCertNotificationGroups'>
);

export type RemoveClientCertNotificationGroupMutationVariables = Exact<{
  templateId: Scalars['Int'];
  group: Scalars['String'];
}>;


export type RemoveClientCertNotificationGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeClientCertNotificationGroups'>
);

export type AddClientCertNotificationMutationVariables = Exact<{
  certId: Scalars['Int'];
  daysBeforeExpiration: Scalars['Int'];
}>;


export type AddClientCertNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addClientCertNotification: (
    { __typename?: 'CertificateNotification' }
    & Pick<CertificateNotification, 'id'>
  ) }
);

export type DeleteClientCertNotificationMutationVariables = Exact<{
  notificationId: Scalars['Int'];
}>;


export type DeleteClientCertNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteClientCertNotification: (
    { __typename?: 'CertificateNotification' }
    & Pick<CertificateNotification, 'id'>
  ) }
);

export type CertificateTemplateTableEntryFragment = (
  { __typename?: 'CertificateTemplate' }
  & Pick<CertificateTemplate, 'id' | 'name' | 'enabled' | 'dc' | 'profile' | 'comments' | 'brandBindings'>
  & { sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name'>
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key'>
  ), notificationGroups: Array<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'name'>
    ) }
  )> }
);

export type ImportableCertificateEntryFragment = (
  { __typename?: 'Certificate' }
  & Pick<Certificate, 'id' | 'name' | 'dc' | 'profile' | 'sites' | 'status' | 'createdAt' | 'validFrom' | 'validTo' | 'snowTicket' | 'comment'>
);

export type CertificateTableEntryFragment = (
  { __typename?: 'Certificate' }
  & Pick<Certificate, 'id' | 'name' | 'status' | 'validFrom' | 'validTo' | 'createdAt' | 'notify' | 'chain' | 'certificate' | 'comment' | 'lastDownloaded'>
  & { lastDownloadedBy?: Maybe<(
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'firstName' | 'lastName' | 'username'>
    & { domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ) }
  )> }
);

export type FindManagedCompanyAndDomainRelationsQueryVariables = Exact<{
  authProviders?: InputMaybe<Array<AuthenticationProvider> | AuthenticationProvider>;
}>;


export type FindManagedCompanyAndDomainRelationsQuery = (
  { __typename?: 'Query' }
  & { managedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key' | 'id' | 'system'>
    & { domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code' | 'primary' | 'authenticationProvider' | 'enabled'>
    )> }
  )> }
);

export type FindManagedCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedCompaniesQuery = (
  { __typename?: 'Query' }
  & { managedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'key' | 'crmId' | 'crmName' | 'syncedFromCrm' | 'system' | 'created' | 'modified' | 'optInEmails'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key'>
      ), passwordPolicy?: Maybe<(
        { __typename?: 'PasswordPolicy' }
        & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
      )> }
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )>, address?: Maybe<(
      { __typename?: 'CompanyAddress' }
      & Pick<CompanyAddress, 'address' | 'city' | 'zip' | 'country'>
    )>, contactInfo?: Maybe<(
      { __typename?: 'CompanyContactInfo' }
      & Pick<CompanyContactInfo, 'phone' | 'email'>
    )> }
  )> }
);

export type FindCompaniesForCompaniesListQueryVariables = Exact<{ [key: string]: never; }>;


export type FindCompaniesForCompaniesListQuery = (
  { __typename?: 'Query' }
  & { managedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'crmId' | 'crmName' | 'key' | 'system' | 'created' | 'modified'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'authenticationProvider'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )> }
  )> }
);

export type FindManagedCompaniesWithSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedCompaniesWithSitesQuery = (
  { __typename?: 'Query' }
  & { managedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key' | 'system'>
    & { sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )> }
  )> }
);

export type FindCompanyByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type FindCompanyByKeyQuery = (
  { __typename?: 'Query' }
  & { findCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'key' | 'crmId' | 'crmName' | 'syncedFromCrm' | 'system' | 'created' | 'modified' | 'optInEmails'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key'>
      ), passwordPolicy?: Maybe<(
        { __typename?: 'PasswordPolicy' }
        & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
      )> }
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )>, address?: Maybe<(
      { __typename?: 'CompanyAddress' }
      & Pick<CompanyAddress, 'address' | 'city' | 'zip' | 'country'>
    )>, contactInfo?: Maybe<(
      { __typename?: 'CompanyContactInfo' }
      & Pick<CompanyContactInfo, 'phone' | 'email'>
    )> }
  ) }
);

export type FindGrantedCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindGrantedCompaniesQuery = (
  { __typename?: 'Query' }
  & { grantedCompanies: Array<(
    { __typename?: 'GrantedCompany' }
    & Pick<GrantedCompany, 'name' | 'key' | 'system'>
    & { instances: Array<(
      { __typename?: 'GrantedInstance' }
      & Pick<GrantedInstance, 'name' | 'regulation'>
    )> }
  )> }
);

export type FindManagedCompanyReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedCompanyReferencesQuery = (
  { __typename?: 'Query' }
  & { managedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'key' | 'system'>
  )> }
);

export type FindGrantedCompanyReferencesLightQueryVariables = Exact<{ [key: string]: never; }>;


export type FindGrantedCompanyReferencesLightQuery = (
  { __typename?: 'Query' }
  & { grantedCompanies: Array<(
    { __typename?: 'GrantedCompany' }
    & Pick<GrantedCompany, 'name' | 'key'>
  )> }
);

export type FindCompanyRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindCompanyRolesQuery = (
  { __typename?: 'Query' }
  & { findCompanyRoles: Array<Maybe<(
    { __typename?: 'CompanyRole' }
    & Pick<CompanyRole, 'name' | 'label' | 'description'>
  )>> }
);

export type AddCompanyMutationVariables = Exact<{
  company: CompanyInput;
}>;


export type AddCompanyMutation = (
  { __typename?: 'Mutation' }
  & { addCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'key' | 'crmId' | 'crmName' | 'syncedFromCrm' | 'system' | 'created' | 'modified' | 'optInEmails'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key'>
      ), passwordPolicy?: Maybe<(
        { __typename?: 'PasswordPolicy' }
        & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
      )> }
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )>, address?: Maybe<(
      { __typename?: 'CompanyAddress' }
      & Pick<CompanyAddress, 'address' | 'city' | 'zip' | 'country'>
    )>, contactInfo?: Maybe<(
      { __typename?: 'CompanyContactInfo' }
      & Pick<CompanyContactInfo, 'phone' | 'email'>
    )> }
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  company: CompanyInput;
  key: Scalars['String'];
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'key' | 'crmId' | 'crmName' | 'syncedFromCrm' | 'system' | 'created' | 'modified' | 'optInEmails'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key'>
      ), passwordPolicy?: Maybe<(
        { __typename?: 'PasswordPolicy' }
        & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
      )> }
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )>, address?: Maybe<(
      { __typename?: 'CompanyAddress' }
      & Pick<CompanyAddress, 'address' | 'city' | 'zip' | 'country'>
    )>, contactInfo?: Maybe<(
      { __typename?: 'CompanyContactInfo' }
      & Pick<CompanyContactInfo, 'phone' | 'email'>
    )> }
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { removeCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'key' | 'system'>
  ) }
);

export type CountCompaniesQueryVariables = Exact<{
  search: CompanySearch;
}>;


export type CountCompaniesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCompanies'>
);

export type FindCompaniesQueryVariables = Exact<{
  search: CompanySearch;
  pagination: Pagination;
  sort?: InputMaybe<Array<Sort> | Sort>;
}>;


export type FindCompaniesQuery = (
  { __typename?: 'Query' }
  & { findCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'externalId' | 'name' | 'crmId' | 'crmName' | 'key' | 'system' | 'created' | 'modified'>
    & { roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label'>
    )>, domains: Array<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'authenticationProvider'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )>, sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    )> }
  )> }
);

export type FindDefaultPasswordPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type FindDefaultPasswordPolicyQuery = (
  { __typename?: 'Query' }
  & { findDefaultPasswordPolicy: (
    { __typename?: 'PasswordPolicy' }
    & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordMinSpecials' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
  ) }
);

export type FindPasswordPolicyContextQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type FindPasswordPolicyContextQuery = (
  { __typename?: 'Query' }
  & { findPasswordPolicyContext: (
    { __typename?: 'PasswordPolicyContext' }
    & Pick<PasswordPolicyContext, 'passwordStorageSchemeOptions'>
    & { passwordWarningReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMaxAgeReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinAgeReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordInHistoryReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMaxRepeatsReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinSpecialsReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinDigitsReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinAlphasReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinLengthReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinLowersReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinUppersReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ), passwordMinCategoriesReq: (
      { __typename?: 'IntRange' }
      & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
    ) }
  ) }
);

export type UpdatePasswordPolicyMutationVariables = Exact<{
  company: Scalars['String'];
  domain: Scalars['String'];
  policy: PasswordPolicyInput;
}>;


export type UpdatePasswordPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updatePwdPolicy: (
    { __typename?: 'PasswordPolicy' }
    & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordMinSpecials' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
  ) }
);

export type AddLoginDomainMutationVariables = Exact<{
  company: Scalars['String'];
  domain: LoginDomainInput;
}>;


export type AddLoginDomainMutation = (
  { __typename?: 'Mutation' }
  & { addLoginDomain: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ), passwordPolicy?: Maybe<(
      { __typename?: 'PasswordPolicy' }
      & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
    )> }
  ) }
);

export type EditLoginDomainMutationVariables = Exact<{
  company: Scalars['String'];
  domain: LoginDomainInput;
}>;


export type EditLoginDomainMutation = (
  { __typename?: 'Mutation' }
  & { updateLoginDomain: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ), passwordPolicy?: Maybe<(
      { __typename?: 'PasswordPolicy' }
      & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
    )> }
  ) }
);

export type RemoveLoginDomainMutationVariables = Exact<{
  company: Scalars['String'];
  domain: Scalars['String'];
}>;


export type RemoveLoginDomainMutation = (
  { __typename?: 'Mutation' }
  & { removeLoginDomain: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ), passwordPolicy?: Maybe<(
      { __typename?: 'PasswordPolicy' }
      & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
    )> }
  ) }
);

export type RefreshLoginDomainMetadataMutationVariables = Exact<{
  company: Scalars['String'];
  domain: Scalars['String'];
}>;


export type RefreshLoginDomainMetadataMutation = (
  { __typename?: 'Mutation' }
  & { refreshLoginDomainMetadata: (
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ), passwordPolicy?: Maybe<(
      { __typename?: 'PasswordPolicy' }
      & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
    )> }
  ) }
);

export type SyncCompanyCrmDataMutationVariables = Exact<{
  syncAll: Scalars['Boolean'];
}>;


export type SyncCompanyCrmDataMutation = (
  { __typename?: 'Mutation' }
  & { syncCompanyCrmData: Array<(
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'name'>
  )> }
);

export type CompanyWithLoginDomainsFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'key' | 'id' | 'system'>
  & { domains: Array<(
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'code' | 'primary' | 'authenticationProvider' | 'enabled'>
  )> }
);

export type CompanyReferenceFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'key' | 'system'>
);

export type CompanyReferenceLightFragment = (
  { __typename?: 'GrantedCompany' }
  & Pick<GrantedCompany, 'name' | 'key'>
);

export type CompanyWithSitesFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'key' | 'system'>
  & { sites: Array<(
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'name' | 'key'>
  )> }
);

export type FullGrantedCompanyFragment = (
  { __typename?: 'GrantedCompany' }
  & Pick<GrantedCompany, 'name' | 'key' | 'system'>
  & { instances: Array<(
    { __typename?: 'GrantedInstance' }
    & Pick<GrantedInstance, 'name' | 'regulation'>
  )> }
);

export type CompanyListEntryFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'externalId' | 'name' | 'crmId' | 'crmName' | 'key' | 'system' | 'created' | 'modified'>
  & { roles: Array<(
    { __typename?: 'CompanyRole' }
    & Pick<CompanyRole, 'name' | 'label'>
  )>, domains: Array<(
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'authenticationProvider'>
  )>, instances: Array<(
    { __typename?: 'InstanceIdentifier' }
    & Pick<InstanceIdentifier, 'name' | 'enabled'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )>, sites: Array<(
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'key' | 'name'>
  )> }
);

export type FullCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'externalId' | 'name' | 'key' | 'crmId' | 'crmName' | 'syncedFromCrm' | 'system' | 'created' | 'modified' | 'optInEmails'>
  & { roles: Array<(
    { __typename?: 'CompanyRole' }
    & Pick<CompanyRole, 'name' | 'label'>
  )>, domains: Array<(
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ), passwordPolicy?: Maybe<(
      { __typename?: 'PasswordPolicy' }
      & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
    )> }
  )>, instances: Array<(
    { __typename?: 'InstanceIdentifier' }
    & Pick<InstanceIdentifier, 'name' | 'enabled'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )>, sites: Array<(
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'key' | 'name'>
  )>, address?: Maybe<(
    { __typename?: 'CompanyAddress' }
    & Pick<CompanyAddress, 'address' | 'city' | 'zip' | 'country'>
  )>, contactInfo?: Maybe<(
    { __typename?: 'CompanyContactInfo' }
    & Pick<CompanyContactInfo, 'phone' | 'email'>
  )> }
);

export type LoginDomainFragmentFragment = (
  { __typename?: 'LoginDomain' }
  & Pick<LoginDomain, 'id' | 'code' | 'primary' | 'enabled' | 'enableTwoFactorAuthentication' | 'authenticationProvider' | 'metadata' | 'metadataUrl' | 'keys' | 'clientId' | 'clientSecret' | 'transitionTo' | 'transitionQuiet' | 'transitionDate' | 'usersCount'>
  & { company: (
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'key'>
  ), passwordPolicy?: Maybe<(
    { __typename?: 'PasswordPolicy' }
    & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinSpecials' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
  )> }
);

export type PasswordPolicyReferenceFragment = (
  { __typename?: 'PasswordPolicy' }
  & Pick<PasswordPolicy, 'passwordWarning' | 'passwordCheckSyntax' | 'passwordMaxAge' | 'passwordMinAge' | 'passwordInHistory' | 'passwordMaxRepeats' | 'passwordMinAlphas' | 'passwordMinDigits' | 'passwordMinSpecials' | 'passwordExp' | 'passwordMinLength' | 'passwordMinLowers' | 'passwordMinUppers' | 'passwordMinCategories' | 'passwordStorageScheme'>
);

export type PasswordPolicyContextReferenceFragment = (
  { __typename?: 'PasswordPolicyContext' }
  & Pick<PasswordPolicyContext, 'passwordStorageSchemeOptions'>
  & { passwordWarningReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMaxAgeReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinAgeReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordInHistoryReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMaxRepeatsReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinSpecialsReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinDigitsReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinAlphasReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinLengthReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinLowersReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinUppersReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ), passwordMinCategoriesReq: (
    { __typename?: 'IntRange' }
    & Pick<IntRange, 'lowerBoundIncluded' | 'upperBoundExcluded'>
  ) }
);

export type GetEventTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventTypesQuery = (
  { __typename?: 'Query' }
  & { eventTypes: Array<(
    { __typename?: 'EventType' }
    & Pick<EventType, 'name' | 'description' | 'level'>
  )> }
);

export type FindEventsQueryVariables = Exact<{
  eventSearch?: InputMaybe<EventSearch>;
  pagination?: InputMaybe<Pagination>;
}>;


export type FindEventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'code' | 'created' | 'domain' | 'actorDomain' | 'actorUsername' | 'targetDomain' | 'targetUsername' | 'companyName' | 'eventip' | 'message' | 'eventName' | 'flowId' | 'versionName'>
  )> }
);

export type CountEventsQueryVariables = Exact<{
  eventSearch?: InputMaybe<EventSearch>;
}>;


export type CountEventsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'eventCount'>
);

export type GetLayoutInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLayoutInfoQuery = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & { folders: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'key' | 'name'>
      & { folders: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'key' | 'name'>
        & { workspaces: Array<(
          { __typename?: 'Workspace' }
          & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
          & { sections: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
            & { sections: Array<(
              { __typename?: 'Workspace' }
              & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
              & { sections: Array<(
                { __typename?: 'Workspace' }
                & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
                & { sections: Array<(
                  { __typename?: 'Workspace' }
                  & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
                  & { navigationConfiguration?: Maybe<(
                    { __typename?: 'NavigationConfiguration' }
                    & Pick<NavigationConfiguration, 'openInNewTab'>
                  )> }
                )>, navigationConfiguration?: Maybe<(
                  { __typename?: 'NavigationConfiguration' }
                  & Pick<NavigationConfiguration, 'openInNewTab'>
                )> }
              )>, navigationConfiguration?: Maybe<(
                { __typename?: 'NavigationConfiguration' }
                & Pick<NavigationConfiguration, 'openInNewTab'>
              )> }
            )>, navigationConfiguration?: Maybe<(
              { __typename?: 'NavigationConfiguration' }
              & Pick<NavigationConfiguration, 'openInNewTab'>
            )> }
          )>, navigationConfiguration?: Maybe<(
            { __typename?: 'NavigationConfiguration' }
            & Pick<NavigationConfiguration, 'openInNewTab'>
          )> }
        )> }
      )>, workspaces: Array<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
        & { navigationConfiguration?: Maybe<(
          { __typename?: 'NavigationConfiguration' }
          & Pick<NavigationConfiguration, 'openInNewTab'>
        )> }
      )> }
    )> }
  ), whoAmI: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'username' | 'timezone'>
    & { domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code' | 'authenticationProvider'>
      & { authenticationProviderAttributes: (
        { __typename?: 'AuthenticationProviderAttributes' }
        & Pick<AuthenticationProviderAttributes, 'externallyGoverned'>
      ) }
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    ) }
  ), activeSession: (
    { __typename?: 'SessionInfo' }
    & Pick<SessionInfo, 'sessionCreation' | 'idleTimeoutInSeconds' | 'hardTimeoutInSeconds'>
  ) }
);

export type GetActiveSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveSessionQuery = (
  { __typename?: 'Query' }
  & { activeSession: (
    { __typename?: 'SessionInfo' }
    & Pick<SessionInfo, 'sessionCreation' | 'idleTimeoutInSeconds' | 'hardTimeoutInSeconds'>
  ) }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'permissions'>
);

export type SectionFieldsFragment = (
  { __typename?: 'Workspace' }
  & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
  & { navigationConfiguration?: Maybe<(
    { __typename?: 'NavigationConfiguration' }
    & Pick<NavigationConfiguration, 'openInNewTab'>
  )> }
);

export type RootFolderFragment = (
  { __typename?: 'Folder' }
  & Pick<Folder, 'key' | 'name'>
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'key' | 'name'>
    & { workspaces: Array<(
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
      & { sections: Array<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
        & { sections: Array<(
          { __typename?: 'Workspace' }
          & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
          & { sections: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
            & { sections: Array<(
              { __typename?: 'Workspace' }
              & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
              & { navigationConfiguration?: Maybe<(
                { __typename?: 'NavigationConfiguration' }
                & Pick<NavigationConfiguration, 'openInNewTab'>
              )> }
            )>, navigationConfiguration?: Maybe<(
              { __typename?: 'NavigationConfiguration' }
              & Pick<NavigationConfiguration, 'openInNewTab'>
            )> }
          )>, navigationConfiguration?: Maybe<(
            { __typename?: 'NavigationConfiguration' }
            & Pick<NavigationConfiguration, 'openInNewTab'>
          )> }
        )>, navigationConfiguration?: Maybe<(
          { __typename?: 'NavigationConfiguration' }
          & Pick<NavigationConfiguration, 'openInNewTab'>
        )> }
      )>, navigationConfiguration?: Maybe<(
        { __typename?: 'NavigationConfiguration' }
        & Pick<NavigationConfiguration, 'openInNewTab'>
      )> }
    )> }
  )>, workspaces: Array<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
    & { navigationConfiguration?: Maybe<(
      { __typename?: 'NavigationConfiguration' }
      & Pick<NavigationConfiguration, 'openInNewTab'>
    )> }
  )> }
);

export type SubFolderFragment = (
  { __typename?: 'Folder' }
  & Pick<Folder, 'key' | 'name'>
  & { workspaces: Array<(
    { __typename?: 'Workspace' }
    & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
    & { sections: Array<(
      { __typename?: 'Workspace' }
      & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
      & { sections: Array<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
        & { sections: Array<(
          { __typename?: 'Workspace' }
          & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
          & { sections: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'key' | 'name' | 'link' | 'icon' | 'component' | 'permissions'>
            & { navigationConfiguration?: Maybe<(
              { __typename?: 'NavigationConfiguration' }
              & Pick<NavigationConfiguration, 'openInNewTab'>
            )> }
          )>, navigationConfiguration?: Maybe<(
            { __typename?: 'NavigationConfiguration' }
            & Pick<NavigationConfiguration, 'openInNewTab'>
          )> }
        )>, navigationConfiguration?: Maybe<(
          { __typename?: 'NavigationConfiguration' }
          & Pick<NavigationConfiguration, 'openInNewTab'>
        )> }
      )>, navigationConfiguration?: Maybe<(
        { __typename?: 'NavigationConfiguration' }
        & Pick<NavigationConfiguration, 'openInNewTab'>
      )> }
    )>, navigationConfiguration?: Maybe<(
      { __typename?: 'NavigationConfiguration' }
      & Pick<NavigationConfiguration, 'openInNewTab'>
    )> }
  )> }
);

export type GroupInfoFragment = (
  { __typename?: 'AdminGroup' }
  & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
  ), managerGroup?: Maybe<(
    { __typename?: 'AdminGroupPreview' }
    & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
    & { company: (
      { __typename?: 'CompanyPreview' }
      & Pick<CompanyPreview, 'key' | 'system'>
    ) }
  )> }
);

export type GroupPageFragment = (
  { __typename?: 'AdminGroupPage' }
  & Pick<AdminGroupPage, 'depth' | 'size' | 'after' | 'first'>
  & { page: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  )> }
);

export type GroupIdentifierFragment = (
  { __typename?: 'AdminGroup' }
  & Pick<AdminGroup, 'id' | 'name' | 'key' | 'level'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
  ) }
);

export type AdminGroupIdentifierFragment = (
  { __typename?: 'AdminGroupIdentifier' }
  & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
  & { company: (
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
  ) }
);

export type FindGroupsWithPermissionQueryVariables = Exact<{
  appKey: Scalars['String'];
  permissionKey: Scalars['String'];
  accessType: AccessType;
  searchRoles: Scalars['Boolean'];
}>;


export type FindGroupsWithPermissionQuery = (
  { __typename?: 'Query' }
  & { findGroupsWithPermission: Array<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'key' | 'name'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type FindGroupsWithRoleQueryVariables = Exact<{
  application: Scalars['String'];
  roleKey: Scalars['String'];
  accessType: AccessType;
  searchRoles: Scalars['Boolean'];
}>;


export type FindGroupsWithRoleQuery = (
  { __typename?: 'Query' }
  & { findGroupsWithRole: Array<(
    { __typename?: 'AdminGroupPreview' }
    & Pick<AdminGroupPreview, 'key' | 'name'>
    & { company: (
      { __typename?: 'CompanyPreview' }
      & Pick<CompanyPreview, 'key' | 'name'>
    ) }
  )> }
);

export type FindPermissionMigrationGroupsQueryVariables = Exact<{
  fromApplication: Scalars['String'];
  fromPermission: Scalars['String'];
  fromIsRole: Scalars['Boolean'];
  toApplication: Scalars['String'];
  toPermission: Scalars['String'];
  toIsRole: Scalars['Boolean'];
}>;


export type FindPermissionMigrationGroupsQuery = (
  { __typename?: 'Query' }
  & { findPermissionMigrationGroups: Array<(
    { __typename?: 'PermissionMigrationGroup' }
    & Pick<PermissionMigrationGroup, 'permToBeAddedAccess' | 'permToBeAddedManagement' | 'permCanBeAdded' | 'sourcePermAccess' | 'sourcePermManagement'>
    & { group: (
      { __typename?: 'AdminGroupIdentifier' }
      & Pick<AdminGroupIdentifier, 'key' | 'name' | 'manager'>
    ) }
  )> }
);

export type FindGroupSitesQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
}>;


export type FindGroupSitesQuery = (
  { __typename?: 'Query' }
  & { findGroupSites: Array<(
    { __typename?: 'GroupSite' }
    & Pick<GroupSite, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'grantedBy' | 'managedBy'>
    & { identifier: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName' | 'status'>
    ) }
  )> }
);

export type FindAdminPermissionSitesAccessQueryVariables = Exact<{
  permission: PermissionInput;
  admin: FindAdmin;
}>;


export type FindAdminPermissionSitesAccessQuery = (
  { __typename?: 'Query' }
  & { findAdminPermissionSitesAccess: Array<(
    { __typename?: 'GroupSite' }
    & Pick<GroupSite, 'access' | 'delegateManagement'>
    & { identifier: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    ) }
  )> }
);

export type FindGroupCompaniesQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
}>;


export type FindGroupCompaniesQuery = (
  { __typename?: 'Query' }
  & { findGroupCompanies: Array<(
    { __typename?: 'GroupCompany' }
    & Pick<GroupCompany, 'access' | 'delegateManagement' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'grantedBy' | 'managedBy'>
    & { identifier: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ), roles: Array<Maybe<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label' | 'description'>
    )>> }
  )> }
);

export type FindGroupInstancesQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
}>;


export type FindGroupInstancesQuery = (
  { __typename?: 'Query' }
  & { findGroupInstances: Array<(
    { __typename?: 'GroupInstance' }
    & Pick<GroupInstance, 'access' | 'delegateManagement' | 'grantedBy' | 'managedBy'>
    & { identifier: (
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'name' | 'key' | 'group'>
      ), company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name' | 'key'>
      ) }
    ) }
  )> }
);

export type GetGroupMembersQueryVariables = Exact<{
  companyKey: Scalars['String'];
  groupKey: Scalars['String'];
}>;


export type GetGroupMembersQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key' | 'name'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'system'>
    ), members: Array<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'id' | 'created'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ), source?: Maybe<(
        { __typename?: 'AmsGroup' }
        & Pick<AmsGroup, 'name' | 'dn'>
      )>, addedBy?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )> }
    )> }
  )> }
);

export type FindGroupCandidatesQueryVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  includeAlreadyAdded?: InputMaybe<Scalars['Boolean']>;
}>;


export type FindGroupCandidatesQuery = (
  { __typename?: 'Query' }
  & { findMemberCandidates: Array<(
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key' | 'system'>
    ), domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ) }
  )> }
);

export type AddGroupMembersMutationVariables = Exact<{
  addedMembers: Array<GroupMemberInput> | GroupMemberInput;
  comment: Scalars['String'];
}>;


export type AddGroupMembersMutation = (
  { __typename?: 'Mutation' }
  & { addGroupMembers: Array<(
    { __typename?: 'AddGroupMemberResponse' }
    & Pick<AddGroupMemberResponse, 'message' | 'added'>
    & { member?: Maybe<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'created'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ), addedBy?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )> }
    )>, group?: Maybe<(
      { __typename?: 'AdminGroup' }
      & Pick<AdminGroup, 'id' | 'name' | 'key' | 'level'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key' | 'name' | 'system'>
      ) }
    )> }
  )> }
);

export type AddMembersFromDomainAndUsernameMutationVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
  usernames: Array<Scalars['String']> | Scalars['String'];
  comment: Scalars['String'];
}>;


export type AddMembersFromDomainAndUsernameMutation = (
  { __typename?: 'Mutation' }
  & { addMembersFromDomainAndUsername: Array<(
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key' | 'system'>
    ), domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ) }
  )> }
);

export type RemoveGroupMembersMutationVariables = Exact<{
  removedMembers: Array<GroupMemberInput> | GroupMemberInput;
  comment: Scalars['String'];
}>;


export type RemoveGroupMembersMutation = (
  { __typename?: 'Mutation' }
  & { removeGroupMembers: Array<(
    { __typename?: 'GroupMember' }
    & Pick<GroupMember, 'created'>
    & { account: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    ) }
  )> }
);

export type GetBoundAmsGroupsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  groupKey: Scalars['String'];
}>;


export type GetBoundAmsGroupsQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key'>
    ), amsBindings: Array<(
      { __typename?: 'AmsGroup' }
      & Pick<AmsGroup, 'name' | 'dn' | 'members'>
      & { childGroups?: Maybe<Array<(
        { __typename?: 'AmsGroup' }
        & Pick<AmsGroup, 'name' | 'dn' | 'members'>
      )>> }
    )> }
  )> }
);

export type FindAmsGroupsQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type FindAmsGroupsQuery = (
  { __typename?: 'Query' }
  & { findAmsGroups: Array<(
    { __typename?: 'AmsGroup' }
    & Pick<AmsGroup, 'name' | 'dn' | 'members'>
    & { childGroups?: Maybe<Array<(
      { __typename?: 'AmsGroup' }
      & Pick<AmsGroup, 'name' | 'dn' | 'members'>
    )>> }
  )> }
);

export type UpdateAmsGroupBindingsMutationVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  addedAmsBindings: Array<AmsGroupInput> | AmsGroupInput;
  removedAmsBindings: Array<AmsGroupInput> | AmsGroupInput;
  comment: Scalars['String'];
}>;


export type UpdateAmsGroupBindingsMutation = (
  { __typename?: 'Mutation' }
  & { updateAmsGroupBindings?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'key'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key'>
    ), amsBindings: Array<(
      { __typename?: 'AmsGroup' }
      & Pick<AmsGroup, 'name' | 'dn' | 'members'>
    )> }
  )> }
);

export type FindGroupReportsQueryVariables = Exact<{
  groupKey: Scalars['String'];
}>;


export type FindGroupReportsQuery = (
  { __typename?: 'Query' }
  & { findGroupReports: Array<(
    { __typename?: 'GroupReport' }
    & Pick<GroupReport, 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedBy' | 'managedBy'>
    & { identifier: (
      { __typename?: 'ReportIdentifier' }
      & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    ) }
  )> }
);

export type FindGroupAccessedAndManagedSitesQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type FindGroupAccessedAndManagedSitesQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { grantedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'key' | 'name'>
    )>, managedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'key' | 'name'>
    )> }
  )> }
);

export type FindGroupRolesAndPermissionsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  groupKey: Scalars['String'];
}>;


export type FindGroupRolesAndPermissionsQuery = (
  { __typename?: 'Query' }
  & { findGroupRolesAndPermissions: Array<(
    { __typename?: 'GroupPermission' }
    & Pick<GroupPermission, 'name' | 'key' | 'roleDerived' | 'access' | 'accessSystem' | 'delegateManagement' | 'delegateManagementSystem' | 'system' | 'dedicated' | 'description' | 'deprecated' | 'tags' | 'managementByRoleNames' | 'grantedByRoleNames' | 'taEnabled'>
    & { application: (
      { __typename?: 'ApplicationIdentifier' }
      & Pick<ApplicationIdentifier, 'name' | 'key' | 'owner'>
    ) }
  )> }
);

export type FindRoleSubRolesAndPermissionsQueryVariables = Exact<{
  companyKey: Scalars['String'];
  groupKey: Scalars['String'];
  role: Scalars['String'];
  application: Scalars['String'];
}>;


export type FindRoleSubRolesAndPermissionsQuery = (
  { __typename?: 'Query' }
  & { findRoleSubRolesAndPermissions: Array<(
    { __typename?: 'GroupPermissionTreeItem' }
    & Pick<GroupPermissionTreeItem, 'fullKey' | 'key' | 'name' | 'role' | 'system' | 'taEnabled' | 'dedicated' | 'children'>
    & { application: (
      { __typename?: 'ApplicationIdentifier' }
      & Pick<ApplicationIdentifier, 'key'>
    ) }
  )> }
);

export type FindGroupItemChangesQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  items: Array<GroupItemInput> | GroupItemInput;
}>;


export type FindGroupItemChangesQuery = (
  { __typename?: 'Query' }
  & { findGroupItemChanges: (
    { __typename?: 'GroupItemsChangeSet' }
    & { changedSites: Array<(
      { __typename?: 'GroupSite' }
      & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
      ) }
    )>, changedCompanies: Array<(
      { __typename?: 'GroupCompany' }
      & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
      ) }
    )>, changedInstances: Array<(
      { __typename?: 'GroupInstance' }
      & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'InstanceIdentifier' }
        & Pick<InstanceIdentifier, 'name'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ), company: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedReports: Array<(
      { __typename?: 'GroupReport' }
      & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'ReportIdentifier' }
        & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedPermissions: Array<(
      { __typename?: 'GroupPermission' }
      & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
      & { application: (
        { __typename?: 'ApplicationIdentifier' }
        & Pick<ApplicationIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type UpdateGroupItemsMutationVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  items: Array<GroupItemInput> | GroupItemInput;
  comment: Scalars['String'];
}>;


export type UpdateGroupItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupItems: (
    { __typename?: 'GroupItemsChangeSet' }
    & Pick<GroupItemsChangeSet, 'id'>
    & { changedSites: Array<(
      { __typename?: 'GroupSite' }
      & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
      ) }
    )>, changedCompanies: Array<(
      { __typename?: 'GroupCompany' }
      & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
      ) }
    )>, changedInstances: Array<(
      { __typename?: 'GroupInstance' }
      & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'InstanceIdentifier' }
        & Pick<InstanceIdentifier, 'name'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ), company: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedReports: Array<(
      { __typename?: 'GroupReport' }
      & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'ReportIdentifier' }
        & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedPermissions: Array<(
      { __typename?: 'GroupPermission' }
      & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
      & { application: (
        { __typename?: 'ApplicationIdentifier' }
        & Pick<ApplicationIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type FindChangesetChangesQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  changesetId: Scalars['String'];
}>;


export type FindChangesetChangesQuery = (
  { __typename?: 'Query' }
  & { findChangesetChanges: Array<(
    { __typename?: 'ChangesetGroupPairs' }
    & { key: (
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'name' | 'key'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'name' | 'key'>
      ) }
    ), value: (
      { __typename?: 'GroupItemsChangeSet' }
      & Pick<GroupItemsChangeSet, 'id' | 'createdAt' | 'comment'>
      & { actor?: Maybe<(
        { __typename?: 'Actor' }
        & { admin?: Maybe<(
          { __typename?: 'AdminAccount' }
          & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'name' | 'key' | 'system'>
          ), domain: (
            { __typename?: 'LoginDomain' }
            & Pick<LoginDomain, 'code'>
          ) }
        )>, technicalAccount?: Maybe<(
          { __typename?: 'TechnicalAccount' }
          & Pick<TechnicalAccount, 'username'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'key' | 'name' | 'system'>
          ), nullableDomain?: Maybe<(
            { __typename?: 'LoginDomain' }
            & Pick<LoginDomain, 'code'>
          )> }
        )> }
      )>, changedSites: Array<(
        { __typename?: 'GroupSite' }
        & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
        & { identifier: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
        ) }
      )>, changedCompanies: Array<(
        { __typename?: 'GroupCompany' }
        & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
        & { identifier: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
        ) }
      )>, changedInstances: Array<(
        { __typename?: 'GroupInstance' }
        & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
        & { identifier: (
          { __typename?: 'InstanceIdentifier' }
          & Pick<InstanceIdentifier, 'name'>
          & { site: (
            { __typename?: 'SiteIdentifier' }
            & Pick<SiteIdentifier, 'key' | 'name'>
          ), company: (
            { __typename?: 'CompanyIdentifier' }
            & Pick<CompanyIdentifier, 'key' | 'name'>
          ) }
        ) }
      )>, changedReports: Array<(
        { __typename?: 'GroupReport' }
        & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
        & { identifier: (
          { __typename?: 'ReportIdentifier' }
          & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
          & { site: (
            { __typename?: 'SiteIdentifier' }
            & Pick<SiteIdentifier, 'key' | 'name'>
          ) }
        ) }
      )>, changedPermissions: Array<(
        { __typename?: 'GroupPermission' }
        & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
        & { application: (
          { __typename?: 'ApplicationIdentifier' }
          & Pick<ApplicationIdentifier, 'key' | 'name'>
        ) }
      )>, changedMembers: Array<(
        { __typename?: 'GroupMember' }
        & Pick<GroupMember, 'newValue'>
        & { account: (
          { __typename?: 'AdminAccount' }
          & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'name' | 'key' | 'system'>
          ), domain: (
            { __typename?: 'LoginDomain' }
            & Pick<LoginDomain, 'code'>
          ) }
        ), source?: Maybe<(
          { __typename?: 'AmsGroup' }
          & Pick<AmsGroup, 'name'>
        )> }
      )> }
    ) }
  )> }
);

export type RevertChangesetsMutationVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  changesetId: Scalars['String'];
  comment: Scalars['String'];
}>;


export type RevertChangesetsMutation = (
  { __typename?: 'Mutation' }
  & { revertChangesets: (
    { __typename?: 'GroupItemsChangeSet' }
    & Pick<GroupItemsChangeSet, 'id' | 'createdAt' | 'comment'>
    & { actor?: Maybe<(
      { __typename?: 'Actor' }
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )>, technicalAccount?: Maybe<(
        { __typename?: 'TechnicalAccount' }
        & Pick<TechnicalAccount, 'username'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'key' | 'name' | 'system'>
        ), nullableDomain?: Maybe<(
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        )> }
      )> }
    )>, changedSites: Array<(
      { __typename?: 'GroupSite' }
      & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
      ) }
    )>, changedCompanies: Array<(
      { __typename?: 'GroupCompany' }
      & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
      ) }
    )>, changedInstances: Array<(
      { __typename?: 'GroupInstance' }
      & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'InstanceIdentifier' }
        & Pick<InstanceIdentifier, 'name'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ), company: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedReports: Array<(
      { __typename?: 'GroupReport' }
      & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'ReportIdentifier' }
        & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedPermissions: Array<(
      { __typename?: 'GroupPermission' }
      & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
      & { application: (
        { __typename?: 'ApplicationIdentifier' }
        & Pick<ApplicationIdentifier, 'key' | 'name'>
      ) }
    )>, changedMembers: Array<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'newValue'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ), source?: Maybe<(
        { __typename?: 'AmsGroup' }
        & Pick<AmsGroup, 'name'>
      )> }
    )> }
  ) }
);

export type FullGroupPermissionTreeItemFragment = (
  { __typename?: 'GroupPermissionTreeItem' }
  & Pick<GroupPermissionTreeItem, 'fullKey' | 'key' | 'name' | 'role' | 'system' | 'taEnabled' | 'dedicated' | 'children'>
  & { application: (
    { __typename?: 'ApplicationIdentifier' }
    & Pick<ApplicationIdentifier, 'key'>
  ) }
);

export type GroupPermissionChangeFragment = (
  { __typename?: 'GroupPermission' }
  & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
  & { application: (
    { __typename?: 'ApplicationIdentifier' }
    & Pick<ApplicationIdentifier, 'key' | 'name'>
  ) }
);

export type FullGroupSiteFragment = (
  { __typename?: 'GroupSite' }
  & Pick<GroupSite, 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
  & { identifier: (
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
  ) }
);

export type FullGroupCompanyFragment = (
  { __typename?: 'GroupCompany' }
  & Pick<GroupCompany, 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
  & { identifier: (
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
  ) }
);

export type FullGroupReportFragment = (
  { __typename?: 'GroupReport' }
  & Pick<GroupReport, 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedBy' | 'managedBy'>
  & { identifier: (
    { __typename?: 'ReportIdentifier' }
    & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  ) }
);

export type FullGroupInstanceFragment = (
  { __typename?: 'GroupInstance' }
  & Pick<GroupInstance, 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
  & { identifier: (
    { __typename?: 'InstanceIdentifier' }
    & Pick<InstanceIdentifier, 'name'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ), company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name'>
    ) }
  ) }
);

export type FullGroupItemChangesetFragment = (
  { __typename?: 'GroupItemsChangeSet' }
  & Pick<GroupItemsChangeSet, 'id' | 'createdAt' | 'comment'>
  & { actor?: Maybe<(
    { __typename?: 'Actor' }
    & { admin?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )>, technicalAccount?: Maybe<(
      { __typename?: 'TechnicalAccount' }
      & Pick<TechnicalAccount, 'username'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key' | 'name' | 'system'>
      ), nullableDomain?: Maybe<(
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      )> }
    )> }
  )>, changedSites: Array<(
    { __typename?: 'GroupSite' }
    & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
    & { identifier: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
    ) }
  )>, changedCompanies: Array<(
    { __typename?: 'GroupCompany' }
    & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
    & { identifier: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )>, changedInstances: Array<(
    { __typename?: 'GroupInstance' }
    & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
    & { identifier: (
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ), company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name'>
      ) }
    ) }
  )>, changedReports: Array<(
    { __typename?: 'GroupReport' }
    & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
    & { identifier: (
      { __typename?: 'ReportIdentifier' }
      & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    ) }
  )>, changedPermissions: Array<(
    { __typename?: 'GroupPermission' }
    & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
    & { application: (
      { __typename?: 'ApplicationIdentifier' }
      & Pick<ApplicationIdentifier, 'key' | 'name'>
    ) }
  )>, changedMembers: Array<(
    { __typename?: 'GroupMember' }
    & Pick<GroupMember, 'newValue'>
    & { account: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    ), source?: Maybe<(
      { __typename?: 'AmsGroup' }
      & Pick<AmsGroup, 'name'>
    )> }
  )> }
);

export type FindOneGroupItemChangeSetsQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  groupItemInput?: InputMaybe<GroupItemInput>;
}>;


export type FindOneGroupItemChangeSetsQuery = (
  { __typename?: 'Query' }
  & { findOneGroupItemChangeSets: Array<(
    { __typename?: 'GroupItemsChangeSet' }
    & Pick<GroupItemsChangeSet, 'id' | 'createdAt' | 'comment'>
    & { actor?: Maybe<(
      { __typename?: 'Actor' }
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )>, technicalAccount?: Maybe<(
        { __typename?: 'TechnicalAccount' }
        & Pick<TechnicalAccount, 'username'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'key' | 'name' | 'system'>
        ), nullableDomain?: Maybe<(
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        )> }
      )> }
    )>, changedSites: Array<(
      { __typename?: 'GroupSite' }
      & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
      ) }
    )>, changedCompanies: Array<(
      { __typename?: 'GroupCompany' }
      & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
      ) }
    )>, changedInstances: Array<(
      { __typename?: 'GroupInstance' }
      & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'InstanceIdentifier' }
        & Pick<InstanceIdentifier, 'name'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ), company: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedReports: Array<(
      { __typename?: 'GroupReport' }
      & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'ReportIdentifier' }
        & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedPermissions: Array<(
      { __typename?: 'GroupPermission' }
      & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
      & { application: (
        { __typename?: 'ApplicationIdentifier' }
        & Pick<ApplicationIdentifier, 'key' | 'name'>
      ) }
    )>, changedMembers: Array<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'newValue'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ), source?: Maybe<(
        { __typename?: 'AmsGroup' }
        & Pick<AmsGroup, 'name'>
      )> }
    )> }
  )> }
);

export type FindGroupItemsChangeSetsQueryVariables = Exact<{
  company: Scalars['String'];
  group: Scalars['String'];
  items: Array<GroupItemType> | GroupItemType;
  searchMembers: Scalars['Boolean'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type FindGroupItemsChangeSetsQuery = (
  { __typename?: 'Query' }
  & { findGroupItemsChangeSets: Array<(
    { __typename?: 'GroupItemsChangeSet' }
    & Pick<GroupItemsChangeSet, 'rollbackEnabled' | 'id' | 'createdAt' | 'comment'>
    & { headGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'name' | 'key'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'name' | 'key'>
      ) }
    )>, actor?: Maybe<(
      { __typename?: 'Actor' }
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )>, technicalAccount?: Maybe<(
        { __typename?: 'TechnicalAccount' }
        & Pick<TechnicalAccount, 'username'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'key' | 'name' | 'system'>
        ), nullableDomain?: Maybe<(
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        )> }
      )> }
    )>, changedSites: Array<(
      { __typename?: 'GroupSite' }
      & Pick<GroupSite, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name' | 'group' | 'infraAccountKey' | 'infraAccountName'>
      ) }
    )>, changedCompanies: Array<(
      { __typename?: 'GroupCompany' }
      & Pick<GroupCompany, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'accessAllInstances' | 'delegateManagementAllInstances' | 'accessAllInstancesChanged' | 'delegateManagementAllInstancesChanged'>
      & { identifier: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
      ) }
    )>, changedInstances: Array<(
      { __typename?: 'GroupInstance' }
      & Pick<GroupInstance, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'InstanceIdentifier' }
        & Pick<InstanceIdentifier, 'name'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ), company: (
          { __typename?: 'CompanyIdentifier' }
          & Pick<CompanyIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedReports: Array<(
      { __typename?: 'GroupReport' }
      & Pick<GroupReport, 'grantedBy' | 'managedBy' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged'>
      & { identifier: (
        { __typename?: 'ReportIdentifier' }
        & Pick<ReportIdentifier, 'name' | 'key' | 'reportGroup' | 'frozen'>
        & { site: (
          { __typename?: 'SiteIdentifier' }
          & Pick<SiteIdentifier, 'key' | 'name'>
        ) }
      ) }
    )>, changedPermissions: Array<(
      { __typename?: 'GroupPermission' }
      & Pick<GroupPermission, 'key' | 'roleDerived' | 'access' | 'accessChanged' | 'delegateManagement' | 'delegateManagementChanged' | 'grantedByRoleNames' | 'managementByRoleNames' | 'deprecated'>
      & { application: (
        { __typename?: 'ApplicationIdentifier' }
        & Pick<ApplicationIdentifier, 'key' | 'name'>
      ) }
    )>, changedMembers: Array<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'newValue'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ), source?: Maybe<(
        { __typename?: 'AmsGroup' }
        & Pick<AmsGroup, 'name'>
      )> }
    )> }
  )> }
);

export type FindGroupQueryVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
}>;


export type FindGroupQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'name' | 'created' | 'enabled' | 'childGroupCount' | 'description'>
    & { createdBy?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )>, managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'name' | 'key'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'name' | 'key'>
      ), members: Array<(
        { __typename?: 'GroupMember' }
        & Pick<GroupMember, 'id'>
        & { account: (
          { __typename?: 'AdminAccount' }
          & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'name' | 'key' | 'system'>
          ), domain: (
            { __typename?: 'LoginDomain' }
            & Pick<LoginDomain, 'code'>
          ) }
        ) }
      )> }
    )>, audits: Array<(
      { __typename?: 'AdminGroupAudit' }
      & Pick<AdminGroupAudit, 'created'>
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )> }
    )>, comments: Array<(
      { __typename?: 'GroupComment' }
      & Pick<GroupComment, 'created' | 'comment'>
      & { admin?: Maybe<(
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'name' | 'key' | 'system'>
        ), domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      )> }
    )> }
  )> }
);

export type AddGroupAuditEntryMutationVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
}>;


export type AddGroupAuditEntryMutation = (
  { __typename?: 'Mutation' }
  & { addGroupAuditEntry: Array<(
    { __typename?: 'AdminGroupAudit' }
    & Pick<AdminGroupAudit, 'created'>
    & { admin?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )> }
  )> }
);

export type AddGroupCommentMutationVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
  comment: Scalars['String'];
}>;


export type AddGroupCommentMutation = (
  { __typename?: 'Mutation' }
  & { addGroupComment: Array<(
    { __typename?: 'GroupComment' }
    & Pick<GroupComment, 'created' | 'comment'>
    & { admin?: Maybe<(
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'id' | 'firstName' | 'lastName' | 'email' | 'username' | 'enabled'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name' | 'key' | 'system'>
      ), domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    )> }
  )> }
);

export type UpdateGroupDescriptionMutationVariables = Exact<{
  group: Scalars['String'];
  company: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateGroupDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupDescription: (
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'key' | 'description'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key'>
    ) }
  ) }
);

export type AddGroupMutationVariables = Exact<{
  group: GroupInput;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { addGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  )> }
);

export type GrantedGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GrantedGroupsQuery = (
  { __typename?: 'Query' }
  & { grantedGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  )> }
);

export type ManagedGroupsQueryVariables = Exact<{
  level?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  instanceName?: InputMaybe<Scalars['String']>;
}>;


export type ManagedGroupsQuery = (
  { __typename?: 'Query' }
  & { managedGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  )> }
);

export type FindChildGroupsQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type FindChildGroupsQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { childGroups: Array<(
      { __typename?: 'AdminGroup' }
      & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key' | 'name' | 'system'>
      ), managerGroup?: Maybe<(
        { __typename?: 'AdminGroupPreview' }
        & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
        & { company: (
          { __typename?: 'CompanyPreview' }
          & Pick<CompanyPreview, 'key' | 'system'>
        ) }
      )> }
    )> }
  )> }
);

export type FindGroupMembersQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type FindGroupMembersQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { members: Array<(
      { __typename?: 'GroupMember' }
      & Pick<GroupMember, 'domainUsername'>
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'enabled'>
      ) }
    )> }
  )> }
);

export type LoadGroupRelationsQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type LoadGroupRelationsQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { members: Array<(
      { __typename?: 'GroupMember' }
      & { account: (
        { __typename?: 'AdminAccount' }
        & Pick<AdminAccount, 'username' | 'enabled' | 'name'>
        & { domain: (
          { __typename?: 'LoginDomain' }
          & Pick<LoginDomain, 'code'>
        ) }
      ) }
    )>, grantedInstances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, grantedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )>, managedInstances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, managedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )> }
  )> }
);

export type LoadGroupSitesAndInstancesQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type LoadGroupSitesAndInstancesQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { grantedInstances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, grantedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )>, managedInstances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, managedSites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )> }
  )> }
);

export type LoadGroupQueryVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type LoadGroupQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  )> }
);

export type FindSiteGroupsQueryVariables = Exact<{
  siteName: Scalars['String'];
  groupName: Scalars['String'];
}>;


export type FindSiteGroupsQuery = (
  { __typename?: 'Query' }
  & { querySiteGroup?: Maybe<(
    { __typename?: 'SiteAdminGroup' }
    & Pick<SiteAdminGroup, 'name'>
    & { permissions: Array<(
      { __typename?: 'AdminScriptPermission' }
      & Pick<AdminScriptPermission, 'key' | 'applicationKey'>
    )> }
  )> }
);

export type ToggleGroupMutationVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
  enable: Scalars['Boolean'];
}>;


export type ToggleGroupMutation = (
  { __typename?: 'Mutation' }
  & { enableGroup: (
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  ) }
);

export type RemoveGroupMutationVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type RemoveGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeGroup?: Maybe<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type RenameGroupMutationVariables = Exact<{
  companyKey: Scalars['String'];
  groupKey: Scalars['String'];
  newName: Scalars['String'];
}>;


export type RenameGroupMutation = (
  { __typename?: 'Mutation' }
  & { renameGroup: (
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  ) }
);

export type AddGroupManagementMutationVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type AddGroupManagementMutation = (
  { __typename?: 'Mutation' }
  & { addManagementPermission?: Maybe<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type RemoveGroupManagementMutationVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type RemoveGroupManagementMutation = (
  { __typename?: 'Mutation' }
  & { removeManagementPermission?: Maybe<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'id' | 'name' | 'key'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type AssignManagerMutationVariables = Exact<{
  groupKey: Scalars['String'];
  companyKey: Scalars['String'];
  managerGroupKey: Scalars['String'];
  managerGroupCompanyKey: Scalars['String'];
}>;


export type AssignManagerMutation = (
  { __typename?: 'Mutation' }
  & { assignManager?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'key' | 'name'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key'>
      ) }
    )> }
  )> }
);

export type ValidateCloneGroupQueryVariables = Exact<{
  sourceGroupKey: Scalars['String'];
  sourceCompanyKey: Scalars['String'];
  group: GroupInput;
  params: GroupCloneParams;
}>;


export type ValidateCloneGroupQuery = (
  { __typename?: 'Query' }
  & { validateCloneGroup?: Maybe<(
    { __typename?: 'GroupCloneValidation' }
    & Pick<GroupCloneValidation, 'valid' | 'message'>
    & { siteDiff?: Maybe<Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
      & { instances: Array<(
        { __typename?: 'Instance' }
        & Pick<Instance, 'name'>
      )> }
    )>>, permissionDiff?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'key'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'name' | 'key'>
      )>, roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'key'>
      )> }
    )>> }
  )> }
);

export type ValidateImportGroupQueryVariables = Exact<{
  group: GroupInput;
  siteName: Scalars['String'];
  groupName: Scalars['String'];
}>;


export type ValidateImportGroupQuery = (
  { __typename?: 'Query' }
  & { validateImportGroup?: Maybe<(
    { __typename?: 'GroupCloneValidation' }
    & Pick<GroupCloneValidation, 'valid' | 'message'>
    & { siteDiff?: Maybe<Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
      & { instances: Array<(
        { __typename?: 'Instance' }
        & Pick<Instance, 'name'>
      )> }
    )>>, permissionDiff?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'key'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'name' | 'key'>
      )>, roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'key'>
      )> }
    )>> }
  )> }
);

export type FindManagedGroupsQueryVariables = Exact<{
  search?: InputMaybe<AdminGroupSearch>;
  pagination?: InputMaybe<Pagination>;
}>;


export type FindManagedGroupsQuery = (
  { __typename?: 'Query' }
  & { findManagedGroups: (
    { __typename?: 'AdminGroupPage' }
    & Pick<AdminGroupPage, 'depth' | 'size' | 'after' | 'first'>
    & { page: Array<(
      { __typename?: 'AdminGroup' }
      & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key' | 'name' | 'system'>
      ), managerGroup?: Maybe<(
        { __typename?: 'AdminGroupPreview' }
        & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
        & { company: (
          { __typename?: 'CompanyPreview' }
          & Pick<CompanyPreview, 'key' | 'system'>
        ) }
      )> }
    )> }
  ) }
);

export type FindManagerGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagerGroupsQuery = (
  { __typename?: 'Query' }
  & { managerGroups: Array<(
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'level'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type FindGroupManagedCompaniesQueryVariables = Exact<{
  groupid: Scalars['Int'];
}>;


export type FindGroupManagedCompaniesQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'AdminGroup' }
    & { managedCompanies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'key' | 'system'>
    )> }
  )> }
);

export type GrantSitesMutationVariables = Exact<{
  groupId: Scalars['String'];
  companyId: Scalars['String'];
  sites: Array<Scalars['String']> | Scalars['String'];
  management: Scalars['Boolean'];
}>;


export type GrantSitesMutation = (
  { __typename?: 'Mutation' }
  & { grantSites?: Maybe<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'key'>
  )> }
);

export type GrantInstancesMutationVariables = Exact<{
  groupId: Scalars['String'];
  companyId: Scalars['String'];
  siteName: Scalars['String'];
  instances: Array<Scalars['String']> | Scalars['String'];
  management: Scalars['Boolean'];
}>;


export type GrantInstancesMutation = (
  { __typename?: 'Mutation' }
  & { grantInstances?: Maybe<(
    { __typename?: 'AdminGroupIdentifier' }
    & Pick<AdminGroupIdentifier, 'key'>
  )> }
);

export type CloneGroupMutationVariables = Exact<{
  sourceGroupKey: Scalars['String'];
  sourceCompanyKey: Scalars['String'];
  group: GroupInput;
  params: GroupCloneParams;
}>;


export type CloneGroupMutation = (
  { __typename?: 'Mutation' }
  & { cloneGroup: (
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  ) }
);

export type ImportGroupMutationVariables = Exact<{
  group: GroupInput;
  siteName: Scalars['String'];
  groupName: Scalars['String'];
}>;


export type ImportGroupMutation = (
  { __typename?: 'Mutation' }
  & { importGroup: (
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'id' | 'name' | 'key' | 'enabled' | 'systemManaged' | 'grantAllInstances' | 'grantAllSites' | 'grantAllReports' | 'grantAllCompanies' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'managersGroup' | 'memberCount' | 'companyCount' | 'siteCount' | 'instanceCount' | 'childGroupCount' | 'personal' | 'level' | 'description'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name' | 'system'>
    ), managerGroup?: Maybe<(
      { __typename?: 'AdminGroupPreview' }
      & Pick<AdminGroupPreview, 'id' | 'key' | 'name' | 'manageAllSites' | 'manageAllInstances' | 'manageAllReports' | 'manageAllCompanies' | 'grantAllReports'>
      & { company: (
        { __typename?: 'CompanyPreview' }
        & Pick<CompanyPreview, 'key' | 'system'>
      ) }
    )> }
  ) }
);

export type TokenInfoQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type TokenInfoQuery = (
  { __typename?: 'Query' }
  & { validateToken: (
    { __typename?: 'JwtValidatingResult' }
    & Pick<JwtValidatingResult, 'tokenSizeKiB' | 'isValid' | 'message' | 'created' | 'expires'>
    & { loginSessionInfo?: Maybe<(
      { __typename?: 'LoginSessionInfo' }
      & Pick<LoginSessionInfo, 'open' | 'loginDate' | 'lastActivityDate'>
    )> }
  ) }
);

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { settings: Array<(
    { __typename?: 'Setting' }
    & Pick<Setting, 'name' | 'value'>
  )> }
);

export type FindSiteNotificationTemplatesQueryVariables = Exact<{
  siteKey: Scalars['String'];
}>;


export type FindSiteNotificationTemplatesQuery = (
  { __typename?: 'Query' }
  & { findSiteNotificationTemplates: Array<(
    { __typename?: 'NotificationTemplate' }
    & Pick<NotificationTemplate, 'id' | 'name' | 'enabled' | 'site' | 'trigger' | 'templateTitle' | 'template' | 'attachmentName' | 'tag'>
    & { recipients: Array<(
      { __typename?: 'NotificationRecipient' }
      & Pick<NotificationRecipient, 'id' | 'channel' | 'recipient'>
    )> }
  )> }
);

export type AddNotificationTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  trigger: NotificationTrigger;
  enabled?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['String']>;
  recipients: Array<NewNotificationRecipient> | NewNotificationRecipient;
  template: Scalars['String'];
  templateTitle: Scalars['String'];
  attachmentName?: InputMaybe<Scalars['String']>;
  attachment?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
}>;


export type AddNotificationTemplateMutation = (
  { __typename?: 'Mutation' }
  & { addNotificationTemplate: (
    { __typename?: 'NotificationTemplate' }
    & Pick<NotificationTemplate, 'id' | 'name' | 'enabled' | 'site' | 'trigger' | 'templateTitle' | 'template' | 'attachmentName' | 'tag'>
    & { recipients: Array<(
      { __typename?: 'NotificationRecipient' }
      & Pick<NotificationRecipient, 'id' | 'channel' | 'recipient'>
    )> }
  ) }
);

export type ModifyNotificationTemplateMutationVariables = Exact<{
  id: Scalars['Long'];
  name?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<NotificationTrigger>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Array<InputMaybe<NewNotificationRecipient>> | InputMaybe<NewNotificationRecipient>>;
  templateTitle?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  attachmentName?: InputMaybe<Scalars['String']>;
  attachment?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
}>;


export type ModifyNotificationTemplateMutation = (
  { __typename?: 'Mutation' }
  & { modifyNotificationTemplate: (
    { __typename?: 'NotificationTemplate' }
    & Pick<NotificationTemplate, 'id' | 'name' | 'enabled' | 'site' | 'trigger' | 'templateTitle' | 'template' | 'attachmentName' | 'tag'>
    & { recipients: Array<(
      { __typename?: 'NotificationRecipient' }
      & Pick<NotificationRecipient, 'id' | 'channel' | 'recipient'>
    )> }
  ) }
);

export type DeleteNotificationTemplateMutationVariables = Exact<{
  templateId: Scalars['Long'];
}>;


export type DeleteNotificationTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotificationTemplate: (
    { __typename?: 'NotificationTemplate' }
    & Pick<NotificationTemplate, 'id' | 'name' | 'enabled' | 'site' | 'trigger' | 'templateTitle' | 'template' | 'attachmentName' | 'tag'>
    & { recipients: Array<(
      { __typename?: 'NotificationRecipient' }
      & Pick<NotificationRecipient, 'id' | 'channel' | 'recipient'>
    )> }
  ) }
);

export type SiteAccessReasonMutationVariables = Exact<{
  service: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  override?: InputMaybe<Scalars['Boolean']>;
}>;


export type SiteAccessReasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'siteAccessReason'>
);

export type SendTestNotificationMutationVariables = Exact<{
  templateId: Scalars['Long'];
}>;


export type SendTestNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendTestNotification'>
);

export type SendTaggedNotificationMutationVariables = Exact<{
  tag: Scalars['String'];
  site: Scalars['String'];
  templateVariables?: InputMaybe<Array<InputMaybe<KeyValuePair>> | InputMaybe<KeyValuePair>>;
}>;


export type SendTaggedNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendTaggedNotifications'>
);

export type SiteNotificationTemplateFragment = (
  { __typename?: 'NotificationTemplate' }
  & Pick<NotificationTemplate, 'id' | 'name' | 'enabled' | 'site' | 'trigger' | 'templateTitle' | 'template' | 'attachmentName' | 'tag'>
  & { recipients: Array<(
    { __typename?: 'NotificationRecipient' }
    & Pick<NotificationRecipient, 'id' | 'channel' | 'recipient'>
  )> }
);

export type FindServiceApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindServiceApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications: Array<(
    { __typename?: 'Application' }
    & Pick<Application, 'key' | 'name' | 'group' | 'statusUrl' | 'loginUrl' | 'authenticationProtocol' | 'contextPath' | 'multiSiteSupport' | 'serveAssignedInstances'>
  )> }
);

export type FindSiteServicesQueryVariables = Exact<{
  siteName?: InputMaybe<Scalars['String']>;
}>;


export type FindSiteServicesQuery = (
  { __typename?: 'Query' }
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  )> }
);

export type FindOauthConfigurationQueryVariables = Exact<{
  clientId: Scalars['String'];
  application: Scalars['String'];
}>;


export type FindOauthConfigurationQuery = (
  { __typename?: 'Query' }
  & { findOauthConfiguration?: Maybe<(
    { __typename?: 'OauthConfiguration' }
    & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
  )> }
);

export type FindOauthConfigurationsQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;


export type FindOauthConfigurationsQuery = (
  { __typename?: 'Query' }
  & { findOauthConfigurations: Array<(
    { __typename?: 'OauthConfiguration' }
    & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
  )> }
);

export type AddSiteServiceMutationVariables = Exact<{
  site: Scalars['String'];
  service: Scalars['String'];
  application: Scalars['String'];
  name: Scalars['String'];
}>;


export type AddSiteServiceMutation = (
  { __typename?: 'Mutation' }
  & { addSiteService: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type UpdateServiceMutationVariables = Exact<{
  service: Scalars['String'];
  data: ServiceInput;
}>;


export type UpdateServiceMutation = (
  { __typename?: 'Mutation' }
  & { updateService: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type RemoveSiteServiceMutationVariables = Exact<{
  site: Scalars['String'];
  service: Scalars['String'];
}>;


export type RemoveSiteServiceMutation = (
  { __typename?: 'Mutation' }
  & { removeSiteService: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type AddOauthConfigurationMutationVariables = Exact<{
  clientId: Scalars['String'];
  service: Scalars['String'];
  application: Scalars['String'];
  secret?: InputMaybe<Scalars['String']>;
}>;


export type AddOauthConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addOauthConfiguration: (
    { __typename?: 'OauthConfiguration' }
    & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
  ) }
);

export type GenerateOauthSecretMutationVariables = Exact<{
  clientId: Scalars['String'];
  application: Scalars['String'];
}>;


export type GenerateOauthSecretMutation = (
  { __typename?: 'Mutation' }
  & { generateOauthSecret: (
    { __typename?: 'OauthConfiguration' }
    & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
  ) }
);

export type RemoveOauthConfigurationMutationVariables = Exact<{
  clientId: Scalars['String'];
  service: Scalars['String'];
  application: Scalars['String'];
}>;


export type RemoveOauthConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOauthConfiguration'>
);

export type RevokeOauthSecretMutationVariables = Exact<{
  clientId: Scalars['String'];
  application: Scalars['String'];
}>;


export type RevokeOauthSecretMutation = (
  { __typename?: 'Mutation' }
  & { revokeOauthSecret: (
    { __typename?: 'OauthConfiguration' }
    & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
  ) }
);

export type AssignServiceInstanceMutationVariables = Exact<{
  service: Scalars['String'];
  site: Scalars['String'];
  instance: Scalars['String'];
}>;


export type AssignServiceInstanceMutation = (
  { __typename?: 'Mutation' }
  & { assignServiceInstance: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type RemoveServiceInstanceMutationVariables = Exact<{
  service: Scalars['String'];
  site: Scalars['String'];
  instance: Scalars['String'];
}>;


export type RemoveServiceInstanceMutation = (
  { __typename?: 'Mutation' }
  & { removeServiceInstance: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    ), sites: Array<(
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'name' | 'key'>
    )>, instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name'>
      & { site: (
        { __typename?: 'SiteIdentifier' }
        & Pick<SiteIdentifier, 'key' | 'name'>
      ) }
    )> }
  ) }
);

export type FullServiceFragment = (
  { __typename?: 'Service' }
  & Pick<Service, 'name' | 'service' | 'promoted' | 'enabled' | 'welcomeMessage' | 'statusMessage'>
  & { application: (
    { __typename?: 'Application' }
    & Pick<Application, 'key'>
  ), sites: Array<(
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'name' | 'key'>
  )>, instances: Array<(
    { __typename?: 'InstanceIdentifier' }
    & Pick<InstanceIdentifier, 'name'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type ServiceApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'key' | 'name' | 'group' | 'statusUrl' | 'loginUrl' | 'authenticationProtocol' | 'contextPath' | 'multiSiteSupport' | 'serveAssignedInstances'>
);

export type OauthConfigurationDataFragment = (
  { __typename?: 'OauthConfiguration' }
  & Pick<OauthConfiguration, 'clientId' | 'clientSecret' | 'application' | 'services'>
);

export type CountLoginSessionsQueryVariables = Exact<{
  search: SessionSearch;
  site?: InputMaybe<Scalars['String']>;
}>;


export type CountLoginSessionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countLoginSessions'>
);

export type FindLoginSessionsQueryVariables = Exact<{
  search: SessionSearch;
  site?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  pagination?: InputMaybe<Pagination>;
}>;


export type FindLoginSessionsQuery = (
  { __typename?: 'Query' }
  & { loginSessions?: Maybe<Array<(
    { __typename?: 'LoginSession' }
    & Pick<LoginSession, 'id' | 'type' | 'username' | 'companyKey' | 'domain' | 'loginIp' | 'loginDate' | 'lastActivityDate' | 'open'>
  )>> }
);

export type FindManagedInstancesForCertTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedInstancesForCertTemplateQuery = (
  { __typename?: 'Query' }
  & { managedInstances: Array<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name' | 'walletCode'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name'>
    ), site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type FindSiteManagedCompaniesAndInstancesQueryVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FindSiteManagedCompaniesAndInstancesQuery = (
  { __typename?: 'Query' }
  & { findSiteManagedCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
    & { instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
    )>, roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label' | 'description'>
    )> }
  )> }
);

export type FindSiteManagedInstancesQueryVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FindSiteManagedInstancesQuery = (
  { __typename?: 'Query' }
  & { findSiteManagedInstances: Array<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name' | 'enabled' | 'regulation'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type ManagedInstancesQueryVariables = Exact<{
  sites?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ManagedInstancesQuery = (
  { __typename?: 'Query' }
  & { managedInstances: Array<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type AddSiteCompanyMutationVariables = Exact<{
  site: Scalars['String'];
  company: Scalars['String'];
}>;


export type AddSiteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { addSiteCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
    & { instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
    )>, roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label' | 'description'>
    )> }
  ) }
);

export type RemoveSiteCompanyMutationVariables = Exact<{
  site: Scalars['String'];
  company: Scalars['String'];
}>;


export type RemoveSiteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { removeSiteCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
    & { instances: Array<(
      { __typename?: 'InstanceIdentifier' }
      & Pick<InstanceIdentifier, 'name' | 'enabled'>
    )>, roles: Array<(
      { __typename?: 'CompanyRole' }
      & Pick<CompanyRole, 'name' | 'label' | 'description'>
    )> }
  ) }
);

export type AddSiteInstanceMutationVariables = Exact<{
  site: Scalars['String'];
  company: Scalars['String'];
  instance: Scalars['String'];
  enabled: Scalars['Boolean'];
  regulation?: InputMaybe<Regulation>;
}>;


export type AddSiteInstanceMutation = (
  { __typename?: 'Mutation' }
  & { addSiteInstance?: Maybe<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name' | 'enabled' | 'regulation'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type UpdateSiteInstanceMutationVariables = Exact<{
  site: Scalars['String'];
  instance: Scalars['String'];
  enabled: Scalars['Boolean'];
  regulation?: InputMaybe<Regulation>;
}>;


export type UpdateSiteInstanceMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteInstance?: Maybe<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name' | 'enabled' | 'regulation'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
    ) }
  )> }
);

export type RemoveSiteInstanceMutationVariables = Exact<{
  site: Scalars['String'];
  instance: Scalars['String'];
}>;


export type RemoveSiteInstanceMutation = (
  { __typename?: 'Mutation' }
  & { removeSiteInstance?: Maybe<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name'>
    & { site: (
      { __typename?: 'SiteIdentifier' }
      & Pick<SiteIdentifier, 'key' | 'name'>
    ) }
  )> }
);

export type SiteCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'key' | 'name' | 'system'>
  & { instances: Array<(
    { __typename?: 'InstanceIdentifier' }
    & Pick<InstanceIdentifier, 'name' | 'enabled'>
  )>, roles: Array<(
    { __typename?: 'CompanyRole' }
    & Pick<CompanyRole, 'name' | 'label' | 'description'>
  )> }
);

export type SiteInstanceFragment = (
  { __typename?: 'Instance' }
  & Pick<Instance, 'name' | 'enabled' | 'regulation'>
  & { company: (
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'key' | 'name' | 'system'>
  ) }
);

export type InstanceForCertTemplateFragment = (
  { __typename?: 'Instance' }
  & Pick<Instance, 'name' | 'walletCode'>
  & { company: (
    { __typename?: 'CompanyIdentifier' }
    & Pick<CompanyIdentifier, 'key' | 'name'>
  ), site: (
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'key' | 'name'>
  ) }
);

export type InstanceReferenceFragment = (
  { __typename?: 'Instance' }
  & Pick<Instance, 'name'>
  & { site: (
    { __typename?: 'SiteIdentifier' }
    & Pick<SiteIdentifier, 'key' | 'name'>
  ) }
);

export type FullSiteFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'occupationalLicenseRequired' | 'loginNotificationsEnabled' | 'supportGroup' | 'version' | 'timezone'>
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'service' | 'name'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'key'>
    ) }
  )>, instances: Array<(
    { __typename?: 'Instance' }
    & Pick<Instance, 'name' | 'regulation' | 'enabled'>
    & { company: (
      { __typename?: 'CompanyIdentifier' }
      & Pick<CompanyIdentifier, 'name' | 'key'>
    ) }
  )>, companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key'>
  )> }
);

export type SiteReferenceFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'key' | 'name' | 'group'>
);

export type GrantedSiteReferenceFragment = (
  { __typename?: 'GrantedSite' }
  & Pick<GrantedSite, 'key' | 'name'>
);

export type PermitProfileFragment = (
  { __typename?: 'SitePermitProfile' }
  & Pick<SitePermitProfile, 'name' | 'type' | 'description' | 'id' | 'notifyDaysBefore'>
  & { managerGroup: (
    { __typename?: 'AdminGroup' }
    & Pick<AdminGroup, 'name' | 'key'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'key'>
    ) }
  ), sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key'>
  )> }
);

export type FullPermitFragment = (
  { __typename?: 'SitePermit' }
  & Pick<SitePermit, 'id' | 'permitNumber' | 'validFrom' | 'validTo' | 'revoked'>
  & { profile: (
    { __typename?: 'SitePermitProfile' }
    & Pick<SitePermitProfile, 'id' | 'name'>
    & { sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name'>
    )> }
  ), admin: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'username'>
    & { domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ) }
  ) }
);

export type PermitWithoutProfileFragment = (
  { __typename?: 'SitePermit' }
  & Pick<SitePermit, 'id' | 'permitNumber' | 'validFrom' | 'validTo' | 'revoked'>
  & { admin: (
    { __typename?: 'AdminAccount' }
    & Pick<AdminAccount, 'username' | 'firstName' | 'lastName'>
    & { domain: (
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    ) }
  ) }
);

export type SiteForWebApiTemplateFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'name' | 'key'>
  & { networks: Array<(
    { __typename?: 'Network' }
    & Pick<Network, 'name' | 'code' | 'externalid'>
    & { type: (
      { __typename?: 'NetworkType' }
      & Pick<NetworkType, 'name'>
    ) }
  )> }
);

export type DashboardSiteFragment = (
  { __typename?: 'GrantedSite' }
  & Pick<GrantedSite, 'key' | 'name' | 'manualNomConfigured'>
  & { instances: Array<(
    { __typename?: 'GrantedInstance' }
    & Pick<GrantedInstance, 'name' | 'regulation'>
  )>, services: Array<(
    { __typename?: 'GrantedService' }
    & Pick<GrantedService, 'id' | 'name' | 'service'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'key' | 'name' | 'contextPath' | 'loginUrl' | 'deprecated'>
    )> }
  )> }
);

export type CertificateDeploymentDataFragment = (
  { __typename?: 'SiteCertificateDeployment' }
  & Pick<SiteCertificateDeployment, 'profile' | 'application'>
  & { certificate?: Maybe<(
    { __typename?: 'Certificate' }
    & Pick<Certificate, 'id' | 'dc' | 'name' | 'status' | 'validFrom' | 'validTo'>
  )> }
);

export type FindManagedSitesReferenceQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedSitesReferenceQuery = (
  { __typename?: 'Query' }
  & { managedSites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'key' | 'name' | 'group'>
  )> }
);

export type FindGrantedSitesLightQueryVariables = Exact<{ [key: string]: never; }>;


export type FindGrantedSitesLightQuery = (
  { __typename?: 'Query' }
  & { grantedSites: Array<(
    { __typename?: 'GrantedSite' }
    & Pick<GrantedSite, 'key' | 'name'>
  )> }
);

export type FindManagedSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManagedSitesQuery = (
  { __typename?: 'Query' }
  & { managedSites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'occupationalLicenseRequired' | 'loginNotificationsEnabled' | 'supportGroup' | 'version' | 'timezone'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service' | 'name'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'key'>
      ) }
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name' | 'regulation' | 'enabled'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name' | 'key'>
      ) }
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    )> }
  )> }
);

export type SearchManagedSitesQueryVariables = Exact<{
  filter?: InputMaybe<SitesFilter>;
  sort?: InputMaybe<Array<Sort> | Sort>;
  pagination?: InputMaybe<Pagination>;
}>;


export type SearchManagedSitesQuery = (
  { __typename?: 'Query' }
  & { managedSites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'occupationalLicenseRequired' | 'loginNotificationsEnabled' | 'supportGroup' | 'version' | 'timezone'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service' | 'name'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'key'>
      ) }
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name' | 'regulation' | 'enabled'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name' | 'key'>
      ) }
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    )> }
  )> }
);

export type CountManagedSitesQueryVariables = Exact<{
  filter?: InputMaybe<SitesFilter>;
}>;


export type CountManagedSitesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countManagedSites'>
);

export type FindSiteQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FindSiteQuery = (
  { __typename?: 'Query' }
  & { findSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'loginNotificationsEnabled' | 'supportGroup' | 'occupationalLicenseRequired'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service' | 'name'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'key'>
      ) }
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name' | 'regulation' | 'enabled'>
      & { company: (
        { __typename?: 'CompanyIdentifier' }
        & Pick<CompanyIdentifier, 'name' | 'key'>
      ) }
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    )> }
  ) }
);

export type AddSiteMutationVariables = Exact<{
  name: Scalars['String'];
  site: SiteInput;
}>;


export type AddSiteMutation = (
  { __typename?: 'Mutation' }
  & { addSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'loginNotificationsEnabled' | 'occupationalLicenseRequired'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name'>
      ) }
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    )> }
  ) }
);

export type UpdateSiteMutationVariables = Exact<{
  name: Scalars['String'];
  site: SiteInput;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key' | 'accountName' | 'status' | 'type' | 'group' | 'loginNotificationsEnabled' | 'occupationalLicenseRequired'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name'>
      ) }
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    )> }
  ) }
);

export type RemoveSiteMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type RemoveSiteMutation = (
  { __typename?: 'Mutation' }
  & { removeSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'key'>
  ) }
);

export type FindSitesForWebApiTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type FindSitesForWebApiTemplateQuery = (
  { __typename?: 'Query' }
  & { managedSites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key'>
    & { networks: Array<(
      { __typename?: 'Network' }
      & Pick<Network, 'name' | 'code' | 'externalid'>
      & { type: (
        { __typename?: 'NetworkType' }
        & Pick<NetworkType, 'name'>
      ) }
    )> }
  )> }
);

export type FindPermitProfilesQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']>;
}>;


export type FindPermitProfilesQuery = (
  { __typename?: 'Query' }
  & { findPermitProfiles: Array<(
    { __typename?: 'SitePermitProfile' }
    & Pick<SitePermitProfile, 'name' | 'type' | 'description' | 'id' | 'notifyDaysBefore'>
    & { managerGroup: (
      { __typename?: 'AdminGroup' }
      & Pick<AdminGroup, 'name' | 'key'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key'>
      ) }
    ), sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name' | 'key'>
    )> }
  )> }
);

export type FindSitePermitsByProfileQueryVariables = Exact<{
  profileId: Scalars['Long'];
}>;


export type FindSitePermitsByProfileQuery = (
  { __typename?: 'Query' }
  & { findSitePermits: Array<(
    { __typename?: 'SitePermit' }
    & Pick<SitePermit, 'id' | 'permitNumber' | 'validFrom' | 'validTo' | 'revoked'>
    & { admin: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'username' | 'firstName' | 'lastName'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ), company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      ) }
    ) }
  )> }
);

export type FindSitePermitsByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type FindSitePermitsByUsernameQuery = (
  { __typename?: 'Query' }
  & { findSitePermits: Array<(
    { __typename?: 'SitePermit' }
    & Pick<SitePermit, 'id' | 'permitNumber' | 'validFrom' | 'validTo' | 'revoked'>
    & { profile: (
      { __typename?: 'SitePermitProfile' }
      & Pick<SitePermitProfile, 'id' | 'name'>
      & { sites: Array<(
        { __typename?: 'Site' }
        & Pick<Site, 'name'>
      )> }
    ), admin: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'username'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ) }
    ) }
  )> }
);

export type FindNavigatorServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindNavigatorServicesQuery = (
  { __typename?: 'Query' }
  & { navigatorServices: Array<(
    { __typename?: 'GrantedSite' }
    & Pick<GrantedSite, 'key' | 'name' | 'manualNomConfigured'>
    & { instances: Array<(
      { __typename?: 'GrantedInstance' }
      & Pick<GrantedInstance, 'name' | 'regulation'>
    )>, services: Array<(
      { __typename?: 'GrantedService' }
      & Pick<GrantedService, 'id' | 'name' | 'service'>
      & { application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key' | 'name' | 'contextPath' | 'loginUrl' | 'deprecated'>
      )> }
    )> }
  )> }
);

export type FindSiteCertsMassExpirationDateQueryVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FindSiteCertsMassExpirationDateQuery = (
  { __typename?: 'Query' }
  & { findSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'key' | 'certsMassExpiration'>
  ) }
);

export type FindSiteCertificateDeploymentsQueryVariables = Exact<{
  site: Scalars['String'];
}>;


export type FindSiteCertificateDeploymentsQuery = (
  { __typename?: 'Query' }
  & { findSiteCertificateDeployments: Array<(
    { __typename?: 'SiteCertificateDeployment' }
    & Pick<SiteCertificateDeployment, 'profile' | 'application'>
    & { certificate?: Maybe<(
      { __typename?: 'Certificate' }
      & Pick<Certificate, 'id' | 'dc' | 'name' | 'status' | 'validFrom' | 'validTo'>
    )> }
  )> }
);

export type FindSiteGroupNamesQueryVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FindSiteGroupNamesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findSiteGroupNames'>
);

export type FindSitesAndNetworksForWebApiQueryVariables = Exact<{ [key: string]: never; }>;


export type FindSitesAndNetworksForWebApiQuery = (
  { __typename?: 'Query' }
  & { managedSites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'name' | 'key'>
    & { networks: Array<(
      { __typename?: 'Network' }
      & Pick<Network, 'name' | 'code' | 'externalid'>
      & { type: (
        { __typename?: 'NetworkType' }
        & Pick<NetworkType, 'name'>
      ) }
    )> }
  )> }
);

export type FindSiteReportsQueryVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FindSiteReportsQuery = (
  { __typename?: 'Query' }
  & { siteReports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'key' | 'reportGroup' | 'frozen'>
  )> }
);

export type AddSitePermitProfileMutationVariables = Exact<{
  name: Scalars['String'];
  type: SitePermitType;
  description?: InputMaybe<Scalars['String']>;
  managerGroup: Scalars['String'];
  notifyDaysBefore: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddSitePermitProfileMutation = (
  { __typename?: 'Mutation' }
  & { addSitePermitProfile: (
    { __typename?: 'SitePermitProfile' }
    & Pick<SitePermitProfile, 'name' | 'type' | 'description' | 'id' | 'notifyDaysBefore'>
    & { managerGroup: (
      { __typename?: 'AdminGroup' }
      & Pick<AdminGroup, 'name' | 'key'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'key'>
      ) }
    ), sites: Array<(
      { __typename?: 'Site' }
      & Pick<Site, 'name' | 'key'>
    )> }
  ) }
);

export type BindPermitProfileToSiteMutationVariables = Exact<{
  site: Scalars['String'];
  profileId: Scalars['Long'];
}>;


export type BindPermitProfileToSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bindSitePermitProfile'>
);

export type UnbindPermitProfileFromSiteMutationVariables = Exact<{
  site: Scalars['String'];
  profileId: Scalars['Long'];
}>;


export type UnbindPermitProfileFromSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unbindSitePermitProfile'>
);

export type AddSitePermitMutationVariables = Exact<{
  profileId: Scalars['Long'];
  permitNumber: Scalars['String'];
  username: Scalars['String'];
  notifyAdminsAboutExpiration: Scalars['Boolean'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
}>;


export type AddSitePermitMutation = (
  { __typename?: 'Mutation' }
  & { addSitePermit: (
    { __typename?: 'SitePermit' }
    & Pick<SitePermit, 'id' | 'permitNumber' | 'validFrom' | 'validTo' | 'revoked'>
    & { admin: (
      { __typename?: 'AdminAccount' }
      & Pick<AdminAccount, 'username' | 'firstName' | 'lastName'>
      & { domain: (
        { __typename?: 'LoginDomain' }
        & Pick<LoginDomain, 'code'>
      ), company: (
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      ) }
    ) }
  ) }
);

export type ToggleSitePermitRevocationMutationVariables = Exact<{
  permitId: Scalars['Long'];
  revoke: Scalars['Boolean'];
}>;


export type ToggleSitePermitRevocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleSitePermitRevocation'>
);

export type FetchSiteConfigMutationVariables = Exact<{
  siteName: Scalars['String'];
}>;


export type FetchSiteConfigMutation = (
  { __typename?: 'Mutation' }
  & { fetchSiteConfig: (
    { __typename?: 'SiteDifferences' }
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name'>
      ) }
    )>, companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    )>, instances: Array<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'name'>
    )>, operators?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'key' | 'name'>
    )>> }
  ) }
);

export type FetchSiteSecretsMutationVariables = Exact<{
  site: Scalars['String'];
}>;


export type FetchSiteSecretsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'fetchSiteSecrets'>
);

export type CreateSiteCertificateMutationVariables = Exact<{
  site: Scalars['String'];
  application: Scalars['String'];
  profile: Scalars['String'];
}>;


export type CreateSiteCertificateMutation = (
  { __typename?: 'Mutation' }
  & { createSiteCertificate: (
    { __typename?: 'SiteCertificateDeployment' }
    & Pick<SiteCertificateDeployment, 'profile' | 'application'>
    & { certificate?: Maybe<(
      { __typename?: 'Certificate' }
      & Pick<Certificate, 'id' | 'dc' | 'name' | 'status' | 'validFrom' | 'validTo'>
    )> }
  ) }
);

export type ReplaceSiteCertificateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReplaceSiteCertificateMutation = (
  { __typename?: 'Mutation' }
  & { replaceSiteCertificate: (
    { __typename?: 'SiteCertificateDeployment' }
    & Pick<SiteCertificateDeployment, 'profile' | 'application'>
    & { certificate?: Maybe<(
      { __typename?: 'Certificate' }
      & Pick<Certificate, 'id' | 'dc' | 'name' | 'status' | 'validFrom' | 'validTo'>
    )> }
  ) }
);

export type RevokeSiteCertificatesMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RevokeSiteCertificatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeSiteCertificates'>
);

export type InstallSiteCertificatesMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type InstallSiteCertificatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'installSiteCertificates'>
);

export type UninstallSiteCertificatesMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UninstallSiteCertificatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninstallSiteCertificates'>
);

export type SyncSiteReportsMutationVariables = Exact<{
  siteName: Scalars['String'];
  timestamp?: InputMaybe<Scalars['Long']>;
}>;


export type SyncSiteReportsMutation = (
  { __typename?: 'Mutation' }
  & { syncReports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'reportGroup'>
  )> }
);

export type CheckSitesVersionForCrmUpdateMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckSitesVersionForCrmUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkSitesVersionForCrmUpdate'>
);

export type TechnicalAccountInfoFragment = (
  { __typename?: 'TechnicalAccount' }
  & Pick<TechnicalAccount, 'username'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'key' | 'name' | 'system'>
  ), nullableDomain?: Maybe<(
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'code'>
  )> }
);

export type ApplicationWithTechnicalAccountInfoFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'name' | 'key'>
  & { technicalAccountConf?: Maybe<(
    { __typename?: 'TechnicalAccountConf' }
    & Pick<TechnicalAccountConf, 'vaultPath' | 'centralVaultPath' | 'generateConfigApiCredentials' | 'useClientCertificateAuthentication'>
  )> }
);

export type TechnicalAccountFragmentFragment = (
  { __typename?: 'TechnicalAccount' }
  & Pick<TechnicalAccount, 'created' | 'modified' | 'username' | 'email' | 'enabled' | 'oauthClientId'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'key'>
  ), domain?: Maybe<(
    { __typename?: 'LoginDomain' }
    & Pick<LoginDomain, 'code'>
  )>, application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'key'>
    & { technicalAccountConf?: Maybe<(
      { __typename?: 'TechnicalAccountConf' }
      & Pick<TechnicalAccountConf, 'vaultPath' | 'centralVaultPath' | 'generateConfigApiCredentials' | 'useClientCertificateAuthentication'>
    )> }
  )> }
);

export type GetTechnicalAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTechnicalAccountsQuery = (
  { __typename?: 'Query' }
  & { technicalAccounts: Array<(
    { __typename?: 'TechnicalAccount' }
    & Pick<TechnicalAccount, 'created' | 'modified' | 'username' | 'email' | 'enabled' | 'oauthClientId'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    ), domain?: Maybe<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'key'>
      & { technicalAccountConf?: Maybe<(
        { __typename?: 'TechnicalAccountConf' }
        & Pick<TechnicalAccountConf, 'vaultPath' | 'centralVaultPath' | 'generateConfigApiCredentials' | 'useClientCertificateAuthentication'>
      )> }
    )> }
  )> }
);

export type UpdateTechnicalAccountMutationVariables = Exact<{
  email: Scalars['String'];
  username: Scalars['String'];
  companyKey: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateTechnicalAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateTechnicalAccount?: Maybe<(
    { __typename?: 'TechnicalAccount' }
    & Pick<TechnicalAccount, 'created' | 'modified' | 'username' | 'email' | 'enabled' | 'oauthClientId'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'key'>
    ), domain?: Maybe<(
      { __typename?: 'LoginDomain' }
      & Pick<LoginDomain, 'code'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'key'>
      & { technicalAccountConf?: Maybe<(
        { __typename?: 'TechnicalAccountConf' }
        & Pick<TechnicalAccountConf, 'vaultPath' | 'centralVaultPath' | 'generateConfigApiCredentials' | 'useClientCertificateAuthentication'>
      )> }
    )> }
  )> }
);

export type IssueApiKeyMutationVariables = Exact<{
  username: Scalars['String'];
  companyKey: Scalars['String'];
  allowedOrigin?: InputMaybe<Scalars['String']>;
}>;


export type IssueApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'issueApikey'>
);

export type RevokeApiKeyMutationVariables = Exact<{
  username: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type RevokeApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeApikey'>
);

export type IssueOauthCredentialsMutationVariables = Exact<{
  username: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type IssueOauthCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { issueOauthCredentials?: Maybe<(
    { __typename?: 'OauthCredentials' }
    & Pick<OauthCredentials, 'clientId' | 'clientSecret'>
  )> }
);

export type RegenerateOauthSecretMutationVariables = Exact<{
  username: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type RegenerateOauthSecretMutation = (
  { __typename?: 'Mutation' }
  & { regenerateOauthSecret?: Maybe<(
    { __typename?: 'OauthCredentials' }
    & Pick<OauthCredentials, 'clientId' | 'clientSecret'>
  )> }
);

export type RevokeOauthCredentialsMutationVariables = Exact<{
  username: Scalars['String'];
  companyKey: Scalars['String'];
}>;


export type RevokeOauthCredentialsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeOauthCredentials'>
);

export type ServiceStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'serviceStatus'>
);

export type ReloadSitesMutationVariables = Exact<{ [key: string]: never; }>;


export type ReloadSitesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reloadSites'>
);

export type RunChecksMutationVariables = Exact<{ [key: string]: never; }>;


export type RunChecksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runChecks'>
);

export type SyncPowerBiAclMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncPowerBiAclMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncPowerBiAcl'>
);

export type PowerBiAclSyncStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type PowerBiAclSyncStatusQuery = (
  { __typename?: 'Query' }
  & { powerBiAclSyncStatus?: Maybe<(
    { __typename?: 'PowerBiAclSyncStatus' }
    & Pick<PowerBiAclSyncStatus, 'id' | 'status' | 'server' | 'created' | 'finished' | 'tasks' | 'chunks' | 'completedChunks'>
  )> }
);

export type PowerBiAclSyncsQueryVariables = Exact<{ [key: string]: never; }>;


export type PowerBiAclSyncsQuery = (
  { __typename?: 'Query' }
  & { powerBiAclSyncs: Array<(
    { __typename?: 'PowerBiAclSyncStatus' }
    & Pick<PowerBiAclSyncStatus, 'id' | 'status' | 'server' | 'created' | 'finished' | 'tasks' | 'chunks' | 'completedChunks'>
  )> }
);

export const FullAdminFragmentDoc = gql`
    fragment FullAdmin on AdminAccount {
  id
  username
  optInEmails
  firstName
  position
  phoneNumber
  hasUserImage
  lastName
  enabled
  email
  timezone
  mailingListOptIn
  commonAuthData {
    twoFactorActivated
    multiplesessionaction
    twoFactorEnabled
    infraLdapUsername
    authenticationMode
  }
  company {
    name
    key
    optInEmails
    system
    mailingList {
      name
    }
  }
  domain {
    code
    authenticationProvider
    enableTwoFactorAuthentication
  }
  customTags {
    key
    value
  }
}
    `;
export const GroupReferenceFragmentDoc = gql`
    fragment GroupReference on AdminGroupRef {
  name
  key
  company
  access
  management
}
    `;
export const FullWorkspaceFragmentDoc = gql`
    fragment FullWorkspace on Workspace {
  key
  name
  accessGroup
  permissions
  tags
  api
  link
  regulations
}
    `;
export const FullPermissionFragmentDoc = gql`
    fragment FullPermission on Permission {
  key
  name
  tags
  system
  reportPermission
  taEnabled
  dedicated
  regulations
  owner
  description
  deprecated
}
    `;
export const FullRoleFragmentDoc = gql`
    fragment FullRole on Role {
  key
  name
  description
  virtualRole
  shared
  permissions {
    key
  }
  deprecated
}
    `;
export const FullApplicationFragmentDoc = gql`
    fragment FullApplication on Application {
  key
  name
  owner
  group
  authenticationProtocol
  authorizationApi
  deprecated
  workspaces {
    ...FullWorkspace
  }
  permissions {
    ...FullPermission
  }
  referredPermissions {
    ...FullPermission
  }
  roles {
    ...FullRole
    roles {
      key
      roles {
        key
      }
    }
  }
}
    ${FullWorkspaceFragmentDoc}
${FullPermissionFragmentDoc}
${FullRoleFragmentDoc}`;
export const LightApplicationPermissionFragmentDoc = gql`
    fragment LightApplicationPermission on Application {
  name
  key
  permissions {
    name
    key
  }
}
    `;
export const AuditReferenceFragmentDoc = gql`
    fragment AuditReference on Audit {
  name
  key
  description
  tags
}
    `;
export const FullAuditConfigurationFragmentDoc = gql`
    fragment FullAuditConfiguration on AuditConfiguration {
  id
  description
  audit {
    key
    name
    description
    tags
    parameters {
      name
      key
      type
      required
      description
      multiple
      options {
        key
        value
      }
    }
  }
  cronExpression
  timezone
  parameters {
    key
    value
  }
  notificationType
  includeCsv
  created
  enabled
  creator {
    admin {
      username
      domain {
        code
      }
    }
  }
}
    `;
export const CertificateTemplateTableEntryFragmentDoc = gql`
    fragment CertificateTemplateTableEntry on CertificateTemplate {
  id
  name
  enabled
  dc
  sites {
    name
  }
  profile
  comments
  brandBindings
  company {
    name
    key
  }
  notificationGroups {
    name
    key
    company {
      name
    }
  }
}
    `;
export const ImportableCertificateEntryFragmentDoc = gql`
    fragment ImportableCertificateEntry on Certificate {
  id
  name
  dc
  profile
  sites
  status
  createdAt
  validFrom
  validTo
  snowTicket
  comment
}
    `;
export const CertificateTableEntryFragmentDoc = gql`
    fragment CertificateTableEntry on Certificate {
  id
  name
  status
  validFrom
  validTo
  createdAt
  notify
  chain
  certificate
  comment
  lastDownloaded
  lastDownloadedBy {
    firstName
    lastName
    username
    domain {
      code
    }
  }
}
    `;
export const CompanyWithLoginDomainsFragmentDoc = gql`
    fragment CompanyWithLoginDomains on Company {
  name
  key
  id
  system
  domains {
    code
    primary
    authenticationProvider
    enabled
  }
}
    `;
export const CompanyReferenceFragmentDoc = gql`
    fragment CompanyReference on Company {
  id
  name
  key
  system
}
    `;
export const CompanyReferenceLightFragmentDoc = gql`
    fragment CompanyReferenceLight on GrantedCompany {
  name
  key
}
    `;
export const CompanyWithSitesFragmentDoc = gql`
    fragment CompanyWithSites on Company {
  name
  key
  system
  sites {
    name
    key
  }
}
    `;
export const FullGrantedCompanyFragmentDoc = gql`
    fragment FullGrantedCompany on GrantedCompany {
  name
  key
  system
  instances {
    name
    regulation
  }
}
    `;
export const CompanyListEntryFragmentDoc = gql`
    fragment CompanyListEntry on Company {
  id
  externalId
  name
  crmId
  crmName
  key
  system
  roles {
    name
    label
  }
  created
  modified
  domains {
    id
    code
    primary
    enabled
    authenticationProvider
  }
  instances {
    name
    enabled
    site {
      key
      name
    }
  }
  sites {
    key
    name
  }
}
    `;
export const FullCompanyFragmentDoc = gql`
    fragment FullCompany on Company {
  id
  externalId
  name
  key
  crmId
  crmName
  syncedFromCrm
  system
  roles {
    name
    label
  }
  created
  modified
  optInEmails
  domains {
    id
    code
    primary
    enabled
    enableTwoFactorAuthentication
    authenticationProvider
    metadata
    metadataUrl
    keys
    clientId
    clientSecret
    transitionTo
    transitionQuiet
    transitionDate
    company {
      key
    }
    usersCount
    passwordPolicy {
      passwordWarning
      passwordCheckSyntax
      passwordMaxAge
      passwordMinAge
      passwordInHistory
      passwordMaxRepeats
      passwordMinSpecials
      passwordMinAlphas
      passwordMinDigits
      passwordMinSpecials
      passwordExp
      passwordMinLength
      passwordMinLowers
      passwordMinUppers
      passwordMinCategories
      passwordStorageScheme
    }
  }
  instances {
    name
    enabled
    site {
      key
      name
    }
  }
  sites {
    key
    name
  }
  address {
    address
    city
    zip
    country
  }
  contactInfo {
    phone
    email
  }
}
    `;
export const LoginDomainFragmentFragmentDoc = gql`
    fragment LoginDomainFragment on LoginDomain {
  id
  code
  primary
  enabled
  enableTwoFactorAuthentication
  authenticationProvider
  metadata
  metadataUrl
  keys
  clientId
  clientSecret
  transitionTo
  transitionQuiet
  transitionDate
  company {
    key
  }
  usersCount
  passwordPolicy {
    passwordWarning
    passwordCheckSyntax
    passwordMaxAge
    passwordMinAge
    passwordInHistory
    passwordMaxRepeats
    passwordMinSpecials
    passwordMinAlphas
    passwordMinDigits
    passwordMinSpecials
    passwordExp
    passwordMinLength
    passwordMinLowers
    passwordMinUppers
    passwordMinCategories
    passwordStorageScheme
  }
}
    `;
export const PasswordPolicyReferenceFragmentDoc = gql`
    fragment PasswordPolicyReference on PasswordPolicy {
  passwordWarning
  passwordCheckSyntax
  passwordMaxAge
  passwordMinAge
  passwordInHistory
  passwordMaxRepeats
  passwordMinAlphas
  passwordMinDigits
  passwordMinSpecials
  passwordExp
  passwordMinLength
  passwordMinLowers
  passwordMinUppers
  passwordMinCategories
  passwordStorageScheme
}
    `;
export const PasswordPolicyContextReferenceFragmentDoc = gql`
    fragment PasswordPolicyContextReference on PasswordPolicyContext {
  passwordWarningReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMaxAgeReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinAgeReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordInHistoryReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMaxRepeatsReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinSpecialsReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinDigitsReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinAlphasReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinLengthReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinLowersReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinUppersReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordMinCategoriesReq {
    lowerBoundIncluded
    upperBoundExcluded
  }
  passwordStorageSchemeOptions
}
    `;
export const SectionFieldsFragmentDoc = gql`
    fragment sectionFields on Workspace {
  key
  name
  link
  icon
  component
  permissions
  navigationConfiguration {
    openInNewTab
  }
}
    `;
export const SubFolderFragmentDoc = gql`
    fragment subFolder on Folder {
  key
  name
  workspaces {
    ...sectionFields
    sections {
      ...sectionFields
      sections {
        ...sectionFields
        sections {
          ...sectionFields
          sections {
            ...sectionFields
          }
        }
      }
    }
  }
}
    ${SectionFieldsFragmentDoc}`;
export const RootFolderFragmentDoc = gql`
    fragment rootFolder on Folder {
  key
  name
  folders {
    ...subFolder
  }
  workspaces {
    ...sectionFields
  }
}
    ${SubFolderFragmentDoc}
${SectionFieldsFragmentDoc}`;
export const GroupInfoFragmentDoc = gql`
    fragment GroupInfo on AdminGroup {
  id
  name
  key
  enabled
  systemManaged
  grantAllInstances
  grantAllSites
  grantAllReports
  grantAllCompanies
  manageAllSites
  manageAllInstances
  manageAllReports
  manageAllCompanies
  managersGroup
  memberCount
  companyCount
  siteCount
  instanceCount
  childGroupCount
  personal
  level
  company {
    key
    name
    system
  }
  managerGroup {
    id
    key
    name
    manageAllSites
    manageAllInstances
    manageAllReports
    manageAllCompanies
    grantAllReports
    company {
      key
      system
    }
  }
  description
}
    `;
export const GroupPageFragmentDoc = gql`
    fragment GroupPage on AdminGroupPage {
  depth
  size
  after
  first
  page {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const GroupIdentifierFragmentDoc = gql`
    fragment GroupIdentifier on AdminGroup {
  id
  name
  key
  level
  company {
    key
    name
    system
  }
}
    `;
export const AdminGroupIdentifierFragmentDoc = gql`
    fragment AdminGroupIdentifier on AdminGroupIdentifier {
  id
  name
  key
  company {
    key
    name
    system
  }
}
    `;
export const FullGroupPermissionTreeItemFragmentDoc = gql`
    fragment FullGroupPermissionTreeItem on GroupPermissionTreeItem {
  fullKey
  key
  name
  application {
    key
  }
  role
  system
  taEnabled
  dedicated
  children
}
    `;
export const AdminInfoFragmentDoc = gql`
    fragment AdminInfo on AdminAccount {
  id
  firstName
  lastName
  email
  username
  enabled
  company {
    name
    key
    system
  }
  domain {
    code
  }
}
    `;
export const TechnicalAccountInfoFragmentDoc = gql`
    fragment TechnicalAccountInfo on TechnicalAccount {
  company {
    key
    name
    system
  }
  nullableDomain: domain {
    code
  }
  username
}
    `;
export const FullGroupSiteFragmentDoc = gql`
    fragment FullGroupSite on GroupSite {
  identifier {
    key
    name
    group
    infraAccountKey
    infraAccountName
  }
  access
  accessChanged
  delegateManagement
  delegateManagementChanged
  accessAllInstances
  delegateManagementAllInstances
  accessAllInstancesChanged
  delegateManagementAllInstancesChanged
}
    `;
export const FullGroupCompanyFragmentDoc = gql`
    fragment FullGroupCompany on GroupCompany {
  identifier {
    key
    name
    system
  }
  access
  accessChanged
  delegateManagement
  delegateManagementChanged
  accessAllInstances
  delegateManagementAllInstances
  accessAllInstancesChanged
  delegateManagementAllInstancesChanged
}
    `;
export const FullGroupInstanceFragmentDoc = gql`
    fragment FullGroupInstance on GroupInstance {
  identifier {
    name
    site {
      key
      name
    }
    company {
      key
      name
    }
  }
  access
  accessChanged
  delegateManagement
  delegateManagementChanged
}
    `;
export const FullGroupReportFragmentDoc = gql`
    fragment FullGroupReport on GroupReport {
  identifier {
    name
    key
    reportGroup
    site {
      key
      name
    }
    frozen
  }
  access
  accessChanged
  delegateManagement
  delegateManagementChanged
  grantedBy
  managedBy
}
    `;
export const GroupPermissionChangeFragmentDoc = gql`
    fragment GroupPermissionChange on GroupPermission {
  key
  application {
    key
    name
  }
  roleDerived
  access
  accessChanged
  delegateManagement
  delegateManagementChanged
  grantedByRoleNames
  managementByRoleNames
  deprecated
}
    `;
export const FullGroupItemChangesetFragmentDoc = gql`
    fragment FullGroupItemChangeset on GroupItemsChangeSet {
  id
  createdAt
  comment
  actor {
    admin {
      ...AdminInfo
    }
    technicalAccount {
      ...TechnicalAccountInfo
    }
  }
  changedSites {
    ...FullGroupSite
    grantedBy
    managedBy
  }
  changedCompanies {
    ...FullGroupCompany
    grantedBy
    managedBy
  }
  changedInstances {
    ...FullGroupInstance
    grantedBy
    managedBy
  }
  changedReports {
    ...FullGroupReport
    grantedBy
    managedBy
  }
  changedPermissions {
    ...GroupPermissionChange
  }
  changedMembers {
    account {
      ...AdminInfo
    }
    source {
      name
    }
    newValue
  }
}
    ${AdminInfoFragmentDoc}
${TechnicalAccountInfoFragmentDoc}
${FullGroupSiteFragmentDoc}
${FullGroupCompanyFragmentDoc}
${FullGroupInstanceFragmentDoc}
${FullGroupReportFragmentDoc}
${GroupPermissionChangeFragmentDoc}`;
export const SiteNotificationTemplateFragmentDoc = gql`
    fragment SiteNotificationTemplate on NotificationTemplate {
  id
  name
  enabled
  site
  trigger
  templateTitle
  template
  attachmentName
  tag
  recipients {
    id
    channel
    recipient
  }
}
    `;
export const FullServiceFragmentDoc = gql`
    fragment FullService on Service {
  name
  service
  application {
    key
  }
  sites {
    name
    key
  }
  promoted
  enabled
  welcomeMessage
  statusMessage
  instances {
    name
    site {
      key
      name
    }
  }
}
    `;
export const ServiceApplicationFragmentDoc = gql`
    fragment ServiceApplication on Application {
  key
  name
  group
  statusUrl
  loginUrl
  authenticationProtocol
  contextPath
  multiSiteSupport
  serveAssignedInstances
}
    `;
export const OauthConfigurationDataFragmentDoc = gql`
    fragment OauthConfigurationData on OauthConfiguration {
  clientId
  clientSecret
  application
  services
}
    `;
export const SiteCompanyFragmentDoc = gql`
    fragment SiteCompany on Company {
  key
  name
  system
  instances {
    name
    enabled
  }
  roles {
    name
    label
    description
  }
}
    `;
export const SiteInstanceFragmentDoc = gql`
    fragment SiteInstance on Instance {
  name
  enabled
  regulation
  company {
    key
    name
    system
  }
}
    `;
export const InstanceForCertTemplateFragmentDoc = gql`
    fragment InstanceForCertTemplate on Instance {
  name
  company {
    key
    name
  }
  site {
    key
    name
  }
  walletCode
}
    `;
export const InstanceReferenceFragmentDoc = gql`
    fragment InstanceReference on Instance {
  name
  site {
    key
    name
  }
}
    `;
export const FullSiteFragmentDoc = gql`
    fragment FullSite on Site {
  name
  key
  accountName
  status
  type
  group
  occupationalLicenseRequired
  loginNotificationsEnabled
  services {
    service
    name
    application {
      name
      key
    }
  }
  instances {
    name
    regulation
    company {
      name
      key
    }
    enabled
  }
  companies {
    name
    key
  }
  supportGroup
  occupationalLicenseRequired
  version
  timezone
}
    `;
export const SiteReferenceFragmentDoc = gql`
    fragment SiteReference on Site {
  id
  key
  name
  group
}
    `;
export const GrantedSiteReferenceFragmentDoc = gql`
    fragment GrantedSiteReference on GrantedSite {
  key
  name
}
    `;
export const PermitProfileFragmentDoc = gql`
    fragment PermitProfile on SitePermitProfile {
  name
  type
  description
  id
  managerGroup {
    name
    key
    company {
      key
    }
  }
  notifyDaysBefore
  sites {
    name
    key
  }
}
    `;
export const FullPermitFragmentDoc = gql`
    fragment FullPermit on SitePermit {
  id
  permitNumber
  profile {
    id
    name
    sites {
      name
    }
  }
  admin {
    username
    domain {
      code
    }
  }
  validFrom
  validTo
  revoked
}
    `;
export const PermitWithoutProfileFragmentDoc = gql`
    fragment PermitWithoutProfile on SitePermit {
  id
  permitNumber
  admin {
    domain {
      code
    }
    username
    company {
      name
    }
    firstName
    lastName
  }
  validFrom
  validTo
  revoked
}
    `;
export const SiteForWebApiTemplateFragmentDoc = gql`
    fragment SiteForWebAPITemplate on Site {
  name
  key
  networks {
    name
    code
    externalid
    type {
      name
    }
  }
}
    `;
export const DashboardSiteFragmentDoc = gql`
    fragment DashboardSite on GrantedSite {
  key
  name
  instances {
    name
    regulation
  }
  services {
    id
    name
    service
    application {
      key
      name
      contextPath
      loginUrl
      deprecated
    }
  }
  manualNomConfigured
}
    `;
export const CertificateDeploymentDataFragmentDoc = gql`
    fragment CertificateDeploymentData on SiteCertificateDeployment {
  profile
  application
  certificate {
    id
    dc
    name
    status
    validFrom
    validTo
  }
}
    `;
export const ApplicationWithTechnicalAccountInfoFragmentDoc = gql`
    fragment ApplicationWithTechnicalAccountInfo on Application {
  name
  key
  technicalAccountConf {
    vaultPath
    centralVaultPath
    generateConfigApiCredentials
    useClientCertificateAuthentication
  }
}
    `;
export const TechnicalAccountFragmentFragmentDoc = gql`
    fragment TechnicalAccountFragment on TechnicalAccount {
  created
  modified
  username
  email
  company {
    name
    key
  }
  domain {
    code
  }
  enabled
  oauthClientId
  application {
    ...ApplicationWithTechnicalAccountInfo
  }
}
    ${ApplicationWithTechnicalAccountInfoFragmentDoc}`;
export const AdminQuickSearchDocument = gql`
    query adminQuickSearch($quickSearch: String!, $enabled: Boolean) {
  findAdminAccounts(search: {filter: $quickSearch, enabled: $enabled}) {
    ...AdminInfo
  }
}
    ${AdminInfoFragmentDoc}`;
export const GetAdminConfigurationDocument = gql`
    query getAdminConfiguration($key: String!) {
  getAdminConfiguration(key: $key)
}
    `;
export const SaveAdminConfigurationDocument = gql`
    mutation saveAdminConfiguration($key: String!, $value: String!) {
  saveAdminConfiguration(key: $key, value: $value)
}
    `;
export const AddAdminAccountDocument = gql`
    mutation AddAdminAccount($admin: NewAdmin!) {
  addAdminAccount(admin: $admin) {
    username
  }
}
    `;
export const RemoveAdminConfigurationDocument = gql`
    mutation removeAdminConfiguration($key: String!) {
  removeAdminConfiguration(key: $key)
}
    `;
export const SetActorTimezoneDocument = gql`
    mutation setActorTimezone($timezone: String!) {
  setActorTimezone(timezone: $timezone)
}
    `;
export const FindAdminReportsFromGroupsDocument = gql`
    query FindAdminReportsFromGroups($companyKey: String!, $domain: String!, $username: String!) {
  adminGroups(companyKey: $companyKey, domain: $domain, username: $username) {
    key
    name
    company {
      key
    }
    grantedSites {
      name
    }
    grantedReports {
      key
      name
      reportGroup
      site {
        name
      }
    }
    managedReports {
      key
      name
      reportGroup
      site {
        name
      }
    }
  }
}
    `;
export const FindAdminGroupsDocument = gql`
    query FindAdminGroups($companyKey: String!, $domain: String!, $username: String!) {
  adminGroups(companyKey: $companyKey, domain: $domain, username: $username) {
    key
    name
    companyCount
    siteCount
    instanceCount
    managerGroup {
      key
      name
      company {
        key
        name
      }
    }
    company {
      key
      name
    }
  }
}
    `;
export const FindAdminPermissionsFromGroupsDocument = gql`
    query FindAdminPermissionsFromGroups($companyKey: String!, $domain: String!, $username: String!) {
  adminGroups(companyKey: $companyKey, domain: $domain, username: $username) {
    grantedApplications {
      key
      name
      permissions {
        key
        name
        tags
        system
        reportPermission
        dedicated
      }
      roles {
        key
        name
        permissions {
          key
          name
          tags
          system
          reportPermission
          dedicated
        }
        roles {
          key
          name
          permissions {
            key
            name
            tags
            system
            reportPermission
            dedicated
          }
        }
      }
    }
    key
    name
    company {
      key
      name
    }
  }
}
    `;
export const FindAdminSitesAndInstancesFromGroupsDocument = gql`
    query FindAdminSitesAndInstancesFromGroups($companyKey: String!, $domain: String!, $username: String!) {
  adminGroups(companyKey: $companyKey, domain: $domain, username: $username) {
    grantedSites {
      name
      key
      accountName
    }
    grantedInstances {
      name
      site {
        key
      }
      company {
        key
      }
    }
    key
    name
    company {
      key
      name
    }
  }
}
    `;
export const FindAdminManagerGroupsFromGroupsDocument = gql`
    query FindAdminManagerGroupsFromGroups($companyKey: String!, $domain: String!, $username: String!) {
  adminGroups(companyKey: $companyKey, domain: $domain, username: $username) {
    managerGroup {
      name
    }
    key
    name
    company {
      key
      name
    }
  }
}
    `;
export const UpdateAdminAccountDocument = gql`
    mutation UpdateAdminAccount($admin: NewAdmin!, $currentCompany: String!, $currentDomain: String!, $currentUsername: String!, $passwordConfirmation: String) {
  updateAdminAccount(admin: $admin, currentCompany: $currentCompany, currentDomain: $currentDomain, currentUsername: $currentUsername, passwordConfirmation: $passwordConfirmation) {
    username
  }
}
    `;
export const FindAdminAccountDocument = gql`
    query FindAdminAccount($companyKey: String!, $domainCode: String!, $username: String!) {
  findAdmin(companyId: $companyKey, domain: $domainCode, username: $username) {
    ...FullAdmin
  }
}
    ${FullAdminFragmentDoc}`;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    username
    optInEmails
    firstName
    position
    phoneNumber
    hasUserImage
    lastName
    enabled
    email
    timezone
    optInEmails
    mailingListOptIn
    customTags {
      key
      value
    }
    commonAuthData {
      twoFactorActivated
      multiplesessionaction
      twoFactorEnabled
      infraLdapUsername
      authenticationMode
    }
    company {
      name
      key
      optInEmails
      system
      mailingList {
        name
      }
    }
    domain {
      code
      authenticationProvider
      enableTwoFactorAuthentication
    }
  }
}
    `;
export const SendTestEmailDocument = gql`
    query SendTestEmail($companyKey: String!, $domain: String, $username: String!) {
  sendTestEmail(companyKey: $companyKey, domain: $domain, username: $username)
}
    `;
export const FindAdAdminsDocument = gql`
    query FindAdAdmins($usernameSearch: String!) {
  findAdAdmins(usernameSearch: $usernameSearch) {
    admin {
      username
      firstName
      lastName
      company {
        name
        key
      }
      domain {
        code
      }
    }
    message
  }
}
    `;
export const FindServiceTicketsByGroupAndApplicationsDocument = gql`
    query FindServiceTicketsByGroupAndApplications($company: String!, $group: String!, $applications: [String]!) {
  serviceTicketsByGroupAndApplications(company: $company, group: $group, applications: $applications) {
    id
    tgt
    type
    loginIp
    loginDate
    lastActivityDate
    companyKey
    username
    domain
    open
    service
    serviceName
    siteName
  }
}
    `;
export const EndServiceTicketsDocument = gql`
    mutation endServiceTickets($company: String!, $group: String!, $applications: [String]!) {
  endServiceTickets(company: $company, group: $group, applications: $applications)
}
    `;
export const SyncAdminAccountDocument = gql`
    mutation syncAdminAccount($username: String!, $domain: String!, $company: String!) {
  syncAdminAccount(username: $username, domain: $domain, company: $company)
}
    `;
export const FindAdminGroupSitesDocument = gql`
    query FindAdminGroupSites($findAdmin: FindAdmin!, $pagination: Pagination, $search: GroupItemSearch) {
  findAdminSites(findAdmin: $findAdmin, pagination: $pagination, search: $search) {
    size
    first
    after
    page {
      canActorManageItem
      item {
        access
        delegateManagement
        accessAllInstances
        delegateManagementAllInstances
        identifier {
          name
          key
          group
          infraAccountName
          infraAccountKey
          status
        }
      }
      groups {
        group {
          key
          name
          company {
            key
          }
        }
        access
        delegateManagement
        accessAllInstances
        delegateManagementAllInstances
      }
    }
  }
}
    `;
export const FindAdminGroupCompaniesDocument = gql`
    query FindAdminGroupCompanies($findAdmin: FindAdmin!, $pagination: Pagination, $search: GroupItemSearch) {
  findAdminCompanies(findAdmin: $findAdmin, pagination: $pagination, search: $search) {
    size
    first
    after
    page {
      canActorManageItem
      item {
        access
        delegateManagement
        accessAllInstances
        delegateManagementAllInstances
        identifier {
          name
          key
        }
      }
      groups {
        group {
          key
          name
          company {
            key
          }
        }
        access
        delegateManagement
        accessAllInstances
        delegateManagementAllInstances
      }
    }
  }
}
    `;
export const FindAdminGroupInstancesDocument = gql`
    query FindAdminGroupInstances($findAdmin: FindAdmin!, $pagination: Pagination, $search: GroupItemSearch) {
  findAdminInstances(findAdmin: $findAdmin, pagination: $pagination, search: $search) {
    size
    first
    after
    page {
      canActorManageItem
      item {
        access
        delegateManagement
        identifier {
          name
          enabled
          site {
            key
            name
          }
          company {
            name
            key
          }
        }
      }
      groups {
        group {
          key
          name
          company {
            key
          }
        }
        access
        delegateManagement
      }
    }
  }
}
    `;
export const FindAdminGroupReportsDocument = gql`
    query FindAdminGroupReports($findAdmin: FindAdmin!, $pagination: Pagination, $search: GroupItemSearch) {
  findAdminReports(findAdmin: $findAdmin, pagination: $pagination, search: $search) {
    size
    first
    after
    page {
      canActorManageItem
      item {
        access
        delegateManagement
        identifier {
          name
          key
          reportGroup
          frozen
          site {
            key
            name
          }
        }
      }
      groups {
        group {
          key
          name
          company {
            key
          }
        }
        access
        delegateManagement
      }
    }
  }
}
    `;
export const FindAdminGroupPermissionsDocument = gql`
    query FindAdminGroupPermissions($findAdmin: FindAdmin!, $pagination: Pagination, $search: GroupItemSearch) {
  findAdminPermissions(findAdmin: $findAdmin, pagination: $pagination, search: $search) {
    size
    first
    after
    page {
      item {
        access
        delegateManagement
        key
        name
        application {
          key
          name
          owner
        }
        tags
        regulations
        roleDerived
        reportPermission
        description
      }
      groups {
        group {
          key
          name
          company {
            key
          }
        }
        access
        delegateManagement
        grantedByRoleNames
        managementByRoleNames
      }
    }
  }
}
    `;
export const FindAdminApplicationsDocument = gql`
    query findAdminApplications($findAdmin: FindAdmin!) {
  findAdminApplications(findAdmin: $findAdmin) {
    key
    name
  }
}
    `;
export const FindApplicationsDocument = gql`
    query findApplications($loadFromDB: Boolean) {
  applications(loadFromDB: $loadFromDB) {
    ...FullApplication
  }
}
    ${FullApplicationFragmentDoc}`;
export const FindApplicationDocument = gql`
    query findApplication($application: String!) {
  findApplication(application: $application) {
    ...FullApplication
  }
}
    ${FullApplicationFragmentDoc}`;
export const FindRoleGroupsDocument = gql`
    query findRoleGroups($application: String!, $role: String!) {
  findRoleGroups(application: $application, role: $role) {
    ...GroupReference
  }
}
    ${GroupReferenceFragmentDoc}`;
export const FindPermissionGroupsDocument = gql`
    query findPermissionGroups($application: String!, $permission: String!) {
  findPermissionGroups(application: $application, permission: $permission) {
    ...GroupReference
  }
}
    ${GroupReferenceFragmentDoc}`;
export const FindApplicationsWithPermissionsDocument = gql`
    query findApplicationsWithPermissions {
  applications {
    ...LightApplicationPermission
  }
}
    ${LightApplicationPermissionFragmentDoc}`;
export const DeleteApplicationItemsDocument = gql`
    mutation deleteApplicationItems($items: [ApplicationItemInput!]!, $comment: String!) {
  deleteApplicationItems(items: $items, comment: $comment)
}
    `;
export const FindDeprecatedAppItemsDocument = gql`
    query findDeprecatedAppItems {
  findDeprecatedAppItems {
    id
    key
    name
    deprecated
    modified
    roles {
      id
      key
      name
      deprecated
      modified
    }
    permissions {
      id
      key
      name
      deprecated
      modified
    }
  }
}
    `;
export const ReloadApplicationsDocument = gql`
    mutation reloadApplications($reloadOnlyApps: Boolean) {
  reloadApplications(reloadOnlyApps: $reloadOnlyApps)
}
    `;
export const MigratePermissionDocument = gql`
    mutation migratePermission($fromPermission: String!, $fromApplication: String!, $fromIsRole: Boolean!, $toPermission: String!, $toApplication: String!, $toIsRole: Boolean!, $removeSourcePermission: Boolean!, $comment: String!) {
  migratePermission(fromPermission: $fromPermission, fromApplication: $fromApplication, fromIsRole: $fromIsRole, toPermission: $toPermission, toApplication: $toApplication, toIsRole: $toIsRole, removeSourcePermission: $removeSourcePermission, comment: $comment) {
    group {
      key
      name
    }
    sourcePermManagement
    sourcePermAccess
    permToBeAddedAccess
    permToBeAddedManagement
  }
}
    `;
export const ExecuteAuditDocument = gql`
    query executeAudit($name: String!, $parameters: [KeyValuePair!]!, $pagination: Pagination, $sort: Sort) {
  executeAudit(key: $name, parameters: $parameters, pagination: $pagination, sort: $sort) {
    size
    first
    after
    page {
      headers
      rows
    }
  }
}
    `;
export const FindAuditCountDocument = gql`
    query findAuditCount($name: String!, $parameters: [KeyValuePair!]!) {
  findAuditResultsCount(key: $name, parameters: $parameters)
}
    `;
export const AddScheduledAuditDocument = gql`
    mutation addScheduledAudit($configuration: ScheduledAuditInput!) {
  addScheduledAuditConfiguration(configuration: $configuration) {
    id
    created
    parameters {
      key
      value
    }
    notificationType
    includeCsv
    description
    created
    creator {
      admin {
        username
      }
    }
  }
}
    `;
export const RemoveScheduledAuditDocument = gql`
    mutation removeScheduledAudit($id: Int!) {
  removeScheduledAuditConfiguration(id: $id) {
    id
  }
}
    `;
export const FindAuditDocument = gql`
    query findAudit($key: String!) {
  findAudits(key: $key) {
    name
    key
    description
    tags
    parameters {
      name
      key
      type
      required
      description
      multiple
      options {
        key
        value
      }
    }
  }
}
    `;
export const FindAuditsListDocument = gql`
    query findAuditsList {
  findAudits {
    ...AuditReference
  }
}
    ${AuditReferenceFragmentDoc}`;
export const FindScheduledAuditConfigurationsDocument = gql`
    query FindScheduledAuditConfigurations {
  findScheduledAuditConfigurations {
    ...FullAuditConfiguration
  }
}
    ${FullAuditConfigurationFragmentDoc}`;
export const SyncClientCertsDocument = gql`
    mutation syncClientCerts {
  syncClientCerts
}
    `;
export const AddClientCertTemplateDocument = gql`
    mutation addClientCertTemplate($template: NewCertificateTemplate!) {
  value: addClientCertTemplate(template: $template) {
    ...CertificateTemplateTableEntry
  }
}
    ${CertificateTemplateTableEntryFragmentDoc}`;
export const DeleteClientCertTemplateDocument = gql`
    mutation deleteClientCertTemplate($templateId: Int!) {
  deleteClientCertTemplate(templateId: $templateId)
}
    `;
export const ToggleClientCertTemplateDocument = gql`
    mutation toggleClientCertTemplate($enable: Boolean!, $templateId: Int!) {
  toggleClientCertTemplate(enable: $enable, templateId: $templateId)
}
    `;
export const CountClientCertTemplatesDocument = gql`
    query countClientCertTemplates($search: CertificateTemplateSearch!) {
  value: countClientCertTemplates(search: $search)
}
    `;
export const FindClientCertTemplatesDocument = gql`
    query findClientCertTemplates($search: CertificateTemplateSearch!, $pagination: Pagination, $sort: [Sort!]!) {
  value: findClientCertTemplates(search: $search, pagination: $pagination, sort: $sort) {
    ...CertificateTemplateTableEntry
  }
}
    ${CertificateTemplateTableEntryFragmentDoc}`;
export const FindClientCertsDocument = gql`
    query findClientCerts($templateId: Int!) {
  value: findClientCerts(templateId: $templateId) {
    ...CertificateTableEntry
  }
}
    ${CertificateTableEntryFragmentDoc}`;
export const ToggleClientCertRevocationDocument = gql`
    mutation toggleClientCertRevocation($certId: Int!, $revoke: Boolean!) {
  toggleClientCertRevocation(certId: $certId, revoke: $revoke)
}
    `;
export const ToggleClientCertNotificationsDocument = gql`
    mutation toggleClientCertNotifications($certId: Int!, $notify: Boolean!) {
  toggleClientCertNotifications(certId: $certId, notify: $notify)
}
    `;
export const GetClientCertPrivateKeyDocument = gql`
    query getClientCertPrivateKey($certId: Int!, $password: String, $format: CertificateFormat!) {
  value: getClientCertPrivateKey(certId: $certId, password: $password, format: $format)
}
    `;
export const ImportClientCertsDocument = gql`
    mutation importClientCerts($templateId: Int!) {
  importClientCerts(templateId: $templateId)
}
    `;
export const IssueClientCertDocument = gql`
    mutation issueClientCert($cert: NewCertificate!, $muteReplacedNotifications: Boolean, $replaces: Int, $notifyDaysBefore: [Int!]!) {
  value: issueClientCert(cert: $cert, muteReplacedNotifications: $muteReplacedNotifications, replaces: $replaces, notifyDaysBefore: $notifyDaysBefore) {
    ...CertificateTableEntry
  }
}
    ${CertificateTableEntryFragmentDoc}`;
export const GetImportableCertificatesDocument = gql`
    query getImportableCertificates {
  value: importableCerts {
    ...ImportableCertificateEntry
  }
}
    ${ImportableCertificateEntryFragmentDoc}`;
export const GetClientCertDocument = gql`
    query getClientCert($certId: Int!) {
  getClientCert(id: $certId) {
    id
    notifications {
      id
      date
      sent
    }
  }
}
    `;
export const AddClientCertNotificationGroupDocument = gql`
    mutation addClientCertNotificationGroup($templateId: Int!, $group: String!) {
  addClientCertNotificationGroups(templateId: $templateId, groups: [$group])
}
    `;
export const RemoveClientCertNotificationGroupDocument = gql`
    mutation removeClientCertNotificationGroup($templateId: Int!, $group: String!) {
  removeClientCertNotificationGroups(templateId: $templateId, groups: [$group])
}
    `;
export const AddClientCertNotificationDocument = gql`
    mutation addClientCertNotification($certId: Int!, $daysBeforeExpiration: Int!) {
  addClientCertNotification(certId: $certId, notifyDaysBefore: $daysBeforeExpiration) {
    id
  }
}
    `;
export const DeleteClientCertNotificationDocument = gql`
    mutation deleteClientCertNotification($notificationId: Int!) {
  deleteClientCertNotification(id: $notificationId) {
    id
  }
}
    `;
export const FindManagedCompanyAndDomainRelationsDocument = gql`
    query findManagedCompanyAndDomainRelations($authProviders: [AuthenticationProvider!]) {
  managedCompanies(authProviders: $authProviders) {
    ...CompanyWithLoginDomains
  }
}
    ${CompanyWithLoginDomainsFragmentDoc}`;
export const FindManagedCompaniesDocument = gql`
    query findManagedCompanies {
  managedCompanies {
    ...FullCompany
  }
}
    ${FullCompanyFragmentDoc}`;
export const FindCompaniesForCompaniesListDocument = gql`
    query findCompaniesForCompaniesList {
  managedCompanies {
    ...CompanyListEntry
  }
}
    ${CompanyListEntryFragmentDoc}`;
export const FindManagedCompaniesWithSitesDocument = gql`
    query findManagedCompaniesWithSites {
  managedCompanies {
    ...CompanyWithSites
  }
}
    ${CompanyWithSitesFragmentDoc}`;
export const FindCompanyByKeyDocument = gql`
    query findCompanyByKey($key: String!) {
  findCompany(companyKey: $key) {
    ...FullCompany
  }
}
    ${FullCompanyFragmentDoc}`;
export const FindGrantedCompaniesDocument = gql`
    query findGrantedCompanies {
  grantedCompanies {
    ...FullGrantedCompany
  }
}
    ${FullGrantedCompanyFragmentDoc}`;
export const FindManagedCompanyReferencesDocument = gql`
    query findManagedCompanyReferences {
  managedCompanies {
    ...CompanyReference
  }
}
    ${CompanyReferenceFragmentDoc}`;
export const FindGrantedCompanyReferencesLightDocument = gql`
    query findGrantedCompanyReferencesLight {
  grantedCompanies {
    ...CompanyReferenceLight
  }
}
    ${CompanyReferenceLightFragmentDoc}`;
export const FindCompanyRolesDocument = gql`
    query findCompanyRoles {
  findCompanyRoles {
    name
    label
    description
  }
}
    `;
export const AddCompanyDocument = gql`
    mutation addCompany($company: CompanyInput!) {
  addCompany(company: $company) {
    ...FullCompany
  }
}
    ${FullCompanyFragmentDoc}`;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($company: CompanyInput!, $key: String!) {
  updateCompany(company: $company, key: $key) {
    ...FullCompany
  }
}
    ${FullCompanyFragmentDoc}`;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($key: String!) {
  removeCompany(company: $key) {
    ...CompanyReference
  }
}
    ${CompanyReferenceFragmentDoc}`;
export const CountCompaniesDocument = gql`
    query countCompanies($search: CompanySearch!) {
  countCompanies(search: $search)
}
    `;
export const FindCompaniesDocument = gql`
    query findCompanies($search: CompanySearch!, $pagination: Pagination!, $sort: [Sort!]) {
  findCompanies(search: $search, pagination: $pagination, sort: $sort) {
    ...CompanyListEntry
  }
}
    ${CompanyListEntryFragmentDoc}`;
export const FindDefaultPasswordPolicyDocument = gql`
    query findDefaultPasswordPolicy {
  findDefaultPasswordPolicy {
    ...PasswordPolicyReference
  }
}
    ${PasswordPolicyReferenceFragmentDoc}`;
export const FindPasswordPolicyContextDocument = gql`
    query findPasswordPolicyContext($domain: String!) {
  findPasswordPolicyContext(domain: $domain) {
    ...PasswordPolicyContextReference
  }
}
    ${PasswordPolicyContextReferenceFragmentDoc}`;
export const UpdatePasswordPolicyDocument = gql`
    mutation updatePasswordPolicy($company: String!, $domain: String!, $policy: PasswordPolicyInput!) {
  updatePwdPolicy(company: $company, domain: $domain, policy: $policy) {
    ...PasswordPolicyReference
  }
}
    ${PasswordPolicyReferenceFragmentDoc}`;
export const AddLoginDomainDocument = gql`
    mutation addLoginDomain($company: String!, $domain: LoginDomainInput!) {
  addLoginDomain(company: $company, domain: $domain) {
    ...LoginDomainFragment
  }
}
    ${LoginDomainFragmentFragmentDoc}`;
export const EditLoginDomainDocument = gql`
    mutation editLoginDomain($company: String!, $domain: LoginDomainInput!) {
  updateLoginDomain(company: $company, domain: $domain) {
    ...LoginDomainFragment
  }
}
    ${LoginDomainFragmentFragmentDoc}`;
export const RemoveLoginDomainDocument = gql`
    mutation removeLoginDomain($company: String!, $domain: String!) {
  removeLoginDomain(company: $company, domain: $domain) {
    ...LoginDomainFragment
  }
}
    ${LoginDomainFragmentFragmentDoc}`;
export const RefreshLoginDomainMetadataDocument = gql`
    mutation refreshLoginDomainMetadata($company: String!, $domain: String!) {
  refreshLoginDomainMetadata(company: $company, domain: $domain) {
    ...LoginDomainFragment
  }
}
    ${LoginDomainFragmentFragmentDoc}`;
export const SyncCompanyCrmDataDocument = gql`
    mutation syncCompanyCrmData($syncAll: Boolean!) {
  syncCompanyCrmData(syncAll: $syncAll) {
    name
  }
}
    `;
export const GetEventTypesDocument = gql`
    query getEventTypes {
  eventTypes {
    name
    description
    level
  }
}
    `;
export const FindEventsDocument = gql`
    query findEvents($eventSearch: EventSearch, $pagination: Pagination) {
  events(eventSearch: $eventSearch, pagination: $pagination) {
    code
    created
    domain
    actorDomain
    actorUsername
    targetDomain
    targetUsername
    companyName
    eventip
    message
    eventName
    flowId
    versionName
  }
}
    `;
export const CountEventsDocument = gql`
    query countEvents($eventSearch: EventSearch) {
  eventCount(eventSearch: $eventSearch)
}
    `;
export const GetLayoutInfoDocument = gql`
    query getLayoutInfo {
  menu {
    folders {
      ...rootFolder
    }
  }
  whoAmI {
    domain {
      code
      authenticationProvider
      authenticationProviderAttributes {
        externallyGoverned
      }
    }
    username
    company {
      name
      key
    }
    timezone
  }
  activeSession {
    sessionCreation
    idleTimeoutInSeconds
    hardTimeoutInSeconds
  }
}
    ${RootFolderFragmentDoc}`;
export const GetActiveSessionDocument = gql`
    query getActiveSession {
  activeSession {
    sessionCreation
    idleTimeoutInSeconds
    hardTimeoutInSeconds
  }
}
    `;
export const PermissionsDocument = gql`
    query permissions {
  permissions
}
    `;
export const FindGroupsWithPermissionDocument = gql`
    query findGroupsWithPermission($appKey: String!, $permissionKey: String!, $accessType: AccessType!, $searchRoles: Boolean!) {
  findGroupsWithPermission(application: $appKey, permissionKey: $permissionKey, accessType: $accessType, searchRoles: $searchRoles) {
    key
    name
    company {
      key
      name
    }
  }
}
    `;
export const FindGroupsWithRoleDocument = gql`
    query findGroupsWithRole($application: String!, $roleKey: String!, $accessType: AccessType!, $searchRoles: Boolean!) {
  findGroupsWithRole(application: $application, roleKey: $roleKey, accessType: $accessType, searchRoles: $searchRoles) {
    key
    name
    company {
      key
      name
    }
  }
}
    `;
export const FindPermissionMigrationGroupsDocument = gql`
    query findPermissionMigrationGroups($fromApplication: String!, $fromPermission: String!, $fromIsRole: Boolean!, $toApplication: String!, $toPermission: String!, $toIsRole: Boolean!) {
  findPermissionMigrationGroups(fromApplication: $fromApplication, fromPermission: $fromPermission, fromIsRole: $fromIsRole, toApplication: $toApplication, toPermission: $toPermission, toIsRole: $toIsRole) {
    group {
      key
      name
      manager
    }
    permToBeAddedAccess
    permToBeAddedManagement
    permCanBeAdded
    sourcePermAccess
    sourcePermManagement
  }
}
    `;
export const FindGroupSitesDocument = gql`
    query findGroupSites($company: String!, $group: String!) {
  findGroupSites(group: $group, company: $company) {
    identifier {
      key
      name
      group
      infraAccountKey
      infraAccountName
      status
    }
    access
    delegateManagement
    accessAllInstances
    delegateManagementAllInstances
    grantedBy
    managedBy
  }
}
    `;
export const FindAdminPermissionSitesAccessDocument = gql`
    query findAdminPermissionSitesAccess($permission: PermissionInput!, $admin: FindAdmin!) {
  findAdminPermissionSitesAccess(permission: $permission, admin: $admin) {
    identifier {
      name
      key
    }
    access
    delegateManagement
  }
}
    `;
export const FindGroupCompaniesDocument = gql`
    query findGroupCompanies($company: String!, $group: String!) {
  findGroupCompanies(group: $group, company: $company) {
    identifier {
      key
      name
      system
    }
    roles {
      name
      label
      description
    }
    access
    delegateManagement
    accessAllInstances
    delegateManagementAllInstances
    grantedBy
    managedBy
  }
}
    `;
export const FindGroupInstancesDocument = gql`
    query findGroupInstances($company: String!, $group: String!) {
  findGroupInstances(group: $group, company: $company) {
    identifier {
      site {
        name
        key
        group
      }
      name
      company {
        name
        key
      }
    }
    access
    delegateManagement
    grantedBy
    managedBy
  }
}
    `;
export const GetGroupMembersDocument = gql`
    query getGroupMembers($companyKey: String!, $groupKey: String!) {
  findGroup(company: $companyKey, group: $groupKey) {
    key
    name
    company {
      key
      system
    }
    members {
      id
      account {
        ...AdminInfo
      }
      source {
        name
        dn
      }
      addedBy {
        ...AdminInfo
      }
      created
    }
  }
}
    ${AdminInfoFragmentDoc}`;
export const FindGroupCandidatesDocument = gql`
    query FindGroupCandidates($group: String!, $company: String!, $filter: String, $includeAlreadyAdded: Boolean) {
  findMemberCandidates(group: $group, company: $company, filter: $filter, includeAlreadyAdded: $includeAlreadyAdded) {
    ...AdminInfo
  }
}
    ${AdminInfoFragmentDoc}`;
export const AddGroupMembersDocument = gql`
    mutation AddGroupMembers($addedMembers: [GroupMemberInput!]!, $comment: String!) {
  addGroupMembers(addedMembers: $addedMembers, comment: $comment) {
    member {
      account {
        ...AdminInfo
      }
      addedBy {
        ...AdminInfo
      }
      created
    }
    group {
      ...GroupIdentifier
    }
    message
    added
  }
}
    ${AdminInfoFragmentDoc}
${GroupIdentifierFragmentDoc}`;
export const AddMembersFromDomainAndUsernameDocument = gql`
    mutation addMembersFromDomainAndUsername($group: String!, $company: String!, $usernames: [String!]!, $comment: String!) {
  addMembersFromDomainAndUsername(group: $group, company: $company, usernames: $usernames, comment: $comment) {
    ...AdminInfo
  }
}
    ${AdminInfoFragmentDoc}`;
export const RemoveGroupMembersDocument = gql`
    mutation RemoveGroupMembers($removedMembers: [GroupMemberInput!]!, $comment: String!) {
  removeGroupMembers(removedMembers: $removedMembers, comment: $comment) {
    account {
      ...AdminInfo
    }
    created
  }
}
    ${AdminInfoFragmentDoc}`;
export const GetBoundAmsGroupsDocument = gql`
    query GetBoundAmsGroups($companyKey: String!, $groupKey: String!) {
  findGroup(company: $companyKey, group: $groupKey) {
    key
    company {
      key
    }
    amsBindings {
      name
      dn
      members
      childGroups {
        name
        dn
        members
      }
    }
  }
}
    `;
export const FindAmsGroupsDocument = gql`
    query FindAmsGroups($search: String!) {
  findAmsGroups(search: $search) {
    name
    dn
    members
    childGroups {
      name
      dn
      members
    }
  }
}
    `;
export const UpdateAmsGroupBindingsDocument = gql`
    mutation updateAmsGroupBindings($company: String!, $group: String!, $addedAmsBindings: [AmsGroupInput!]!, $removedAmsBindings: [AmsGroupInput!]!, $comment: String!) {
  updateAmsGroupBindings(company: $company, group: $group, addedAmsBindings: $addedAmsBindings, removedAmsBindings: $removedAmsBindings, comment: $comment) {
    key
    company {
      key
    }
    amsBindings {
      name
      dn
      members
    }
  }
}
    `;
export const FindGroupReportsDocument = gql`
    query FindGroupReports($groupKey: String!) {
  findGroupReports(groupKey: $groupKey) {
    ...FullGroupReport
  }
}
    ${FullGroupReportFragmentDoc}`;
export const FindGroupAccessedAndManagedSitesDocument = gql`
    query FindGroupAccessedAndManagedSites($groupKey: String!, $companyKey: String!) {
  findGroup(group: $groupKey, company: $companyKey) {
    grantedSites {
      key
      name
    }
    managedSites {
      key
      name
    }
  }
}
    `;
export const FindGroupRolesAndPermissionsDocument = gql`
    query findGroupRolesAndPermissions($companyKey: String!, $groupKey: String!) {
  findGroupRolesAndPermissions(company: $companyKey, group: $groupKey) {
    name
    key
    roleDerived
    access
    accessSystem
    delegateManagement
    delegateManagementSystem
    system
    dedicated
    description
    deprecated
    application {
      name
      key
      owner
    }
    tags
    managementByRoleNames
    grantedByRoleNames
    taEnabled
  }
}
    `;
export const FindRoleSubRolesAndPermissionsDocument = gql`
    query findRoleSubRolesAndPermissions($companyKey: String!, $groupKey: String!, $role: String!, $application: String!) {
  findRoleSubRolesAndPermissions(group: $groupKey, company: $companyKey, role: $role, application: $application) {
    ...FullGroupPermissionTreeItem
  }
}
    ${FullGroupPermissionTreeItemFragmentDoc}`;
export const FindGroupItemChangesDocument = gql`
    query findGroupItemChanges($company: String!, $group: String!, $items: [GroupItemInput!]!) {
  findGroupItemChanges(company: $company, group: $group, groupItems: $items) {
    changedSites {
      ...FullGroupSite
      grantedBy
      managedBy
    }
    changedCompanies {
      ...FullGroupCompany
      grantedBy
      managedBy
    }
    changedInstances {
      ...FullGroupInstance
      grantedBy
      managedBy
    }
    changedReports {
      ...FullGroupReport
      grantedBy
      managedBy
    }
    changedPermissions {
      ...GroupPermissionChange
    }
  }
}
    ${FullGroupSiteFragmentDoc}
${FullGroupCompanyFragmentDoc}
${FullGroupInstanceFragmentDoc}
${FullGroupReportFragmentDoc}
${GroupPermissionChangeFragmentDoc}`;
export const UpdateGroupItemsDocument = gql`
    mutation UpdateGroupItems($company: String!, $group: String!, $items: [GroupItemInput!]!, $comment: String!) {
  updateGroupItems(company: $company, group: $group, items: $items, comment: $comment) {
    id
    changedSites {
      ...FullGroupSite
      grantedBy
      managedBy
    }
    changedCompanies {
      ...FullGroupCompany
      grantedBy
      managedBy
    }
    changedInstances {
      ...FullGroupInstance
      grantedBy
      managedBy
    }
    changedReports {
      ...FullGroupReport
      grantedBy
      managedBy
    }
    changedPermissions {
      ...GroupPermissionChange
    }
  }
}
    ${FullGroupSiteFragmentDoc}
${FullGroupCompanyFragmentDoc}
${FullGroupInstanceFragmentDoc}
${FullGroupReportFragmentDoc}
${GroupPermissionChangeFragmentDoc}`;
export const FindChangesetChangesDocument = gql`
    query findChangesetChanges($company: String!, $group: String!, $changesetId: String!) {
  findChangesetChanges(company: $company, group: $group, changesetId: $changesetId) {
    key {
      name
      key
      company {
        name
        key
      }
    }
    value {
      ...FullGroupItemChangeset
    }
  }
}
    ${FullGroupItemChangesetFragmentDoc}`;
export const RevertChangesetsDocument = gql`
    mutation revertChangesets($company: String!, $group: String!, $changesetId: String!, $comment: String!) {
  revertChangesets(company: $company, group: $group, changesetId: $changesetId, comment: $comment) {
    ...FullGroupItemChangeset
  }
}
    ${FullGroupItemChangesetFragmentDoc}`;
export const FindOneGroupItemChangeSetsDocument = gql`
    query findOneGroupItemChangeSets($company: String!, $group: String!, $groupItemInput: GroupItemInput) {
  findOneGroupItemChangeSets(company: $company, group: $group, groupItem: $groupItemInput) {
    ...FullGroupItemChangeset
  }
}
    ${FullGroupItemChangesetFragmentDoc}`;
export const FindGroupItemsChangeSetsDocument = gql`
    query findGroupItemsChangeSets($company: String!, $group: String!, $items: [GroupItemType!]!, $searchMembers: Boolean!, $startDate: String!, $endDate: String!) {
  findGroupItemsChangeSets(company: $company, group: $group, items: $items, searchMembers: $searchMembers, startDate: $startDate, endDate: $endDate) {
    ...FullGroupItemChangeset
    headGroup {
      name
      key
      company {
        name
        key
      }
    }
    rollbackEnabled
  }
}
    ${FullGroupItemChangesetFragmentDoc}`;
export const FindGroupDocument = gql`
    query findGroup($group: String!, $company: String!) {
  findGroup(group: $group, company: $company) {
    name
    created
    enabled
    childGroupCount
    createdBy {
      ...AdminInfo
    }
    managerGroup {
      name
      key
      company {
        name
        key
      }
      members {
        id
        account {
          ...AdminInfo
        }
      }
    }
    audits {
      created
      admin {
        ...AdminInfo
      }
    }
    comments {
      created
      comment
      admin {
        ...AdminInfo
      }
    }
    description
  }
}
    ${AdminInfoFragmentDoc}`;
export const AddGroupAuditEntryDocument = gql`
    mutation addGroupAuditEntry($group: String!, $company: String!) {
  addGroupAuditEntry(group: $group, company: $company) {
    created
    admin {
      ...AdminInfo
    }
  }
}
    ${AdminInfoFragmentDoc}`;
export const AddGroupCommentDocument = gql`
    mutation addGroupComment($group: String!, $company: String!, $comment: String!) {
  addGroupComment(company: $company, group: $group, comment: $comment) {
    admin {
      ...AdminInfo
    }
    created
    comment
  }
}
    ${AdminInfoFragmentDoc}`;
export const UpdateGroupDescriptionDocument = gql`
    mutation updateGroupDescription($group: String!, $company: String!, $description: String!) {
  updateGroupDescription(company: $company, group: $group, description: $description) {
    key
    company {
      key
    }
    description
  }
}
    `;
export const AddGroupDocument = gql`
    mutation addGroup($group: GroupInput!) {
  addGroup(group: $group) {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const GrantedGroupsDocument = gql`
    query grantedGroups {
  grantedGroups {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const ManagedGroupsDocument = gql`
    query managedGroups($level: Int! = 1, $search: String, $siteName: String, $instanceName: String) {
  managedGroups(level: $level, search: $search, siteName: $siteName, instanceName: $instanceName) {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const FindChildGroupsDocument = gql`
    query findChildGroups($groupKey: String!, $companyKey: String!) {
  findGroup(group: $groupKey, company: $companyKey) {
    childGroups {
      ...GroupInfo
    }
  }
}
    ${GroupInfoFragmentDoc}`;
export const FindGroupMembersDocument = gql`
    query findGroupMembers($groupKey: String!, $companyKey: String!) {
  findGroup(group: $groupKey, company: $companyKey) {
    members {
      domainUsername
      account {
        enabled
      }
    }
  }
}
    `;
export const LoadGroupRelationsDocument = gql`
    query loadGroupRelations($groupKey: String!, $companyKey: String!) {
  findGroup(company: $companyKey, group: $groupKey) {
    members {
      account {
        username
        enabled
        domain {
          code
        }
        name
      }
    }
    grantedInstances {
      name
    }
    grantedSites {
      name
    }
    managedInstances {
      name
    }
    managedSites {
      name
    }
  }
}
    `;
export const LoadGroupSitesAndInstancesDocument = gql`
    query loadGroupSitesAndInstances($groupKey: String!, $companyKey: String!) {
  findGroup(company: $companyKey, group: $groupKey) {
    grantedInstances {
      name
    }
    grantedSites {
      name
    }
    managedInstances {
      name
    }
    managedSites {
      name
    }
  }
}
    `;
export const LoadGroupDocument = gql`
    query loadGroup($groupKey: String!, $companyKey: String!) {
  findGroup(company: $companyKey, group: $groupKey) {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const FindSiteGroupsDocument = gql`
    query findSiteGroups($siteName: String!, $groupName: String!) {
  querySiteGroup(siteName: $siteName, groupName: $groupName) {
    name
    permissions {
      key
      applicationKey
    }
  }
}
    `;
export const ToggleGroupDocument = gql`
    mutation toggleGroup($groupKey: String!, $companyKey: String!, $enable: Boolean!) {
  enableGroup(group: $groupKey, company: $companyKey, enable: $enable) {
    ...AdminGroupIdentifier
  }
}
    ${AdminGroupIdentifierFragmentDoc}`;
export const RemoveGroupDocument = gql`
    mutation removeGroup($groupKey: String!, $companyKey: String!) {
  removeGroup(company: $companyKey, group: $groupKey) {
    ...AdminGroupIdentifier
  }
}
    ${AdminGroupIdentifierFragmentDoc}`;
export const RenameGroupDocument = gql`
    mutation renameGroup($companyKey: String!, $groupKey: String!, $newName: String!) {
  renameGroup(company: $companyKey, group: $groupKey, newName: $newName) {
    ...AdminGroupIdentifier
  }
}
    ${AdminGroupIdentifierFragmentDoc}`;
export const AddGroupManagementDocument = gql`
    mutation addGroupManagement($groupKey: String!, $companyKey: String!) {
  addManagementPermission(group: $groupKey, company: $companyKey) {
    ...AdminGroupIdentifier
  }
}
    ${AdminGroupIdentifierFragmentDoc}`;
export const RemoveGroupManagementDocument = gql`
    mutation removeGroupManagement($groupKey: String!, $companyKey: String!) {
  removeManagementPermission(group: $groupKey, company: $companyKey) {
    ...AdminGroupIdentifier
  }
}
    ${AdminGroupIdentifierFragmentDoc}`;
export const AssignManagerDocument = gql`
    mutation assignManager($groupKey: String!, $companyKey: String!, $managerGroupKey: String!, $managerGroupCompanyKey: String!) {
  assignManager(group: $groupKey, company: $companyKey, managerGroup: $managerGroupKey, managerGroupCompany: $managerGroupCompanyKey) {
    managerGroup {
      key
      name
      company {
        key
      }
    }
  }
}
    `;
export const ValidateCloneGroupDocument = gql`
    query validateCloneGroup($sourceGroupKey: String!, $sourceCompanyKey: String!, $group: GroupInput!, $params: GroupCloneParams!) {
  validateCloneGroup(sourceGroup: $sourceGroupKey, sourceCompany: $sourceCompanyKey, group: $group, params: $params) {
    valid
    message
    siteDiff {
      name
      instances {
        name
      }
    }
    permissionDiff {
      name
      key
      permissions {
        name
        key
      }
      roles {
        name
        key
      }
    }
  }
}
    `;
export const ValidateImportGroupDocument = gql`
    query validateImportGroup($group: GroupInput!, $siteName: String!, $groupName: String!) {
  validateImportGroup(group: $group, siteName: $siteName, groupName: $groupName) {
    valid
    message
    siteDiff {
      name
      instances {
        name
      }
    }
    permissionDiff {
      name
      key
      permissions {
        name
        key
      }
      roles {
        name
        key
      }
    }
  }
}
    `;
export const FindManagedGroupsDocument = gql`
    query findManagedGroups($search: AdminGroupSearch, $pagination: Pagination) {
  findManagedGroups(search: $search, pagination: $pagination) {
    ...GroupPage
  }
}
    ${GroupPageFragmentDoc}`;
export const FindManagerGroupsDocument = gql`
    query findManagerGroups {
  managerGroups {
    ...GroupIdentifier
  }
}
    ${GroupIdentifierFragmentDoc}`;
export const FindGroupManagedCompaniesDocument = gql`
    query findGroupManagedCompanies($groupid: Int!) {
  findGroup(groupid: $groupid) {
    managedCompanies {
      id
      name
      key
      system
    }
  }
}
    `;
export const GrantSitesDocument = gql`
    mutation grantSites($groupId: String!, $companyId: String!, $sites: [String!]!, $management: Boolean!) {
  grantSites(group: $groupId, company: $companyId, sites: $sites, management: $management) {
    key
  }
}
    `;
export const GrantInstancesDocument = gql`
    mutation grantInstances($groupId: String!, $companyId: String!, $siteName: String!, $instances: [String!]!, $management: Boolean!) {
  grantInstances(group: $groupId, company: $companyId, site: $siteName, instances: $instances, management: $management) {
    key
  }
}
    `;
export const CloneGroupDocument = gql`
    mutation cloneGroup($sourceGroupKey: String!, $sourceCompanyKey: String!, $group: GroupInput!, $params: GroupCloneParams!) {
  cloneGroup(sourceGroup: $sourceGroupKey, sourceCompany: $sourceCompanyKey, group: $group, params: $params) {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const ImportGroupDocument = gql`
    mutation importGroup($group: GroupInput!, $siteName: String!, $groupName: String!) {
  importGroup(group: $group, siteName: $siteName, groupName: $groupName) {
    ...GroupInfo
  }
}
    ${GroupInfoFragmentDoc}`;
export const TokenInfoDocument = gql`
    query tokenInfo($token: String!) {
  validateToken(token: $token) {
    tokenSizeKiB
    isValid
    message
    created
    expires
    loginSessionInfo {
      open
      loginDate
      lastActivityDate
    }
  }
}
    `;
export const GetSettingsDocument = gql`
    query getSettings {
  settings {
    name
    value
  }
}
    `;
export const FindSiteNotificationTemplatesDocument = gql`
    query findSiteNotificationTemplates($siteKey: String!) {
  findSiteNotificationTemplates(siteKey: $siteKey) {
    ...SiteNotificationTemplate
  }
}
    ${SiteNotificationTemplateFragmentDoc}`;
export const AddNotificationTemplateDocument = gql`
    mutation addNotificationTemplate($name: String!, $trigger: NotificationTrigger!, $enabled: Boolean, $site: String, $recipients: [NewNotificationRecipient!]!, $template: String!, $templateTitle: String!, $attachmentName: String, $attachment: String, $tag: String) {
  addNotificationTemplate(template: {name: $name, enabled: $enabled, site: $site, trigger: $trigger, recipients: $recipients, templateTitle: $templateTitle, template: $template, attachmentName: $attachmentName, attachment: $attachment, tag: $tag}) {
    ...SiteNotificationTemplate
  }
}
    ${SiteNotificationTemplateFragmentDoc}`;
export const ModifyNotificationTemplateDocument = gql`
    mutation modifyNotificationTemplate($id: Long!, $name: String, $trigger: NotificationTrigger, $enabled: Boolean, $site: String, $recipients: [NewNotificationRecipient], $templateTitle: String, $template: String, $attachmentName: String, $attachment: String, $tag: String) {
  modifyNotificationTemplate(template: {id: $id, name: $name, enabled: $enabled, site: $site, trigger: $trigger, recipients: $recipients, templateTitle: $templateTitle, template: $template, attachmentName: $attachmentName, attachment: $attachment, tag: $tag}) {
    ...SiteNotificationTemplate
  }
}
    ${SiteNotificationTemplateFragmentDoc}`;
export const DeleteNotificationTemplateDocument = gql`
    mutation deleteNotificationTemplate($templateId: Long!) {
  deleteNotificationTemplate(templateId: $templateId) {
    ...SiteNotificationTemplate
  }
}
    ${SiteNotificationTemplateFragmentDoc}`;
export const SiteAccessReasonDocument = gql`
    mutation siteAccessReason($service: String!, $reason: String, $override: Boolean) {
  siteAccessReason(service: $service, reason: $reason, override: $override)
}
    `;
export const SendTestNotificationDocument = gql`
    mutation sendTestNotification($templateId: Long!) {
  sendTestNotification(templateId: $templateId)
}
    `;
export const SendTaggedNotificationDocument = gql`
    mutation sendTaggedNotification($tag: String!, $site: String!, $templateVariables: [KeyValuePair]) {
  sendTaggedNotifications(tag: $tag, site: $site, templateVariables: $templateVariables)
}
    `;
export const FindServiceApplicationsDocument = gql`
    query findServiceApplications {
  applications {
    ...ServiceApplication
  }
}
    ${ServiceApplicationFragmentDoc}`;
export const FindSiteServicesDocument = gql`
    query findSiteServices($siteName: String) {
  services(siteName: $siteName) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const FindOauthConfigurationDocument = gql`
    query findOauthConfiguration($clientId: String!, $application: String!) {
  findOauthConfiguration(clientId: $clientId, application: $application) {
    ...OauthConfigurationData
  }
}
    ${OauthConfigurationDataFragmentDoc}`;
export const FindOauthConfigurationsDocument = gql`
    query findOauthConfigurations($serviceId: String!) {
  findOauthConfigurations(serviceId: $serviceId) {
    ...OauthConfigurationData
  }
}
    ${OauthConfigurationDataFragmentDoc}`;
export const AddSiteServiceDocument = gql`
    mutation addSiteService($site: String!, $service: String!, $application: String!, $name: String!) {
  addSiteService(site: $site, service: $service, application: $application, name: $name) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const UpdateServiceDocument = gql`
    mutation updateService($service: String!, $data: ServiceInput!) {
  updateService(service: $service, data: $data) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const RemoveSiteServiceDocument = gql`
    mutation removeSiteService($site: String!, $service: String!) {
  removeSiteService(site: $site, service: $service) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const AddOauthConfigurationDocument = gql`
    mutation addOauthConfiguration($clientId: String!, $service: String!, $application: String!, $secret: String) {
  addOauthConfiguration(clientId: $clientId, service: $service, application: $application, secret: $secret) {
    ...OauthConfigurationData
  }
}
    ${OauthConfigurationDataFragmentDoc}`;
export const GenerateOauthSecretDocument = gql`
    mutation generateOauthSecret($clientId: String!, $application: String!) {
  generateOauthSecret(clientId: $clientId, application: $application) {
    ...OauthConfigurationData
  }
}
    ${OauthConfigurationDataFragmentDoc}`;
export const RemoveOauthConfigurationDocument = gql`
    mutation removeOauthConfiguration($clientId: String!, $service: String!, $application: String!) {
  removeOauthConfiguration(clientId: $clientId, service: $service, application: $application)
}
    `;
export const RevokeOauthSecretDocument = gql`
    mutation revokeOauthSecret($clientId: String!, $application: String!) {
  revokeOauthSecret(clientId: $clientId, application: $application) {
    ...OauthConfigurationData
  }
}
    ${OauthConfigurationDataFragmentDoc}`;
export const AssignServiceInstanceDocument = gql`
    mutation assignServiceInstance($service: String!, $site: String!, $instance: String!) {
  assignServiceInstance(service: $service, site: $site, instance: $instance) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const RemoveServiceInstanceDocument = gql`
    mutation removeServiceInstance($service: String!, $site: String!, $instance: String!) {
  removeServiceInstance(service: $service, site: $site, instance: $instance) {
    ...FullService
  }
}
    ${FullServiceFragmentDoc}`;
export const CountLoginSessionsDocument = gql`
    query CountLoginSessions($search: SessionSearch!, $site: String) {
  countLoginSessions(search: $search, site: $site)
}
    `;
export const FindLoginSessionsDocument = gql`
    query FindLoginSessions($search: SessionSearch!, $site: String, $sort: Sort, $pagination: Pagination) {
  loginSessions(search: $search, site: $site, sort: $sort, pagination: $pagination) {
    id
    type
    username
    companyKey
    domain
    loginIp
    loginDate
    lastActivityDate
    open
  }
}
    `;
export const FindManagedInstancesForCertTemplateDocument = gql`
    query findManagedInstancesForCertTemplate {
  managedInstances {
    ...InstanceForCertTemplate
  }
}
    ${InstanceForCertTemplateFragmentDoc}`;
export const FindSiteManagedCompaniesAndInstancesDocument = gql`
    query findSiteManagedCompaniesAndInstances($siteName: String!) {
  findSiteManagedCompanies(sitename: $siteName) {
    ...SiteCompany
  }
}
    ${SiteCompanyFragmentDoc}`;
export const FindSiteManagedInstancesDocument = gql`
    query findSiteManagedInstances($siteName: String!) {
  findSiteManagedInstances(sitename: $siteName) {
    ...SiteInstance
  }
}
    ${SiteInstanceFragmentDoc}`;
export const ManagedInstancesDocument = gql`
    query managedInstances($sites: [String!]) {
  managedInstances(sites: $sites) {
    ...InstanceReference
  }
}
    ${InstanceReferenceFragmentDoc}`;
export const AddSiteCompanyDocument = gql`
    mutation addSiteCompany($site: String!, $company: String!) {
  addSiteCompany(site: $site, company: $company) {
    ...SiteCompany
  }
}
    ${SiteCompanyFragmentDoc}`;
export const RemoveSiteCompanyDocument = gql`
    mutation removeSiteCompany($site: String!, $company: String!) {
  removeSiteCompany(site: $site, company: $company) {
    ...SiteCompany
  }
}
    ${SiteCompanyFragmentDoc}`;
export const AddSiteInstanceDocument = gql`
    mutation addSiteInstance($site: String!, $company: String!, $instance: String!, $enabled: Boolean!, $regulation: Regulation) {
  addSiteInstance(site: $site, company: $company, instance: $instance, enabled: $enabled, regulation: $regulation) {
    ...SiteInstance
  }
}
    ${SiteInstanceFragmentDoc}`;
export const UpdateSiteInstanceDocument = gql`
    mutation updateSiteInstance($site: String!, $instance: String!, $enabled: Boolean!, $regulation: Regulation) {
  updateSiteInstance(site: $site, instance: $instance, enabled: $enabled, regulation: $regulation) {
    ...SiteInstance
  }
}
    ${SiteInstanceFragmentDoc}`;
export const RemoveSiteInstanceDocument = gql`
    mutation removeSiteInstance($site: String!, $instance: String!) {
  removeSiteInstance(site: $site, instance: $instance) {
    ...InstanceReference
  }
}
    ${InstanceReferenceFragmentDoc}`;
export const FindManagedSitesReferenceDocument = gql`
    query findManagedSitesReference {
  managedSites {
    ...SiteReference
  }
}
    ${SiteReferenceFragmentDoc}`;
export const FindGrantedSitesLightDocument = gql`
    query findGrantedSitesLight {
  grantedSites {
    ...GrantedSiteReference
  }
}
    ${GrantedSiteReferenceFragmentDoc}`;
export const FindManagedSitesDocument = gql`
    query findManagedSites {
  managedSites {
    ...FullSite
  }
}
    ${FullSiteFragmentDoc}`;
export const SearchManagedSitesDocument = gql`
    query searchManagedSites($filter: SitesFilter, $sort: [Sort!], $pagination: Pagination) {
  managedSites(filter: $filter, sort: $sort, pagination: $pagination) {
    ...FullSite
  }
}
    ${FullSiteFragmentDoc}`;
export const CountManagedSitesDocument = gql`
    query countManagedSites($filter: SitesFilter) {
  countManagedSites(filter: $filter)
}
    `;
export const FindSiteDocument = gql`
    query findSite($name: String!) {
  findSite(name: $name) {
    name
    key
    accountName
    status
    type
    group
    loginNotificationsEnabled
    services {
      service
      name
      application {
        name
        key
      }
    }
    instances {
      name
      regulation
      company {
        name
        key
      }
      enabled
    }
    companies {
      name
      key
    }
    supportGroup
    occupationalLicenseRequired
  }
}
    `;
export const AddSiteDocument = gql`
    mutation addSite($name: String!, $site: SiteInput!) {
  addSite(name: $name, site: $site) {
    name
    key
    accountName
    status
    type
    group
    loginNotificationsEnabled
    services {
      service
      application {
        name
      }
    }
    instances {
      name
    }
    companies {
      name
    }
    occupationalLicenseRequired
  }
}
    `;
export const UpdateSiteDocument = gql`
    mutation updateSite($name: String!, $site: SiteInput!) {
  updateSite(name: $name, site: $site) {
    name
    key
    accountName
    status
    type
    group
    loginNotificationsEnabled
    services {
      service
      application {
        name
      }
    }
    instances {
      name
    }
    companies {
      name
    }
    occupationalLicenseRequired
  }
}
    `;
export const RemoveSiteDocument = gql`
    mutation removeSite($name: String!) {
  removeSite(name: $name) {
    key
  }
}
    `;
export const FindSitesForWebApiTemplateDocument = gql`
    query findSitesForWebAPITemplate {
  managedSites {
    ...SiteForWebAPITemplate
  }
}
    ${SiteForWebApiTemplateFragmentDoc}`;
export const FindPermitProfilesDocument = gql`
    query findPermitProfiles($site: String) {
  findPermitProfiles(site: $site) {
    ...PermitProfile
  }
}
    ${PermitProfileFragmentDoc}`;
export const FindSitePermitsByProfileDocument = gql`
    query findSitePermitsByProfile($profileId: Long!) {
  findSitePermits(profileId: $profileId) {
    ...PermitWithoutProfile
  }
}
    ${PermitWithoutProfileFragmentDoc}`;
export const FindSitePermitsByUsernameDocument = gql`
    query findSitePermitsByUsername($username: String!) {
  findSitePermits(username: $username) {
    ...FullPermit
  }
}
    ${FullPermitFragmentDoc}`;
export const FindNavigatorServicesDocument = gql`
    query findNavigatorServices {
  navigatorServices {
    ...DashboardSite
  }
}
    ${DashboardSiteFragmentDoc}`;
export const FindSiteCertsMassExpirationDateDocument = gql`
    query findSiteCertsMassExpirationDate($siteName: String!) {
  findSite(name: $siteName) {
    key
    certsMassExpiration
  }
}
    `;
export const FindSiteCertificateDeploymentsDocument = gql`
    query findSiteCertificateDeployments($site: String!) {
  findSiteCertificateDeployments(site: $site) {
    ...CertificateDeploymentData
  }
}
    ${CertificateDeploymentDataFragmentDoc}`;
export const FindSiteGroupNamesDocument = gql`
    query findSiteGroupNames($siteName: String!) {
  findSiteGroupNames(siteName: $siteName)
}
    `;
export const FindSitesAndNetworksForWebApiDocument = gql`
    query findSitesAndNetworksForWebAPI {
  managedSites {
    ...SiteForWebAPITemplate
  }
}
    ${SiteForWebApiTemplateFragmentDoc}`;
export const FindSiteReportsDocument = gql`
    query findSiteReports($siteName: String!) {
  siteReports(siteName: $siteName) {
    name
    key
    reportGroup
    frozen
  }
}
    `;
export const AddSitePermitProfileDocument = gql`
    mutation addSitePermitProfile($name: String!, $type: SitePermitType!, $description: String, $managerGroup: String!, $notifyDaysBefore: [Int!]!) {
  addSitePermitProfile(name: $name, type: $type, description: $description, managerGroup: $managerGroup, notifyDaysBefore: $notifyDaysBefore) {
    ...PermitProfile
  }
}
    ${PermitProfileFragmentDoc}`;
export const BindPermitProfileToSiteDocument = gql`
    mutation bindPermitProfileToSite($site: String!, $profileId: Long!) {
  bindSitePermitProfile(site: $site, profileId: $profileId)
}
    `;
export const UnbindPermitProfileFromSiteDocument = gql`
    mutation unbindPermitProfileFromSite($site: String!, $profileId: Long!) {
  unbindSitePermitProfile(site: $site, profileId: $profileId)
}
    `;
export const AddSitePermitDocument = gql`
    mutation addSitePermit($profileId: Long!, $permitNumber: String!, $username: String!, $notifyAdminsAboutExpiration: Boolean!, $validFrom: Date!, $validTo: Date!) {
  addSitePermit(profileId: $profileId, permitNumber: $permitNumber, username: $username, notifyAdminsAboutExpiration: $notifyAdminsAboutExpiration, validFrom: $validFrom, validTo: $validTo) {
    ...PermitWithoutProfile
  }
}
    ${PermitWithoutProfileFragmentDoc}`;
export const ToggleSitePermitRevocationDocument = gql`
    mutation toggleSitePermitRevocation($permitId: Long!, $revoke: Boolean!) {
  toggleSitePermitRevocation(permitId: $permitId, revoke: $revoke)
}
    `;
export const FetchSiteConfigDocument = gql`
    mutation fetchSiteConfig($siteName: String!) {
  fetchSiteConfig(name: $siteName) {
    services {
      service
      application {
        name
      }
    }
    companies {
      key
      name
    }
    instances {
      name
    }
    operators {
      key
      name
    }
  }
}
    `;
export const FetchSiteSecretsDocument = gql`
    mutation fetchSiteSecrets($site: String!) {
  fetchSiteSecrets(site: $site)
}
    `;
export const CreateSiteCertificateDocument = gql`
    mutation createSiteCertificate($site: String!, $application: String!, $profile: String!) {
  createSiteCertificate(site: $site, application: $application, profile: $profile) {
    ...CertificateDeploymentData
  }
}
    ${CertificateDeploymentDataFragmentDoc}`;
export const ReplaceSiteCertificateDocument = gql`
    mutation replaceSiteCertificate($id: Int!) {
  replaceSiteCertificate(id: $id) {
    ...CertificateDeploymentData
  }
}
    ${CertificateDeploymentDataFragmentDoc}`;
export const RevokeSiteCertificatesDocument = gql`
    mutation revokeSiteCertificates($ids: [Int!]!) {
  revokeSiteCertificates(ids: $ids)
}
    `;
export const InstallSiteCertificatesDocument = gql`
    mutation installSiteCertificates($ids: [Int!]!) {
  installSiteCertificates(ids: $ids)
}
    `;
export const UninstallSiteCertificatesDocument = gql`
    mutation uninstallSiteCertificates($ids: [Int!]!) {
  uninstallSiteCertificates(ids: $ids)
}
    `;
export const SyncSiteReportsDocument = gql`
    mutation syncSiteReports($siteName: String!, $timestamp: Long) {
  syncReports(siteName: $siteName, timestamp: $timestamp) {
    name
    reportGroup
  }
}
    `;
export const CheckSitesVersionForCrmUpdateDocument = gql`
    mutation checkSitesVersionForCrmUpdate {
  checkSitesVersionForCrmUpdate
}
    `;
export const GetTechnicalAccountsDocument = gql`
    query GetTechnicalAccounts {
  technicalAccounts {
    ...TechnicalAccountFragment
  }
}
    ${TechnicalAccountFragmentFragmentDoc}`;
export const UpdateTechnicalAccountDocument = gql`
    mutation UpdateTechnicalAccount($email: String!, $username: String!, $companyKey: String!, $enabled: Boolean) {
  updateTechnicalAccount(email: $email, username: $username, companyKey: $companyKey, enabled: $enabled) {
    ...TechnicalAccountFragment
  }
}
    ${TechnicalAccountFragmentFragmentDoc}`;
export const IssueApiKeyDocument = gql`
    mutation IssueApiKey($username: String!, $companyKey: String!, $allowedOrigin: String) {
  issueApikey(username: $username, companyKey: $companyKey, allowedOrigin: $allowedOrigin)
}
    `;
export const RevokeApiKeyDocument = gql`
    mutation RevokeApiKey($username: String!, $companyKey: String!) {
  revokeApikey(username: $username, companyKey: $companyKey)
}
    `;
export const IssueOauthCredentialsDocument = gql`
    mutation IssueOauthCredentials($username: String!, $companyKey: String!) {
  issueOauthCredentials(username: $username, companyKey: $companyKey) {
    clientId
    clientSecret
  }
}
    `;
export const RegenerateOauthSecretDocument = gql`
    mutation RegenerateOauthSecret($username: String!, $companyKey: String!) {
  regenerateOauthSecret(username: $username, companyKey: $companyKey) {
    clientId
    clientSecret
  }
}
    `;
export const RevokeOauthCredentialsDocument = gql`
    mutation RevokeOauthCredentials($username: String!, $companyKey: String!) {
  revokeOauthCredentials(username: $username, companyKey: $companyKey)
}
    `;
export const ServiceStatusDocument = gql`
    query serviceStatus {
  serviceStatus
}
    `;
export const ReloadSitesDocument = gql`
    mutation reloadSites {
  reloadSites
}
    `;
export const RunChecksDocument = gql`
    mutation runChecks {
  runChecks
}
    `;
export const SyncPowerBiAclDocument = gql`
    mutation syncPowerBiAcl {
  syncPowerBiAcl
}
    `;
export const PowerBiAclSyncStatusDocument = gql`
    query powerBiAclSyncStatus {
  powerBiAclSyncStatus {
    id
    status
    server
    created
    finished
    tasks
    chunks
    completedChunks
  }
}
    `;
export const PowerBiAclSyncsDocument = gql`
    query powerBiAclSyncs {
  powerBiAclSyncs {
    id
    status
    server
    created
    finished
    tasks
    chunks
    completedChunks
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    adminQuickSearch(variables: AdminQuickSearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AdminQuickSearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminQuickSearchQuery>(AdminQuickSearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminQuickSearch', 'query');
    },
    getAdminConfiguration(variables: GetAdminConfigurationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAdminConfigurationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAdminConfigurationQuery>(GetAdminConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAdminConfiguration', 'query');
    },
    saveAdminConfiguration(variables: SaveAdminConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SaveAdminConfigurationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SaveAdminConfigurationMutation>(SaveAdminConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'saveAdminConfiguration', 'mutation');
    },
    AddAdminAccount(variables: AddAdminAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddAdminAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddAdminAccountMutation>(AddAdminAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAdminAccount', 'mutation');
    },
    removeAdminConfiguration(variables: RemoveAdminConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveAdminConfigurationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveAdminConfigurationMutation>(RemoveAdminConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeAdminConfiguration', 'mutation');
    },
    setActorTimezone(variables: SetActorTimezoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetActorTimezoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetActorTimezoneMutation>(SetActorTimezoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'setActorTimezone', 'mutation');
    },
    FindAdminReportsFromGroups(variables: FindAdminReportsFromGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminReportsFromGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminReportsFromGroupsQuery>(FindAdminReportsFromGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminReportsFromGroups', 'query');
    },
    FindAdminGroups(variables: FindAdminGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupsQuery>(FindAdminGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroups', 'query');
    },
    FindAdminPermissionsFromGroups(variables: FindAdminPermissionsFromGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminPermissionsFromGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminPermissionsFromGroupsQuery>(FindAdminPermissionsFromGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminPermissionsFromGroups', 'query');
    },
    FindAdminSitesAndInstancesFromGroups(variables: FindAdminSitesAndInstancesFromGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminSitesAndInstancesFromGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminSitesAndInstancesFromGroupsQuery>(FindAdminSitesAndInstancesFromGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminSitesAndInstancesFromGroups', 'query');
    },
    FindAdminManagerGroupsFromGroups(variables: FindAdminManagerGroupsFromGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminManagerGroupsFromGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminManagerGroupsFromGroupsQuery>(FindAdminManagerGroupsFromGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminManagerGroupsFromGroups', 'query');
    },
    UpdateAdminAccount(variables: UpdateAdminAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAdminAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminAccountMutation>(UpdateAdminAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAdminAccount', 'mutation');
    },
    FindAdminAccount(variables: FindAdminAccountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminAccountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminAccountQuery>(FindAdminAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminAccount', 'query');
    },
    WhoAmI(variables?: WhoAmIQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WhoAmIQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<WhoAmIQuery>(WhoAmIDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WhoAmI', 'query');
    },
    SendTestEmail(variables: SendTestEmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendTestEmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendTestEmailQuery>(SendTestEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendTestEmail', 'query');
    },
    FindAdAdmins(variables: FindAdAdminsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdAdminsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdAdminsQuery>(FindAdAdminsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdAdmins', 'query');
    },
    FindServiceTicketsByGroupAndApplications(variables: FindServiceTicketsByGroupAndApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindServiceTicketsByGroupAndApplicationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindServiceTicketsByGroupAndApplicationsQuery>(FindServiceTicketsByGroupAndApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindServiceTicketsByGroupAndApplications', 'query');
    },
    endServiceTickets(variables: EndServiceTicketsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EndServiceTicketsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EndServiceTicketsMutation>(EndServiceTicketsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'endServiceTickets', 'mutation');
    },
    syncAdminAccount(variables: SyncAdminAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncAdminAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncAdminAccountMutation>(SyncAdminAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncAdminAccount', 'mutation');
    },
    FindAdminGroupSites(variables: FindAdminGroupSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupSitesQuery>(FindAdminGroupSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroupSites', 'query');
    },
    FindAdminGroupCompanies(variables: FindAdminGroupCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupCompaniesQuery>(FindAdminGroupCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroupCompanies', 'query');
    },
    FindAdminGroupInstances(variables: FindAdminGroupInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupInstancesQuery>(FindAdminGroupInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroupInstances', 'query');
    },
    FindAdminGroupReports(variables: FindAdminGroupReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupReportsQuery>(FindAdminGroupReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroupReports', 'query');
    },
    FindAdminGroupPermissions(variables: FindAdminGroupPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminGroupPermissionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminGroupPermissionsQuery>(FindAdminGroupPermissionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAdminGroupPermissions', 'query');
    },
    findAdminApplications(variables: FindAdminApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminApplicationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminApplicationsQuery>(FindAdminApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findAdminApplications', 'query');
    },
    findApplications(variables?: FindApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindApplicationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindApplicationsQuery>(FindApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findApplications', 'query');
    },
    findApplication(variables: FindApplicationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindApplicationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindApplicationQuery>(FindApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findApplication', 'query');
    },
    findRoleGroups(variables: FindRoleGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindRoleGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindRoleGroupsQuery>(FindRoleGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findRoleGroups', 'query');
    },
    findPermissionGroups(variables: FindPermissionGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindPermissionGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindPermissionGroupsQuery>(FindPermissionGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findPermissionGroups', 'query');
    },
    findApplicationsWithPermissions(variables?: FindApplicationsWithPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindApplicationsWithPermissionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindApplicationsWithPermissionsQuery>(FindApplicationsWithPermissionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findApplicationsWithPermissions', 'query');
    },
    deleteApplicationItems(variables: DeleteApplicationItemsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteApplicationItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteApplicationItemsMutation>(DeleteApplicationItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteApplicationItems', 'mutation');
    },
    findDeprecatedAppItems(variables?: FindDeprecatedAppItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindDeprecatedAppItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindDeprecatedAppItemsQuery>(FindDeprecatedAppItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findDeprecatedAppItems', 'query');
    },
    reloadApplications(variables?: ReloadApplicationsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReloadApplicationsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReloadApplicationsMutation>(ReloadApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'reloadApplications', 'mutation');
    },
    migratePermission(variables: MigratePermissionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MigratePermissionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MigratePermissionMutation>(MigratePermissionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'migratePermission', 'mutation');
    },
    executeAudit(variables: ExecuteAuditQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExecuteAuditQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExecuteAuditQuery>(ExecuteAuditDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'executeAudit', 'query');
    },
    findAuditCount(variables: FindAuditCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAuditCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAuditCountQuery>(FindAuditCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findAuditCount', 'query');
    },
    addScheduledAudit(variables: AddScheduledAuditMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddScheduledAuditMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddScheduledAuditMutation>(AddScheduledAuditDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addScheduledAudit', 'mutation');
    },
    removeScheduledAudit(variables: RemoveScheduledAuditMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveScheduledAuditMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveScheduledAuditMutation>(RemoveScheduledAuditDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeScheduledAudit', 'mutation');
    },
    findAudit(variables: FindAuditQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAuditQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAuditQuery>(FindAuditDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findAudit', 'query');
    },
    findAuditsList(variables?: FindAuditsListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAuditsListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAuditsListQuery>(FindAuditsListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findAuditsList', 'query');
    },
    FindScheduledAuditConfigurations(variables?: FindScheduledAuditConfigurationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindScheduledAuditConfigurationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindScheduledAuditConfigurationsQuery>(FindScheduledAuditConfigurationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindScheduledAuditConfigurations', 'query');
    },
    syncClientCerts(variables?: SyncClientCertsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncClientCertsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncClientCertsMutation>(SyncClientCertsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncClientCerts', 'mutation');
    },
    addClientCertTemplate(variables: AddClientCertTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddClientCertTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddClientCertTemplateMutation>(AddClientCertTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addClientCertTemplate', 'mutation');
    },
    deleteClientCertTemplate(variables: DeleteClientCertTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteClientCertTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteClientCertTemplateMutation>(DeleteClientCertTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClientCertTemplate', 'mutation');
    },
    toggleClientCertTemplate(variables: ToggleClientCertTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleClientCertTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleClientCertTemplateMutation>(ToggleClientCertTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleClientCertTemplate', 'mutation');
    },
    countClientCertTemplates(variables: CountClientCertTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountClientCertTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountClientCertTemplatesQuery>(CountClientCertTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countClientCertTemplates', 'query');
    },
    findClientCertTemplates(variables: FindClientCertTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindClientCertTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindClientCertTemplatesQuery>(FindClientCertTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findClientCertTemplates', 'query');
    },
    findClientCerts(variables: FindClientCertsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindClientCertsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindClientCertsQuery>(FindClientCertsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findClientCerts', 'query');
    },
    toggleClientCertRevocation(variables: ToggleClientCertRevocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleClientCertRevocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleClientCertRevocationMutation>(ToggleClientCertRevocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleClientCertRevocation', 'mutation');
    },
    toggleClientCertNotifications(variables: ToggleClientCertNotificationsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleClientCertNotificationsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleClientCertNotificationsMutation>(ToggleClientCertNotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleClientCertNotifications', 'mutation');
    },
    getClientCertPrivateKey(variables: GetClientCertPrivateKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetClientCertPrivateKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetClientCertPrivateKeyQuery>(GetClientCertPrivateKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClientCertPrivateKey', 'query');
    },
    importClientCerts(variables: ImportClientCertsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportClientCertsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ImportClientCertsMutation>(ImportClientCertsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'importClientCerts', 'mutation');
    },
    issueClientCert(variables: IssueClientCertMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IssueClientCertMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IssueClientCertMutation>(IssueClientCertDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'issueClientCert', 'mutation');
    },
    getImportableCertificates(variables?: GetImportableCertificatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetImportableCertificatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetImportableCertificatesQuery>(GetImportableCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getImportableCertificates', 'query');
    },
    getClientCert(variables: GetClientCertQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetClientCertQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetClientCertQuery>(GetClientCertDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClientCert', 'query');
    },
    addClientCertNotificationGroup(variables: AddClientCertNotificationGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddClientCertNotificationGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddClientCertNotificationGroupMutation>(AddClientCertNotificationGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addClientCertNotificationGroup', 'mutation');
    },
    removeClientCertNotificationGroup(variables: RemoveClientCertNotificationGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveClientCertNotificationGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveClientCertNotificationGroupMutation>(RemoveClientCertNotificationGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeClientCertNotificationGroup', 'mutation');
    },
    addClientCertNotification(variables: AddClientCertNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddClientCertNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddClientCertNotificationMutation>(AddClientCertNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addClientCertNotification', 'mutation');
    },
    deleteClientCertNotification(variables: DeleteClientCertNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteClientCertNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteClientCertNotificationMutation>(DeleteClientCertNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClientCertNotification', 'mutation');
    },
    findManagedCompanyAndDomainRelations(variables?: FindManagedCompanyAndDomainRelationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedCompanyAndDomainRelationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedCompanyAndDomainRelationsQuery>(FindManagedCompanyAndDomainRelationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedCompanyAndDomainRelations', 'query');
    },
    findManagedCompanies(variables?: FindManagedCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedCompaniesQuery>(FindManagedCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedCompanies', 'query');
    },
    findCompaniesForCompaniesList(variables?: FindCompaniesForCompaniesListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindCompaniesForCompaniesListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindCompaniesForCompaniesListQuery>(FindCompaniesForCompaniesListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findCompaniesForCompaniesList', 'query');
    },
    findManagedCompaniesWithSites(variables?: FindManagedCompaniesWithSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedCompaniesWithSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedCompaniesWithSitesQuery>(FindManagedCompaniesWithSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedCompaniesWithSites', 'query');
    },
    findCompanyByKey(variables: FindCompanyByKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindCompanyByKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindCompanyByKeyQuery>(FindCompanyByKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findCompanyByKey', 'query');
    },
    findGrantedCompanies(variables?: FindGrantedCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGrantedCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGrantedCompaniesQuery>(FindGrantedCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGrantedCompanies', 'query');
    },
    findManagedCompanyReferences(variables?: FindManagedCompanyReferencesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedCompanyReferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedCompanyReferencesQuery>(FindManagedCompanyReferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedCompanyReferences', 'query');
    },
    findGrantedCompanyReferencesLight(variables?: FindGrantedCompanyReferencesLightQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGrantedCompanyReferencesLightQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGrantedCompanyReferencesLightQuery>(FindGrantedCompanyReferencesLightDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGrantedCompanyReferencesLight', 'query');
    },
    findCompanyRoles(variables?: FindCompanyRolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindCompanyRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindCompanyRolesQuery>(FindCompanyRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findCompanyRoles', 'query');
    },
    addCompany(variables: AddCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddCompanyMutation>(AddCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addCompany', 'mutation');
    },
    updateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCompany', 'mutation');
    },
    deleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCompany', 'mutation');
    },
    countCompanies(variables: CountCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountCompaniesQuery>(CountCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countCompanies', 'query');
    },
    findCompanies(variables: FindCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindCompaniesQuery>(FindCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findCompanies', 'query');
    },
    findDefaultPasswordPolicy(variables?: FindDefaultPasswordPolicyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindDefaultPasswordPolicyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindDefaultPasswordPolicyQuery>(FindDefaultPasswordPolicyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findDefaultPasswordPolicy', 'query');
    },
    findPasswordPolicyContext(variables: FindPasswordPolicyContextQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindPasswordPolicyContextQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindPasswordPolicyContextQuery>(FindPasswordPolicyContextDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findPasswordPolicyContext', 'query');
    },
    updatePasswordPolicy(variables: UpdatePasswordPolicyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePasswordPolicyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePasswordPolicyMutation>(UpdatePasswordPolicyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePasswordPolicy', 'mutation');
    },
    addLoginDomain(variables: AddLoginDomainMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddLoginDomainMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddLoginDomainMutation>(AddLoginDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addLoginDomain', 'mutation');
    },
    editLoginDomain(variables: EditLoginDomainMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EditLoginDomainMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EditLoginDomainMutation>(EditLoginDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'editLoginDomain', 'mutation');
    },
    removeLoginDomain(variables: RemoveLoginDomainMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveLoginDomainMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveLoginDomainMutation>(RemoveLoginDomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeLoginDomain', 'mutation');
    },
    refreshLoginDomainMetadata(variables: RefreshLoginDomainMetadataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshLoginDomainMetadataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshLoginDomainMetadataMutation>(RefreshLoginDomainMetadataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'refreshLoginDomainMetadata', 'mutation');
    },
    syncCompanyCrmData(variables: SyncCompanyCrmDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncCompanyCrmDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncCompanyCrmDataMutation>(SyncCompanyCrmDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncCompanyCrmData', 'mutation');
    },
    getEventTypes(variables?: GetEventTypesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEventTypesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEventTypesQuery>(GetEventTypesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEventTypes', 'query');
    },
    findEvents(variables?: FindEventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindEventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindEventsQuery>(FindEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findEvents', 'query');
    },
    countEvents(variables?: CountEventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountEventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountEventsQuery>(CountEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countEvents', 'query');
    },
    getLayoutInfo(variables?: GetLayoutInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLayoutInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLayoutInfoQuery>(GetLayoutInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLayoutInfo', 'query');
    },
    getActiveSession(variables?: GetActiveSessionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetActiveSessionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetActiveSessionQuery>(GetActiveSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getActiveSession', 'query');
    },
    permissions(variables?: PermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PermissionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PermissionsQuery>(PermissionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'permissions', 'query');
    },
    findGroupsWithPermission(variables: FindGroupsWithPermissionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupsWithPermissionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupsWithPermissionQuery>(FindGroupsWithPermissionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupsWithPermission', 'query');
    },
    findGroupsWithRole(variables: FindGroupsWithRoleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupsWithRoleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupsWithRoleQuery>(FindGroupsWithRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupsWithRole', 'query');
    },
    findPermissionMigrationGroups(variables: FindPermissionMigrationGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindPermissionMigrationGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindPermissionMigrationGroupsQuery>(FindPermissionMigrationGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findPermissionMigrationGroups', 'query');
    },
    findGroupSites(variables: FindGroupSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupSitesQuery>(FindGroupSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupSites', 'query');
    },
    findAdminPermissionSitesAccess(variables: FindAdminPermissionSitesAccessQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAdminPermissionSitesAccessQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAdminPermissionSitesAccessQuery>(FindAdminPermissionSitesAccessDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findAdminPermissionSitesAccess', 'query');
    },
    findGroupCompanies(variables: FindGroupCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupCompaniesQuery>(FindGroupCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupCompanies', 'query');
    },
    findGroupInstances(variables: FindGroupInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupInstancesQuery>(FindGroupInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupInstances', 'query');
    },
    getGroupMembers(variables: GetGroupMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGroupMembersQuery>(GetGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGroupMembers', 'query');
    },
    FindGroupCandidates(variables: FindGroupCandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupCandidatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupCandidatesQuery>(FindGroupCandidatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindGroupCandidates', 'query');
    },
    AddGroupMembers(variables: AddGroupMembersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupMembersMutation>(AddGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddGroupMembers', 'mutation');
    },
    addMembersFromDomainAndUsername(variables: AddMembersFromDomainAndUsernameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddMembersFromDomainAndUsernameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddMembersFromDomainAndUsernameMutation>(AddMembersFromDomainAndUsernameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addMembersFromDomainAndUsername', 'mutation');
    },
    RemoveGroupMembers(variables: RemoveGroupMembersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveGroupMembersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveGroupMembersMutation>(RemoveGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveGroupMembers', 'mutation');
    },
    GetBoundAmsGroups(variables: GetBoundAmsGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBoundAmsGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBoundAmsGroupsQuery>(GetBoundAmsGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBoundAmsGroups', 'query');
    },
    FindAmsGroups(variables: FindAmsGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAmsGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAmsGroupsQuery>(FindAmsGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAmsGroups', 'query');
    },
    updateAmsGroupBindings(variables: UpdateAmsGroupBindingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAmsGroupBindingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAmsGroupBindingsMutation>(UpdateAmsGroupBindingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAmsGroupBindings', 'mutation');
    },
    FindGroupReports(variables: FindGroupReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupReportsQuery>(FindGroupReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindGroupReports', 'query');
    },
    FindGroupAccessedAndManagedSites(variables: FindGroupAccessedAndManagedSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupAccessedAndManagedSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupAccessedAndManagedSitesQuery>(FindGroupAccessedAndManagedSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindGroupAccessedAndManagedSites', 'query');
    },
    findGroupRolesAndPermissions(variables: FindGroupRolesAndPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupRolesAndPermissionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupRolesAndPermissionsQuery>(FindGroupRolesAndPermissionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupRolesAndPermissions', 'query');
    },
    findRoleSubRolesAndPermissions(variables: FindRoleSubRolesAndPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindRoleSubRolesAndPermissionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindRoleSubRolesAndPermissionsQuery>(FindRoleSubRolesAndPermissionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findRoleSubRolesAndPermissions', 'query');
    },
    findGroupItemChanges(variables: FindGroupItemChangesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupItemChangesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupItemChangesQuery>(FindGroupItemChangesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupItemChanges', 'query');
    },
    UpdateGroupItems(variables: UpdateGroupItemsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGroupItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupItemsMutation>(UpdateGroupItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGroupItems', 'mutation');
    },
    findChangesetChanges(variables: FindChangesetChangesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindChangesetChangesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindChangesetChangesQuery>(FindChangesetChangesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findChangesetChanges', 'query');
    },
    revertChangesets(variables: RevertChangesetsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RevertChangesetsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevertChangesetsMutation>(RevertChangesetsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'revertChangesets', 'mutation');
    },
    findOneGroupItemChangeSets(variables: FindOneGroupItemChangeSetsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindOneGroupItemChangeSetsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindOneGroupItemChangeSetsQuery>(FindOneGroupItemChangeSetsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findOneGroupItemChangeSets', 'query');
    },
    findGroupItemsChangeSets(variables: FindGroupItemsChangeSetsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupItemsChangeSetsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupItemsChangeSetsQuery>(FindGroupItemsChangeSetsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupItemsChangeSets', 'query');
    },
    findGroup(variables: FindGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupQuery>(FindGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroup', 'query');
    },
    addGroupAuditEntry(variables: AddGroupAuditEntryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupAuditEntryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupAuditEntryMutation>(AddGroupAuditEntryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addGroupAuditEntry', 'mutation');
    },
    addGroupComment(variables: AddGroupCommentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupCommentMutation>(AddGroupCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addGroupComment', 'mutation');
    },
    updateGroupDescription(variables: UpdateGroupDescriptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGroupDescriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGroupDescriptionMutation>(UpdateGroupDescriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateGroupDescription', 'mutation');
    },
    addGroup(variables: AddGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupMutation>(AddGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addGroup', 'mutation');
    },
    grantedGroups(variables?: GrantedGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GrantedGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GrantedGroupsQuery>(GrantedGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'grantedGroups', 'query');
    },
    managedGroups(variables?: ManagedGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ManagedGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ManagedGroupsQuery>(ManagedGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'managedGroups', 'query');
    },
    findChildGroups(variables: FindChildGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindChildGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindChildGroupsQuery>(FindChildGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findChildGroups', 'query');
    },
    findGroupMembers(variables: FindGroupMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupMembersQuery>(FindGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupMembers', 'query');
    },
    loadGroupRelations(variables: LoadGroupRelationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoadGroupRelationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoadGroupRelationsQuery>(LoadGroupRelationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'loadGroupRelations', 'query');
    },
    loadGroupSitesAndInstances(variables: LoadGroupSitesAndInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoadGroupSitesAndInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoadGroupSitesAndInstancesQuery>(LoadGroupSitesAndInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'loadGroupSitesAndInstances', 'query');
    },
    loadGroup(variables: LoadGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoadGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoadGroupQuery>(LoadGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'loadGroup', 'query');
    },
    findSiteGroups(variables: FindSiteGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteGroupsQuery>(FindSiteGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteGroups', 'query');
    },
    toggleGroup(variables: ToggleGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleGroupMutation>(ToggleGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleGroup', 'mutation');
    },
    removeGroup(variables: RemoveGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveGroupMutation>(RemoveGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeGroup', 'mutation');
    },
    renameGroup(variables: RenameGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RenameGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RenameGroupMutation>(RenameGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'renameGroup', 'mutation');
    },
    addGroupManagement(variables: AddGroupManagementMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupManagementMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddGroupManagementMutation>(AddGroupManagementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addGroupManagement', 'mutation');
    },
    removeGroupManagement(variables: RemoveGroupManagementMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveGroupManagementMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveGroupManagementMutation>(RemoveGroupManagementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeGroupManagement', 'mutation');
    },
    assignManager(variables: AssignManagerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignManagerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignManagerMutation>(AssignManagerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'assignManager', 'mutation');
    },
    validateCloneGroup(variables: ValidateCloneGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ValidateCloneGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ValidateCloneGroupQuery>(ValidateCloneGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'validateCloneGroup', 'query');
    },
    validateImportGroup(variables: ValidateImportGroupQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ValidateImportGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ValidateImportGroupQuery>(ValidateImportGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'validateImportGroup', 'query');
    },
    findManagedGroups(variables?: FindManagedGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedGroupsQuery>(FindManagedGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedGroups', 'query');
    },
    findManagerGroups(variables?: FindManagerGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagerGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagerGroupsQuery>(FindManagerGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagerGroups', 'query');
    },
    findGroupManagedCompanies(variables: FindGroupManagedCompaniesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGroupManagedCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGroupManagedCompaniesQuery>(FindGroupManagedCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGroupManagedCompanies', 'query');
    },
    grantSites(variables: GrantSitesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GrantSitesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GrantSitesMutation>(GrantSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'grantSites', 'mutation');
    },
    grantInstances(variables: GrantInstancesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GrantInstancesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GrantInstancesMutation>(GrantInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'grantInstances', 'mutation');
    },
    cloneGroup(variables: CloneGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CloneGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CloneGroupMutation>(CloneGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cloneGroup', 'mutation');
    },
    importGroup(variables: ImportGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ImportGroupMutation>(ImportGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'importGroup', 'mutation');
    },
    tokenInfo(variables: TokenInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TokenInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TokenInfoQuery>(TokenInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tokenInfo', 'query');
    },
    getSettings(variables?: GetSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSettingsQuery>(GetSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSettings', 'query');
    },
    findSiteNotificationTemplates(variables: FindSiteNotificationTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteNotificationTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteNotificationTemplatesQuery>(FindSiteNotificationTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteNotificationTemplates', 'query');
    },
    addNotificationTemplate(variables: AddNotificationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddNotificationTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddNotificationTemplateMutation>(AddNotificationTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addNotificationTemplate', 'mutation');
    },
    modifyNotificationTemplate(variables: ModifyNotificationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyNotificationTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ModifyNotificationTemplateMutation>(ModifyNotificationTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'modifyNotificationTemplate', 'mutation');
    },
    deleteNotificationTemplate(variables: DeleteNotificationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteNotificationTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteNotificationTemplateMutation>(DeleteNotificationTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteNotificationTemplate', 'mutation');
    },
    siteAccessReason(variables: SiteAccessReasonMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SiteAccessReasonMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SiteAccessReasonMutation>(SiteAccessReasonDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'siteAccessReason', 'mutation');
    },
    sendTestNotification(variables: SendTestNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendTestNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendTestNotificationMutation>(SendTestNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendTestNotification', 'mutation');
    },
    sendTaggedNotification(variables: SendTaggedNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendTaggedNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendTaggedNotificationMutation>(SendTaggedNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendTaggedNotification', 'mutation');
    },
    findServiceApplications(variables?: FindServiceApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindServiceApplicationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindServiceApplicationsQuery>(FindServiceApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findServiceApplications', 'query');
    },
    findSiteServices(variables?: FindSiteServicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteServicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteServicesQuery>(FindSiteServicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteServices', 'query');
    },
    findOauthConfiguration(variables: FindOauthConfigurationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindOauthConfigurationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindOauthConfigurationQuery>(FindOauthConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findOauthConfiguration', 'query');
    },
    findOauthConfigurations(variables: FindOauthConfigurationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindOauthConfigurationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindOauthConfigurationsQuery>(FindOauthConfigurationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findOauthConfigurations', 'query');
    },
    addSiteService(variables: AddSiteServiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSiteServiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSiteServiceMutation>(AddSiteServiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSiteService', 'mutation');
    },
    updateService(variables: UpdateServiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateServiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateServiceMutation>(UpdateServiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateService', 'mutation');
    },
    removeSiteService(variables: RemoveSiteServiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveSiteServiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveSiteServiceMutation>(RemoveSiteServiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeSiteService', 'mutation');
    },
    addOauthConfiguration(variables: AddOauthConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddOauthConfigurationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddOauthConfigurationMutation>(AddOauthConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addOauthConfiguration', 'mutation');
    },
    generateOauthSecret(variables: GenerateOauthSecretMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GenerateOauthSecretMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GenerateOauthSecretMutation>(GenerateOauthSecretDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateOauthSecret', 'mutation');
    },
    removeOauthConfiguration(variables: RemoveOauthConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveOauthConfigurationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveOauthConfigurationMutation>(RemoveOauthConfigurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeOauthConfiguration', 'mutation');
    },
    revokeOauthSecret(variables: RevokeOauthSecretMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RevokeOauthSecretMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevokeOauthSecretMutation>(RevokeOauthSecretDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'revokeOauthSecret', 'mutation');
    },
    assignServiceInstance(variables: AssignServiceInstanceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignServiceInstanceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignServiceInstanceMutation>(AssignServiceInstanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'assignServiceInstance', 'mutation');
    },
    removeServiceInstance(variables: RemoveServiceInstanceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveServiceInstanceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveServiceInstanceMutation>(RemoveServiceInstanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeServiceInstance', 'mutation');
    },
    CountLoginSessions(variables: CountLoginSessionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountLoginSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountLoginSessionsQuery>(CountLoginSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountLoginSessions', 'query');
    },
    FindLoginSessions(variables: FindLoginSessionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindLoginSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindLoginSessionsQuery>(FindLoginSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindLoginSessions', 'query');
    },
    findManagedInstancesForCertTemplate(variables?: FindManagedInstancesForCertTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedInstancesForCertTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedInstancesForCertTemplateQuery>(FindManagedInstancesForCertTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedInstancesForCertTemplate', 'query');
    },
    findSiteManagedCompaniesAndInstances(variables: FindSiteManagedCompaniesAndInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteManagedCompaniesAndInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteManagedCompaniesAndInstancesQuery>(FindSiteManagedCompaniesAndInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteManagedCompaniesAndInstances', 'query');
    },
    findSiteManagedInstances(variables: FindSiteManagedInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteManagedInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteManagedInstancesQuery>(FindSiteManagedInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteManagedInstances', 'query');
    },
    managedInstances(variables?: ManagedInstancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ManagedInstancesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ManagedInstancesQuery>(ManagedInstancesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'managedInstances', 'query');
    },
    addSiteCompany(variables: AddSiteCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSiteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSiteCompanyMutation>(AddSiteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSiteCompany', 'mutation');
    },
    removeSiteCompany(variables: RemoveSiteCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveSiteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveSiteCompanyMutation>(RemoveSiteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeSiteCompany', 'mutation');
    },
    addSiteInstance(variables: AddSiteInstanceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSiteInstanceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSiteInstanceMutation>(AddSiteInstanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSiteInstance', 'mutation');
    },
    updateSiteInstance(variables: UpdateSiteInstanceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSiteInstanceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSiteInstanceMutation>(UpdateSiteInstanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSiteInstance', 'mutation');
    },
    removeSiteInstance(variables: RemoveSiteInstanceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveSiteInstanceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveSiteInstanceMutation>(RemoveSiteInstanceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeSiteInstance', 'mutation');
    },
    findManagedSitesReference(variables?: FindManagedSitesReferenceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedSitesReferenceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedSitesReferenceQuery>(FindManagedSitesReferenceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedSitesReference', 'query');
    },
    findGrantedSitesLight(variables?: FindGrantedSitesLightQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindGrantedSitesLightQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindGrantedSitesLightQuery>(FindGrantedSitesLightDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findGrantedSitesLight', 'query');
    },
    findManagedSites(variables?: FindManagedSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindManagedSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindManagedSitesQuery>(FindManagedSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManagedSites', 'query');
    },
    searchManagedSites(variables?: SearchManagedSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchManagedSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchManagedSitesQuery>(SearchManagedSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchManagedSites', 'query');
    },
    countManagedSites(variables?: CountManagedSitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountManagedSitesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountManagedSitesQuery>(CountManagedSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countManagedSites', 'query');
    },
    findSite(variables: FindSiteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteQuery>(FindSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSite', 'query');
    },
    addSite(variables: AddSiteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSiteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSiteMutation>(AddSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSite', 'mutation');
    },
    updateSite(variables: UpdateSiteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSiteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSiteMutation>(UpdateSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSite', 'mutation');
    },
    removeSite(variables: RemoveSiteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveSiteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveSiteMutation>(RemoveSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeSite', 'mutation');
    },
    findSitesForWebAPITemplate(variables?: FindSitesForWebApiTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSitesForWebApiTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSitesForWebApiTemplateQuery>(FindSitesForWebApiTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSitesForWebAPITemplate', 'query');
    },
    findPermitProfiles(variables?: FindPermitProfilesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindPermitProfilesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindPermitProfilesQuery>(FindPermitProfilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findPermitProfiles', 'query');
    },
    findSitePermitsByProfile(variables: FindSitePermitsByProfileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSitePermitsByProfileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSitePermitsByProfileQuery>(FindSitePermitsByProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSitePermitsByProfile', 'query');
    },
    findSitePermitsByUsername(variables: FindSitePermitsByUsernameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSitePermitsByUsernameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSitePermitsByUsernameQuery>(FindSitePermitsByUsernameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSitePermitsByUsername', 'query');
    },
    findNavigatorServices(variables?: FindNavigatorServicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindNavigatorServicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindNavigatorServicesQuery>(FindNavigatorServicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findNavigatorServices', 'query');
    },
    findSiteCertsMassExpirationDate(variables: FindSiteCertsMassExpirationDateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteCertsMassExpirationDateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteCertsMassExpirationDateQuery>(FindSiteCertsMassExpirationDateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteCertsMassExpirationDate', 'query');
    },
    findSiteCertificateDeployments(variables: FindSiteCertificateDeploymentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteCertificateDeploymentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteCertificateDeploymentsQuery>(FindSiteCertificateDeploymentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteCertificateDeployments', 'query');
    },
    findSiteGroupNames(variables: FindSiteGroupNamesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteGroupNamesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteGroupNamesQuery>(FindSiteGroupNamesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteGroupNames', 'query');
    },
    findSitesAndNetworksForWebAPI(variables?: FindSitesAndNetworksForWebApiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSitesAndNetworksForWebApiQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSitesAndNetworksForWebApiQuery>(FindSitesAndNetworksForWebApiDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSitesAndNetworksForWebAPI', 'query');
    },
    findSiteReports(variables: FindSiteReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindSiteReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindSiteReportsQuery>(FindSiteReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findSiteReports', 'query');
    },
    addSitePermitProfile(variables: AddSitePermitProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSitePermitProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSitePermitProfileMutation>(AddSitePermitProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSitePermitProfile', 'mutation');
    },
    bindPermitProfileToSite(variables: BindPermitProfileToSiteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BindPermitProfileToSiteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<BindPermitProfileToSiteMutation>(BindPermitProfileToSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'bindPermitProfileToSite', 'mutation');
    },
    unbindPermitProfileFromSite(variables: UnbindPermitProfileFromSiteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UnbindPermitProfileFromSiteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnbindPermitProfileFromSiteMutation>(UnbindPermitProfileFromSiteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unbindPermitProfileFromSite', 'mutation');
    },
    addSitePermit(variables: AddSitePermitMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSitePermitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSitePermitMutation>(AddSitePermitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSitePermit', 'mutation');
    },
    toggleSitePermitRevocation(variables: ToggleSitePermitRevocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleSitePermitRevocationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleSitePermitRevocationMutation>(ToggleSitePermitRevocationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleSitePermitRevocation', 'mutation');
    },
    fetchSiteConfig(variables: FetchSiteConfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchSiteConfigMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchSiteConfigMutation>(FetchSiteConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchSiteConfig', 'mutation');
    },
    fetchSiteSecrets(variables: FetchSiteSecretsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchSiteSecretsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchSiteSecretsMutation>(FetchSiteSecretsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchSiteSecrets', 'mutation');
    },
    createSiteCertificate(variables: CreateSiteCertificateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSiteCertificateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSiteCertificateMutation>(CreateSiteCertificateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createSiteCertificate', 'mutation');
    },
    replaceSiteCertificate(variables: ReplaceSiteCertificateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReplaceSiteCertificateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReplaceSiteCertificateMutation>(ReplaceSiteCertificateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'replaceSiteCertificate', 'mutation');
    },
    revokeSiteCertificates(variables: RevokeSiteCertificatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RevokeSiteCertificatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevokeSiteCertificatesMutation>(RevokeSiteCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'revokeSiteCertificates', 'mutation');
    },
    installSiteCertificates(variables: InstallSiteCertificatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InstallSiteCertificatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InstallSiteCertificatesMutation>(InstallSiteCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'installSiteCertificates', 'mutation');
    },
    uninstallSiteCertificates(variables: UninstallSiteCertificatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UninstallSiteCertificatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UninstallSiteCertificatesMutation>(UninstallSiteCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uninstallSiteCertificates', 'mutation');
    },
    syncSiteReports(variables: SyncSiteReportsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncSiteReportsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncSiteReportsMutation>(SyncSiteReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncSiteReports', 'mutation');
    },
    checkSitesVersionForCrmUpdate(variables?: CheckSitesVersionForCrmUpdateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CheckSitesVersionForCrmUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckSitesVersionForCrmUpdateMutation>(CheckSitesVersionForCrmUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkSitesVersionForCrmUpdate', 'mutation');
    },
    GetTechnicalAccounts(variables?: GetTechnicalAccountsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTechnicalAccountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTechnicalAccountsQuery>(GetTechnicalAccountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTechnicalAccounts', 'query');
    },
    UpdateTechnicalAccount(variables: UpdateTechnicalAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTechnicalAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTechnicalAccountMutation>(UpdateTechnicalAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTechnicalAccount', 'mutation');
    },
    IssueApiKey(variables: IssueApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IssueApiKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IssueApiKeyMutation>(IssueApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IssueApiKey', 'mutation');
    },
    RevokeApiKey(variables: RevokeApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RevokeApiKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevokeApiKeyMutation>(RevokeApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RevokeApiKey', 'mutation');
    },
    IssueOauthCredentials(variables: IssueOauthCredentialsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IssueOauthCredentialsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IssueOauthCredentialsMutation>(IssueOauthCredentialsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IssueOauthCredentials', 'mutation');
    },
    RegenerateOauthSecret(variables: RegenerateOauthSecretMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RegenerateOauthSecretMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RegenerateOauthSecretMutation>(RegenerateOauthSecretDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegenerateOauthSecret', 'mutation');
    },
    RevokeOauthCredentials(variables: RevokeOauthCredentialsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RevokeOauthCredentialsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RevokeOauthCredentialsMutation>(RevokeOauthCredentialsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RevokeOauthCredentials', 'mutation');
    },
    serviceStatus(variables?: ServiceStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ServiceStatusQuery>(ServiceStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'serviceStatus', 'query');
    },
    reloadSites(variables?: ReloadSitesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReloadSitesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReloadSitesMutation>(ReloadSitesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'reloadSites', 'mutation');
    },
    runChecks(variables?: RunChecksMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunChecksMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RunChecksMutation>(RunChecksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'runChecks', 'mutation');
    },
    syncPowerBiAcl(variables?: SyncPowerBiAclMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncPowerBiAclMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncPowerBiAclMutation>(SyncPowerBiAclDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncPowerBiAcl', 'mutation');
    },
    powerBiAclSyncStatus(variables?: PowerBiAclSyncStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PowerBiAclSyncStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PowerBiAclSyncStatusQuery>(PowerBiAclSyncStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'powerBiAclSyncStatus', 'query');
    },
    powerBiAclSyncs(variables?: PowerBiAclSyncsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PowerBiAclSyncsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PowerBiAclSyncsQuery>(PowerBiAclSyncsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'powerBiAclSyncs', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;